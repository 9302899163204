import React from 'react';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import { styled } from '@mui/system';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Upload from './../../components/uploadMidia/Upload'
import CPFInput from './../../components/mask/CPFInput'
import CEPInput from './../../components/mask/CEPInput'
import TelefoneInput from './../../components/mask/TelefoneInput'
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '98%',
  marginLeft: '1%',
  marginTop: '20px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

let formatos = [
  {
    type: "text",
    data_type: "character varying"
  },
  {
    type: "date",
    data_type: "date"
  },
  {
    type: "number",
    data_type: "integer"
  },
]

export default function SimpleModal(props) {
  const theme = useTheme();
  const {cliente, metodos_divugacoes, updateFieldFisica, updateFieldJuridica, dadosComplementaresPF,
    updateFieldDadosComplementar, validarCpfCnpjConjugue, handleUpload, updateFieldEndereco, buscaEndereco, 
    openDocumento, loadingSalvar
  } = props
  
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={10} xs={10} sm={10}>
              <h5 className="tituloConfirmacao">Solicitação de Atualização Cadastral</h5>
            </Grid>
          </Grid>
          <div className='card_dados mg_top_10'>
            <Grid container direction="row" spacing={1}>
              <Grid item md={12} xs={12} sm={12}>
                <h5>Dados Básicos:</h5>
              </Grid>
            </Grid>
            {cliente.tipoPessoa === 'FISICA' &&
              <React.Fragment>
                <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Tipo Pessoa*"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="tipoPessoa"
                      value={cliente.tipoPessoa}
                      disabled
                    >
                      <option value="FISICA">
                        Pessoa Física
                      </option>
                      <option value="JURIDICA">
                        Pessoa Júridica
                      </option>
                    </TextField>
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="CPF*" variant="outlined" size="small" name="cpf" value={cliente.fisica.cpf} disabled InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={8}>
                    <TextField className="input" label="Nome*" variant="outlined" size="small" name="nome" value={cliente.fisica.nome} onChange={(e) => updateFieldFisica(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={8}>
                    <TextField className="input" label="Sobrenome" variant="outlined" size="small" name="sobrenome" value={cliente.fisica.sobrenome} onChange={(e) => updateFieldFisica(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={3} xs={12} sm={8}>
                    <TextField type='date' className="input" label="Data de Nascimento*" variant="outlined" size="small" name="data_nascimento" value={cliente.fisica.data_nascimento} onChange={(e) => updateFieldFisica(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={8}>
                    <TelefoneInput
                      label="Celular*"
                      className="inputCpfCnpj"
                      name="telefone"
                      value={cliente.fisica.telefone}
                      onChange={(e) => updateFieldFisica(e)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} sm={8}>
                    <TextField className="input" label="E-mail" variant="outlined" size="small" name="email" value={cliente.fisica.email} onChange={(e) => updateFieldFisica(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
            {cliente.tipoPessoa === 'JURIDICA' &&
              <React.Fragment>
                <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Tipo Pessoa*"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="tipoPessoa"
                      value={cliente.tipoPessoa}
                      disabled
                    >
                      <option value="FISICA">
                        Pessoa Física
                      </option>
                      <option value="JURIDICA">
                        Pessoa Júridica
                      </option>
                    </TextField>
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField className="input" label="CNPJ*" variant="outlined" size="small" name="cnpj" value={cliente.juridica.cnpj} disabled InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={8}>
                    <TextField className="input" label="Razão Social*" variant="outlined" size="small" name="razao_social" value={cliente.juridica.razao_social} onChange={(e) => updateFieldJuridica(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={8}>
                    <TextField className="input" label="Nome Fantasia*" variant="outlined" size="small" name="nome_fantasia" value={cliente.juridica.nome_fantasia} onChange={(e) => updateFieldJuridica(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={3} xs={12} sm={8}>
                    <TextField type='date' className="input" label="Data de Fundação*" variant="outlined" size="small" name="data_fundacao" value={cliente.juridica.data_fundacao} onChange={(e) => updateFieldJuridica(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={8}>
                    <TelefoneInput
                      label="Celular*"
                      className="inputCpfCnpj"
                      name="telefone"
                      value={cliente.juridica.telefone}
                      onChange={(e) => updateFieldJuridica(e)}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} sm={8}>
                    <TextField className="input" label="E-mail" variant="outlined" size="small" name="email" value={cliente.juridica.email} onChange={(e) => updateFieldJuridica(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          </div>
          {(cliente.tipoPessoa === 'FISICA' && dadosComplementaresPF.list.length > 0) &&
            <div className='card_dados mg_top_20'>
              <Grid container direction="row" spacing={1}>
                <Grid item md={12} xs={12} sm={12}>
                  <h5>Dados Complementares:</h5>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
                {dadosComplementaresPF.list.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
                  if(value.column_name === 'estado_civil'){
                    return (
                      <Grid key={value.dtd_identifier} item md={3} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name={value.column_name}
                          label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                          value={cliente.fisica.dadosComplementar[value.column_name]}
                          onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                          InputLabelProps={{shrink: true}} 
                        >
                          <option value="">Selecionar Estado Civil</option>
                          <option value="CASADO">Casado(a)</option>
                          <option value="SOLTEIRO">Solteiro(a)</option>
                          <option value="VIUVO">Viuvo(a)</option>
                          <option value="SEPARADO">Separado(a)</option>
                        </TextField>
                      </Grid>
                    )
                  }else if(value.column_name === 'sexo'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name={value.column_name}
                          label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                          value={cliente.fisica.dadosComplementar[value.column_name]}
                          onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                          InputLabelProps={{shrink: true}} 
                        >
                          <option value="">Selecionar Sexo</option>
                          <option value="MASCULINO">Masculino</option>
                          <option value="FEMININO">Feminino</option>
                        </TextField>
                      </Grid>
                    )
                  }else if(value.column_name === 'rg_uf'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name={value.column_name}
                          label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                          value={cliente.fisica.dadosComplementar[value.column_name]}
                          onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                          InputLabelProps={{shrink: true}} 
                        >
                          <option value="">Selecionar Estado</option>
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                        </TextField>
                      </Grid>
                    )
                  }else if(value.column_name === 'rg_orgao_emissor'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name={value.column_name}
                          label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                          value={cliente.fisica.dadosComplementar[value.column_name]}
                          onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                          InputLabelProps={{shrink: true}} 
                        >
                          <option value="">Selecionar Orgão Emissor</option>
                          <option value="SSP - Secretaria de Segurança Pública">SSP - Secretaria de Segurança Pública</option>
                          <option value="PM - Polícia Militar">PM - Polícia Militar</option>
                          <option value="PC - Policia Civil">PC - Policia Civil</option>
                          <option value="CNT - Carteira Nacional de Habilitação">CNT - Carteira Nacional de Habilitação</option>
                          <option value="CRM - Conselho Regional de Medicina">CRM - Conselho Regional de Medicina</option>
                          <option value="CREA - Conselho Regional de Engenharia e Arquitetura">CREA - Conselho Regional de Engenharia e Arquitetura</option>
                          <option value="DIC - Diretoria de Identificação Civil">DIC - Diretoria de Identificação Civil</option>
                          <option value="CTPS - Carteira de Trabaho e Previdência Social">CTPS - Carteira de Trabaho e Previdência Social</option>
                          <option value="FGTS - Fundo de Garantia do Tempo de Serviço">FGTS - Fundo de Garantia do Tempo de Serviço</option>
                          <option value="IFP - Instituto Félix Pacheco">IFP - Instituto Félix Pacheco</option>
                          <option value="IPF - Instituto Pereira Faustino">IPF - Instituto Pereira Faustino</option>
                          <option value="IML - Instituto Médico-Legal">IML - Instituto Médico-Legal</option>
                          <option value="MTE - Ministério do Trabalho e Emprego">MTE - Ministério do Trabalho e Emprego</option>
                          <option value="MMA - Ministério da Marinha">MMA - Ministério da Marinha</option>
                          <option value="MAE - Ministério da Aeronáutica">MAE - Ministério da Aeronáutica</option>
                          <option value="ME - Ministério do Exército">ME - Ministério do Exército</option>
                          <option value="OAB - Ordem dos Advogados do Brasil">OAB - Ordem dos Advogados do Brasil</option>
                          <option value="POF - Polícia Federal">POF - Polícia Federal</option>
                          <option value="POM - Polícia Militar">POM - Polícia Militar</option>
                          <option value="SES - Carteira de Estrangeiro">SES - Carteira de Estrangeiro</option>
                          <option value="SJS - Secretaria da Justiça e Segurança">SJS - Secretaria da Justiça e Segurança</option>
                          <option value="SJTS - Secretaria da Justiça do Trabalho e Segurança">SJTS - Secretaria da Justiça do Trabalho e Segurança</option>
                          <option value="ZZZ - Outros (inclusive exterior)">ZZZ - Outros (inclusive exterior)</option>
                        </TextField>
                      </Grid>
                    )
                  }else if(value.column_name === 'rg_orgao_emissor_conjugue'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name={value.column_name}
                          label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                          value={cliente.fisica.dadosComplementar[value.column_name]}
                          onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                          InputLabelProps={{shrink: true}} 
                        >
                          <option value="">Selecionar Orgão Emissor</option>
                          <option value="SSP - Secretaria de Segurança Pública">SSP - Secretaria de Segurança Pública</option>
                          <option value="PM - Polícia Militar">PM - Polícia Militar</option>
                          <option value="PC - Policia Civil">PC - Policia Civil</option>
                          <option value="CNT - Carteira Nacional de Habilitação">CNT - Carteira Nacional de Habilitação</option>
                          <option value="CRM - Conselho Regional de Medicina">CRM - Conselho Regional de Medicina</option>
                          <option value="CREA - Conselho Regional de Engenharia e Arquitetura">CREA - Conselho Regional de Engenharia e Arquitetura</option>
                          <option value="DIC - Diretoria de Identificação Civil">DIC - Diretoria de Identificação Civil</option>
                          <option value="CTPS - Carteira de Trabaho e Previdência Social">CTPS - Carteira de Trabaho e Previdência Social</option>
                          <option value="FGTS - Fundo de Garantia do Tempo de Serviço">FGTS - Fundo de Garantia do Tempo de Serviço</option>
                          <option value="IFP - Instituto Félix Pacheco">IFP - Instituto Félix Pacheco</option>
                          <option value="IPF - Instituto Pereira Faustino">IPF - Instituto Pereira Faustino</option>
                          <option value="IML - Instituto Médico-Legal">IML - Instituto Médico-Legal</option>
                          <option value="MTE - Ministério do Trabalho e Emprego">MTE - Ministério do Trabalho e Emprego</option>
                          <option value="MMA - Ministério da Marinha">MMA - Ministério da Marinha</option>
                          <option value="MAE - Ministério da Aeronáutica">MAE - Ministério da Aeronáutica</option>
                          <option value="ME - Ministério do Exército">ME - Ministério do Exército</option>
                          <option value="OAB - Ordem dos Advogados do Brasil">OAB - Ordem dos Advogados do Brasil</option>
                          <option value="POF - Polícia Federal">POF - Polícia Federal</option>
                          <option value="POM - Polícia Militar">POM - Polícia Militar</option>
                          <option value="SES - Carteira de Estrangeiro">SES - Carteira de Estrangeiro</option>
                          <option value="SJS - Secretaria da Justiça e Segurança">SJS - Secretaria da Justiça e Segurança</option>
                          <option value="SJTS - Secretaria da Justiça do Trabalho e Segurança">SJTS - Secretaria da Justiça do Trabalho e Segurança</option>
                          <option value="ZZZ - Outros (inclusive exterior)">ZZZ - Outros (inclusive exterior)</option>
                        </TextField>
                      </Grid>
                    )
                  }else if(value.column_name === 'receber_novidades'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name={value.column_name}
                          label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                          value={cliente.fisica.dadosComplementar[value.column_name]}
                          onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                          InputLabelProps={{shrink: true}} 
                        >
                          <option value="SIM">Sim</option>
                          <option value="NAO">Não</option>
                        </TextField>
                      </Grid>
                    )
                  }else if(value.column_name === 'como_conheceu_id'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name={value.column_name}
                          label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                          value={cliente.fisica.dadosComplementar[value.column_name]}
                          onChangeCapture={(e) => updateFieldDadosComplementar(e)}
                          InputLabelProps={{shrink: true}} 
                        >
                          <option value={value.id}>Selecionar Como Conheceu?</option>
                        {metodos_divugacoes.list.map(value => {
                          return (
                            <option key={value.id} value={value.id}>{value.descricao}</option>
                          )
                        })}
                        </TextField>
                      </Grid>
                    )
                  }else if(value.column_name === 'telefone_conjugue'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <TelefoneInput
                          label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                          className="inputCpfCnpj"
                          name={value.column_name} 
                          value={cliente.fisica.dadosComplementar[value.column_name]}
                          onChange={(e) => updateFieldDadosComplementar(e)}
                        />
                      </Grid>
                    )
                  }else if(value.column_name === 'cpf_conjugue'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <CPFInput
                          label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                          className="inputCpfCnpj"
                          name={value.column_name}
                          value={cliente.fisica.dadosComplementar[value.column_name]}
                          onChange={(e) => updateFieldDadosComplementar(e)}
                          onBlur={e => validarCpfCnpjConjugue(e)}
                        />
                      </Grid>
                    )
                  }else if(value.column_name === 'doc_rg_frente'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <span style={{color: "#000", fontWeight: 400}}>RG Frente: (Limite 2MB)</span>
                        <Upload onUpload={(e) => handleUpload(e, 'doc_rg_frente')} />
                        <span>{cliente.files['doc_rg_frente'][0] ? cliente.files['doc_rg_frente'][0].name : ''}</span>
                      </Grid>
                    )
                  }else if(value.column_name !== 'doc_rg_frente' &&
                    value.column_name !== 'doc_rg_verso' &&
                    value.column_name !== 'doc_cnh_frente' &&
                    value.column_name !== 'doc_cnh_verso' &&
                    value.column_name !== 'doc_comprovante_renda' &&
                    value.column_name !== 'doc_comprovante_endereco'){
                    return (
                      <Grid key={value.dtd_identifier} item md={3} xs={12} sm={6}>
                        <TextField
                          fullWidth
                          className="input"
                          type={formatos.filter(param => param.data_type === value.data_type)[0] ? formatos.filter(param => param.data_type === value.data_type)[0].type : 'text'}
                          name={value.column_name}
                          label={`${value.column_comment}${value.obrigatorio ? '*': ''}`}
                          value={cliente.fisica.dadosComplementar[value.column_name]}
                          variant="outlined"
                          size="small"
                          onChange={(e) => updateFieldDadosComplementar(e)}
                          InputLabelProps={{shrink: true}} 
                        />
                      </Grid>
                    )
                  }
                })}
              </Grid>
              <Grid container direction="row" spacing={1} className="mg_top_10">
                {dadosComplementaresPF.list.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(value => {
                  if(value.column_name === 'doc_rg_frente'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <span style={{color: "#000", fontWeight: 400}}>RG Frente: (Limite 2MB)</span>
                        <Grid container direction="row" spacing={1} className="mg_top_10">
                          {cliente.files['doc_rg_frente'][0] &&
                            <React.Fragment>
                              <Grid item md={4} xs={12} sm={4}>
                                <Tooltip title='Visualizar' aria-label="add">
                                  <img src={cliente.files['doc_rg_frente'][0].preview} style={{cursor: 'pointer'}} onClick={() => openDocumento(cliente.files['doc_rg_frente'][0].preview, 'Comprovante de Endereço', cliente.files['doc_rg_frente'][0].formato)}/>
                                </Tooltip>
                              </Grid>
                              <Grid item md={8} xs={12} sm={8}>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_rg_frente')} />
                              </Grid>
                            </React.Fragment>
                          }
                          {!cliente.files['doc_rg_frente'][0] &&
                            <Grid item md={12} xs={12} sm={12}>
                              <Upload onUpload={(e) => handleUpload(e, 'doc_rg_frente')} />
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    )
                  }else if(value.column_name === 'doc_rg_verso'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <span style={{color: "#000", fontWeight: 400}}>RG Verso: (Limite 2MB)</span>
                        <Grid container direction="row" spacing={1} className="mg_top_10">
                          {cliente.files['doc_rg_verso'][0] &&
                            <React.Fragment>
                              <Grid item md={4} xs={12} sm={4}>
                                <Tooltip title='Visualizar' aria-label="add">
                                  <img src={cliente.files['doc_rg_verso'][0].preview} style={{cursor: 'pointer'}} onClick={() => openDocumento(cliente.files['doc_rg_verso'][0].preview, 'Comprovante de Endereço', cliente.files['doc_rg_verso'][0].formato)}/>
                                </Tooltip>
                              </Grid>
                              <Grid item md={8} xs={12} sm={8}>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_rg_verso')} />
                              </Grid>
                            </React.Fragment>
                          }
                          {!cliente.files['doc_rg_verso'][0] &&
                            <Grid item md={12} xs={12} sm={12}>
                              <Upload onUpload={(e) => handleUpload(e, 'doc_rg_verso')} />
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    )
                  }else if(value.column_name === 'doc_cnh_frente'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <span style={{color: "#000", fontWeight: 400}}>CNH Frente: (Limite 2MB)</span>
                        <Grid container direction="row" spacing={1} className="mg_top_10">
                          {cliente.files['doc_cnh_frente'][0] &&
                            <React.Fragment>
                              <Grid item md={4} xs={12} sm={4}>
                                <Tooltip title='Visualizar' aria-label="add">
                                  <img src={cliente.files['doc_cnh_frente'][0].preview} style={{cursor: 'pointer'}} onClick={() => openDocumento(cliente.files['doc_cnh_frente'][0].preview, 'Comprovante de Endereço', cliente.files['doc_cnh_frente'][0].formato)}/>
                                </Tooltip>
                              </Grid>
                              <Grid item md={8} xs={12} sm={8}>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_cnh_frente')} />
                              </Grid>
                            </React.Fragment>
                          }
                          {!cliente.files['doc_cnh_frente'][0] &&
                            <Grid item md={12} xs={12} sm={12}>
                              <Upload onUpload={(e) => handleUpload(e, 'doc_cnh_frente')} />
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    )
                  }else if(value.column_name === 'doc_cnh_verso'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <span style={{color: "#000", fontWeight: 400}}>CNH Verso: (Limite 2MB)</span>
                        <Grid container direction="row" spacing={1} className="mg_top_10">
                          {cliente.files['doc_cnh_verso'][0] &&
                            <React.Fragment>
                              <Grid item md={4} xs={12} sm={4}>
                                <Tooltip title='Visualizar' aria-label="add">
                                  <img src={cliente.files['doc_cnh_verso'][0].preview} style={{cursor: 'pointer'}} onClick={() => openDocumento(cliente.files['doc_cnh_verso'][0].preview, 'Comprovante de Endereço', cliente.files['doc_cnh_verso'][0].formato)}/>
                                </Tooltip>
                              </Grid>
                              <Grid item md={8} xs={12} sm={8}>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_cnh_verso')} />
                              </Grid>
                            </React.Fragment>
                          }
                          {!cliente.files['doc_cnh_verso'][0] &&
                            <Grid item md={12} xs={12} sm={12}>
                              <Upload onUpload={(e) => handleUpload(e, 'doc_cnh_verso')} />
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    )
                  }else if(value.column_name === 'doc_comprovante_renda'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <span style={{color: "#000", fontWeight: 400}}>Comp. de Renda: (Limite 2MB)</span>
                        <Grid container direction="row" spacing={1} className="mg_top_10">
                          {cliente.files['doc_comprovante_renda'][0] &&
                            <React.Fragment>
                              <Grid item md={4} xs={12} sm={4}>
                                <Tooltip title='Visualizar' aria-label="add">
                                  <img src={cliente.files['doc_comprovante_renda'][0].preview} style={{cursor: 'pointer'}} onClick={() => openDocumento(cliente.files['doc_comprovante_renda'][0].preview, 'Comprovante de Endereço', cliente.files['doc_comprovante_renda'][0].formato)}/>
                                </Tooltip>
                              </Grid>
                              <Grid item md={8} xs={12} sm={8}>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_comprovante_renda')} />
                              </Grid>
                            </React.Fragment>
                          }
                          {!cliente.files['doc_comprovante_renda'][0] &&
                            <Grid item md={12} xs={12} sm={12}>
                              <Upload onUpload={(e) => handleUpload(e, 'doc_comprovante_renda')} />
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    )
                  }else if(value.column_name === 'doc_comprovante_endereco'){
                    return (
                      <Grid key={value.dtd_identifier}  item md={3} xs={12} sm={6}>
                        <span style={{color: "#000", fontWeight: 400}}>Comp. de Endereço: (Limite 2MB)</span>
                        <Grid container direction="row" spacing={1} className="mg_top_10">
                          {cliente.files['doc_comprovante_endereco'][0] &&
                            <React.Fragment>
                              <Grid item md={4} xs={12} sm={4}>
                                <Tooltip title='Visualizar' aria-label="add">
                                  <img src={cliente.files['doc_comprovante_endereco'][0].preview} style={{cursor: 'pointer'}} onClick={() => openDocumento(cliente.files['doc_comprovante_endereco'][0].preview, 'Comprovante de Endereço', cliente.files['doc_comprovante_endereco'][0].formato)}/>
                                </Tooltip>
                              </Grid>
                              <Grid item md={8} xs={12} sm={8}>
                                <Upload onUpload={(e) => handleUpload(e, 'doc_comprovante_endereco')} />
                              </Grid>
                            </React.Fragment>
                          }
                          {!cliente.files['doc_comprovante_endereco'][0] &&
                            <Grid item md={12} xs={12} sm={12}>
                              <Upload onUpload={(e) => handleUpload(e, 'doc_comprovante_endereco')} />
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    )
                  }
                })}
              </Grid>
            </div>
          }
          <div className='card_dados mg_top_20'>
            <Grid container direction="row" spacing={1}>
              <Grid item md={12} xs={12} sm={12}>
                <h5>Endereço:</h5>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} style={{marginTop: 5}}>
              <Grid item md={2} xs={12} sm={8}>
                <CEPInput
                  label="CEP*"
                  className="inputCpfCnpj"
                  name="cep"
                  value={cliente.endereco.cep}
                  onChange={(e) => updateFieldEndereco(e)}
                  onBlur={(e) => buscaEndereco(e)}
                />
              </Grid>
              <Grid item md={6} xs={12} sm={8}>
                <TextField className="input" label="Endereço*" variant="outlined" size="small" name="logradouro" value={cliente.endereco.logradouro} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={8}>
                <TextField className="input" label="Número*" variant="outlined" size="small" name="numero" value={cliente.endereco.numero} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={8}>
                <TextField className="input" label="Complemento" variant="outlined" size="small" name="complemento" value={cliente.endereco.complemento} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={4} xs={12} sm={8}>
                <TextField className="input" label="Bairro*" variant="outlined" size="small" name="bairro" value={cliente.endereco.bairro} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={4} xs={12} sm={8}>
                <TextField className="input" label="Cidade*" variant="outlined" size="small" name="cidade" value={cliente.endereco.cidade} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={4} xs={12} sm={8}>
                <TextField className="input" label="Estado*" variant="outlined" size="small" name="estado" value={cliente.endereco.estado} onChange={(e) => updateFieldEndereco(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
            </Grid>
          </div>

          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={8} xs={6} sm={4}></Grid>
            <Grid item md={2} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={loadingSalvar}>Voltar</Button>
            </Grid>
            <Grid item md={2} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={loadingSalvar}>Confirmar</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}

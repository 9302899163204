import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ContratosUnidades.scss'
import './../../main/ultil.scss'
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import ModalNovoContrato from './../../components/modals/NovoContratoUnidade'
import ModalContrato from './../../components/modals/ContratoUnidade'
import ModalCancelarContratoUnidade from './../../components/modals/CancelarContratoUnidade'
import Alert from './../../components/templates/Alert'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import real from './../../services/real'
import moeda from './../../services/moeda'

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const initialState = {
  contratos: {
    list: []
  },
  cabecalhoTabela: [
    { id: 'unidade', numeric: false, disablePadding: false, label: 'Unidade'},
    { id: 'data_ini_vig_form', numeric: false, disablePadding: false, label: 'Data Inicio'},
    { id: 'data_fim_vig_form', numeric: false, disablePadding: false, label: 'Data Fim' },
    { id: 'produtos', numeric: false, disablePadding: false, label: 'Serviços' },
    { id: 'situacao_atual', numeric: false, disablePadding: false, label: 'Situação' },
  ],
  acoesTabela: [],
  unin_tipo: "",
  filtro: {
    situacao: "ATIVO",
    unidade_id: ""
  },
  loading: true,
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  openModalContrato: false,
  dadosContrato: {
    codigo: '',
    data_ini_vig: '',
    data_fim_vig: '',
    unidade_tempo: '',
    tempo_contrato: '',
    unidade_id: '',
    produtos: [],
    dias_pagamento: '',
    juros: '',
    observacao: '',
    template_versao_id: "",
    assinar_contrato: false,
  },
  servico: {
    prod_id: "",
    prod_descricao: "",
    id: "",
    venc_prim_parcela: "",
    parametro: "",
    valor: "",
    ativo: true,
    habilita_valor_min: false,
    habilita_valor_max: false,
    inativa_unidade: true,
    valor_min: real(0),
    valor_max: real(0),
    formpag_id: '',
    contfin_id: '',
    formpag_descricao: '',
    contfin_descricao: '',
    index: "",
    gerar_nfse: false
  },
  update: false,
  dadosContratoView: {
    codigo: '',
    data_ini_vig: '',
    data_fim_vig: '',
    unidade_tempo: '',
    tempo_contrato: '',
    unidade_id: '',
    produtos: [],
    formpag_id: '',
    contfin_id: '',
    dias_pagamento: '',
    juros: '',
    observacao: ''
  },
  financeiros_pendentes: {
    list: []
  },
  openModalCancelarContratoUnidade: false,
  dadosCancelamento: {
    lanc_fin: false,
    motivoCancelamento: ''
  },
  unidade: {
    id: '',
    descricao: ''
  },
  conta: {
    descricao: "",
    tipo: "",
    valortotal: "",
    numparcela: 1,
    item_id: "",
    qtd_parcelas: 1,
    parcelas: [
      {
        baixado: "",
        datavencimento: "",
        datavencimento_orig: "",
        valorparcela: "",
        formapagto_id: "",
        documento: "",
        num: "",
        parcela_editada: false,
        contfin_id: '',
        index: 1
      }
    ]
  },
  produtos: {
    list: []
  },
  itens_receita_despesa: {
    list: []
  },
  openModalAdicionarServContratoUnidade: false,
  contasFinanceira:{
    list: []
  },
  formasPagamento:{
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades: {
    list: []
  },
  itemSelecionado:{
    id: '',
    codigo: '',
    descricao: '',
    ativo: '',
    slug: '',
    centro_custos: '',
    totalizador: ''
  },
  possui_emissao_nfse: false,
  template_documentos: {
    list: []
  }
}

const ContratoUnidade = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const filtrarContratos = async () => {
    setState(prevState => ({...prevState,
      loading:true
    }))

    let filtro = state.filtro

    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

    const dados = {
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.id
      })
    }

    filtro.unidadesnegocio = dados.unidadesnegocio

    const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/listContratos`, filtro, getToken())

    setState(prevState => ({...prevState,
      contratos: {
        list: data.sort((a, b) => (a.situacao_atual.situacao > b.situacao_atual.situacao) ? 1 : ((b.situacao_atual.situacao > a.situacao_atual.situacao) ? -1 : 0))
        .map(contrato => {
          let produtos = ''

          for (let i = 0; i < contrato.servicos.length; i++) {
            if((i + 1) === contrato.servicos.length){
              produtos += `${contrato.servicos[i].produto.descricao}.`
            }else{
              produtos += `${contrato.servicos[i].produto.descricao}, `
            }
            
          }

          return {
            ...contrato,
            unidade: contrato.unidade.descricao,
            data_ini_vig_form: moment(contrato.data_ini_vig).format('DD/MM/YYYY'),
            data_fim_vig_form: moment(contrato.data_fim_vig).format('DD/MM/YYYY'),
            situacao_atual: contrato.situacao_atual.situacao,
            produtos
          }
        })
      },
      loading:false
    }))
  }

  const verificaUnidadeTempo = (value) => {
    if(value === 'DIA'){
      return 'day'
    }else if (value === 'MÊS'){
      return 'month'
    }else if (value === 'ANO'){
      return 'year'
    }
  }

  const updateFiltro = (event) => {
    const filtro = state.filtro

    filtro[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      filtro
    }))
  }

  const viewContrato = async (value) => {
    console.log(value)
    setState(prevState => ({...prevState,
      dadosContratoView: {
        id: value.id,
        codigo: value.codigo,
        data_ini_vig_unform: value.data_ini_vig,
        data_fim_vig_unform: value.data_fim_vig,
        data_ini_vig: moment(value.data_ini_vig).format('DD/MM/YYYY'),
        data_fim_vig: moment(value.data_fim_vig).format('DD/MM/YYYY'),
        unidade_tempo: value.unidade_tempo,
        tempo_contrato: value.tempo_contrato,
        unidade_id: value.unidade_id,
        dias_pagamento: value.dias_pagamento,
        juros: value.juros,
        observacao: '',
        situacao: value.situacao_atual,
        servicos: value.servicos,
        produtos: value.servicos.map(servico => {
          return {
            prod_descricao: servico.produto.descricao,
            id: servico.id,
            venc_prim_parcela: servico.venc_prim_parcela,
            parametro: servico.parametro,
            valor: real(servico.valor),
            ativo: servico.ativo,
            habilita_valor_min: servico.habilita_valor_min,
            habilita_valor_max: servico.habilita_valor_max,
            inativa_unidade: servico.inativa_unidade,
            valor_min: real(servico.valor_min),
            valor_max: real(servico.valor_max),
            formpag_id: servico.forma_pagamento.id,
            contfin_id: servico.conta_financeira.id,
            formpag_descricao: servico.forma_pagamento.descricao,
            contfin_descricao: servico.conta_financeira.descricao,
            pendencias: servico.pendencias,
            gerar_nfse: servico.gerar_nfse
          }
        }),
      },
      openModalContrato: true
    }))
  }

  const handleCloseModalContrato = () => {
    setState(prevState => ({...prevState,
      openModalContrato: false
    }))
  }

  const consultaPlanoContas = async () => {
    try {
  
      const dados = {
        unidadesnegocio: [1],
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados,getToken())
             
      setState(prevState => ({...prevState,
        itens_receita_despesa: {
          list: data.filter(param => param.ativo && param.plano_contas.tipo === 'RECEITA').map(item => {
            return {
              id: item.id,
              descricao: item.descricao,
              planocontas_id: item.planocontas_id,
              plano_contas: item.plano_contas
            }
          })
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const cancelarContrato = async () => {
    let {dadosContratoView} = state

    let financeiros_pendentes = []

    for (const produto of dadosContratoView.produtos) {
      for (const pendencia of produto.pendencias) {
        if(pendencia.parcela_receita.situacao === "A VENCER"){
          financeiros_pendentes.push({
            ...pendencia.parcela_receita
          })
        }
      }
    }

    await consultaPlanoContas()

    setState(prevState => ({...prevState,
      financeiros_pendentes: {
        list: financeiros_pendentes
      },
      conta: {
        descricao: "Cancelamento de Contrato",
        tipo: "",
        valortotal: "",
        numparcela: 1,
        item_id: "",
        qtd_parcelas: 1,
        parcelas: [
          {
            baixado: "",
            datavencimento: "",
            datavencimento_orig: "",
            valorparcela: "",
            formapagto_id: "",
            documento: "",
            num: "",
            parcela_editada: false,
            contfin_id: '',
            index: 1
          }
        ]
      },
      openModalCancelarContratoUnidade: true
    }))
  }

  const consultaProdutos = async () => {
    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/produtos/list`, dados, getToken())

      const produtos = []

      resp.data.forEach(produto => {
        if(produto.ativo && produto.tipo === 'SERVICO'){
          produtos.push({
            _id: produto.id,
            descricao: produto.descricao,
            ativo: produto.ativo,
            index: ""
          })
        }
      })

      setState(prevState => ({...prevState,
        produtos: {
          list: produtos
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const addServico = async () => {
    await consultaProdutos()

    setState(prevState => ({...prevState,
      openModalAdicionarServContratoUnidade: true
    }))
  }

  const renovarContrato = async () => {
    let {dadosContratoView} = state

    await consultaProdutos()

    setState(prevState => ({...prevState,
      dadosContrato: {
        codigo: "",
        data_ini_vig: "",
        data_fim_vig: "",
        unidade_tempo: dadosContratoView.unidade_tempo,
        tempo_contrato: dadosContratoView.tempo_contrato,
        unidade_id: dadosContratoView.unidade_id,
        dias_pagamento: dadosContratoView.dias_pagamento,
        juros: dadosContratoView.juros,
        observacao: dadosContratoView.observacao,
        produtos: dadosContratoView.servicos.map((servico, key) => {
          return {
            prod_id: servico.produto.id,
            prod_descricao: servico.produto.descricao,
            venc_prim_parcela: servico.venc_prim_parcela,
            parametro: servico.parametro,
            valor: real(servico.valor),
            ativo: true,
            habilita_valor_min: servico.habilita_valor_min,
            habilita_valor_max: servico.habilita_valor_max,
            inativa_unidade: servico.inativa_unidade,
            valor_min: real(servico.valor_min),
            valor_max: real(servico.valor_max),
            index: key
          }
        }),
      },
      openModalNovoContrato: true,
      servico: {
        prod_id: "",
        prod_descricao: "",
        id: "",
        venc_prim_parcela: "",
        parametro: "",
        valor: "",
        ativo: true,
        habilita_valor_min: false,
        habilita_valor_max: false,
        inativa_unidade: true,
        valor_min: real(0),
        valor_max: real(0),
        formpag_id: '',
        contfin_id: '',
        formpag_descricao: '',
        contfin_descricao: '',
        gerar_nfse: false,
        index: ""
      }
    }))
  }

  const handleCloseModalCancelarContratoUnidade = () => {
    setState(prevState => ({...prevState,
      openModalCancelarContratoUnidade: false
    }))
  }

  const updateFieldLancFinanceiroCancelamento = () => {
    let {dadosCancelamento} = state
    dadosCancelamento.lanc_fin = !dadosCancelamento.lanc_fin
    setState(prevState => ({...prevState,dadosCancelamento}))
  }

  const updateFieldConta = (event) => {
    const conta = { ...state.conta }

    if (event.target.name === 'valortotal') {

      const valorTotal = parseFloat(event.target.value.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
      let valorDividido = valorTotal / conta.qtd_parcelas
      valorDividido = parseFloat(valorDividido.toFixed(2))

      let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        conta.parcelas[i].valorparcela = real(valorDividido)
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }
    }

    conta[event.target.name] = event.target.value

    setState(prevState => ({...prevState, conta }))
  }

  const updateFieldParcela = (event, index) => {
    const conta = { ...state.conta }

    let value = event.target.value
    let name = event.target.name

    if(name === 'datavencimento'){
      conta.parcelas[index - 1][name] = value
    }else{
      conta.parcelas[index - 1][name] = value
    }
    
    setState(prevState => ({...prevState, conta }))
  }

  const mudarParcelas = (event) => {
    const conta = { ...state.conta }

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    let array = []

    const valorTotal = parseFloat(conta.valortotal.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
    let valorDividido = valorTotal / qtdParcelasInfo
    valorDividido = parseFloat(valorDividido.toFixed(2))

    let sobra = valorTotal - (valorDividido * qtdParcelasInfo)

    sobra = parseFloat(sobra.toFixed(2))

    const dataHoje = new Date()

    for (let i = 0; i < qtdParcelasInfo; i++) {
      let mesAcrecentado = new Date();
      mesAcrecentado.setMonth(dataHoje.getMonth() + (i));

      array.push({
        baixado: "",
        datavencimento: moment(mesAcrecentado).format('YYYY-MM-DD'),
        valorparcela: real(valorDividido),
        formapagto_id: "",
        documento: "",
        num: "",
        parcela_editada: false,
        index: i + 1
      })
    }

    if (sobra !== 0) {
      array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
    }

    conta.numparcela = qtdParcelasInfo
    conta.qtd_parcelas = qtdParcelasInfo
    conta.parcelas = array
    setState(prevState => ({...prevState, conta }))
  }

  const ajustarArray = (qtdParcela, array, valorDividido, sobra) => {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].valorparcela = moeda((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  const recalcularParcela = (event, index) => {
    const conta = { ...state.conta }

    const parcelaEditada = conta.parcelas[index - 1]

    if (parcelaEditada.valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.valortotal.replace("R$ ", "").replaceAll(".", "").replace(",", "."))

      let qtdParcelasEditado = state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index - 1].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        setState(prevState => ({...prevState,
          qtdParcelasEditado
        }))
      }

      conta.parcelas[index - 1][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.valorparcela.replace("R$ ", "").replaceAll(".", "").replace(",", ".")) }))
      const diferenca = valorTotal - somaParcelasEditada
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)


      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].valorparcela = moeda(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      setState(prevState => ({...prevState, conta }))
    }
  }

  const updateFieldCancelamento = (event) => {
    let {dadosCancelamento} = state
    dadosCancelamento[event.target.name] = event.target.value
    setState(prevState => ({...prevState,dadosCancelamento}))
  }

  const confirmarCancelamento = async (value) => {
    if(value){
      let {dadosCancelamento, conta, dadosContratoView, financeiros_pendentes} = state

      if(dadosCancelamento.lanc_fin){
        if(conta.planocontas_id === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o Plano de Contas.'
            }
          }))
          return
        }
  
        if(conta.valortotal === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o Valor Total.'
            }
          }))
          return
        }
  
        if(conta.descricao === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a descrição da conta.'
            }
          }))
          return
        }
      }

      if(dadosCancelamento.motivoCancelamento === ''){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o motivo do cancelamento.'
          }
        }))
        return
      }

      try {
        const dados = {
          motivoCancelamento: dadosCancelamento.motivoCancelamento,
          contrato_id: dadosContratoView.id,
          conta,
          financeiros_pendentes: financeiros_pendentes.list.map(value => {
            return {
              id: value.id
            }
          })
        }

        if(dados.financeiros_pendentes.length === 0){
          delete dados.financeiros_pendentes
        }

        if(!dadosCancelamento.lanc_fin){
          delete dados.conta
        }else{
          dados.conta.tipo = 'RECEBER'
          dados.conta.unin_id = 1
          dados.conta.valortotal = parseFloat(dados.conta.valortotal.replaceAll(".", "").replace(",", "."))
    
          for (let i = 0; i < dados.conta.parcelas.length; i++) {
            dados.conta.parcelas[i].valorparcela = parseFloat(dados.conta.parcelas[i].valorparcela.replaceAll(".", "").replace(",", "."))
            dados.conta.parcelas[i].baixado = false
            dados.conta.parcelas[i].num = dados.conta.parcelas[i].index
            dados.conta.parcelas[i].formas_pgto = [
              {
                formapagto_id: parseInt(dados.conta.parcelas[i].formapagto_id)
              }
            ]
      
            if(
              moment(dados.conta.parcelas[i].datavencimento).isBefore(new Date()) 
              && (dados.conta.parcelas[i].formas_pgto.formapagto_id === 4 || dados.conta.parcelas[i].formas_pgto.formapagto_id === 5)){
               
                setState(prevState => ({...prevState,
                  alerta: {
                    open: true,
                    severity: 'warning',
                    message: `A data do ${dados.conta.parcelas[i].formas_pgto.formapagto_id === 4 ? 'Pix' : 'Boleto'} não pode ser inferior ao dia de amanhã`
                  }
                }))
                return
            }
    
            delete dados.conta.parcelas[i].formapagto_id
          }
        }

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: `Cancelando Contrato...`
          }
        }))
          
        await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/cancelarContrato/${dadosContratoView.id}`, dados, getToken())   
  
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: `Contrato Cancelado.`
          }
        }))

        // UNSAFE_componentWillMount()
        handleCloseModalCancelarContratoUnidade()
        handleCloseModalContrato()

        window.location.reload()

        return
        
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

    }else{
      handleCloseModalCancelarContratoUnidade()
    }
  }

  const handleCloseModalNovoContrato = () => {
    setState(prevState => ({...prevState,
      openModalNovoContrato: false,
      disabledButton: false
    }))
  }

  const updateFieldContrato = (event) => {
    const { dadosContrato } = state

    const name = event.target.name
    const value = event.target.value

    dadosContrato[name] = value

    if((name === 'data_ini_vig' || name === 'unidade_tempo' || name === 'tempo_contrato') && dadosContrato.data_ini_vig !== '' && dadosContrato.unidade_tempo !== '' && dadosContrato.tempo_contrato !== ''){
      dadosContrato.data_fim_vig = moment(dadosContrato.data_ini_vig).add(dadosContrato.tempo_contrato, verificaUnidadeTempo(dadosContrato.unidade_tempo)).subtract(1, 'day').format('YYYY-MM-DD')   
    }

    setState(prevState => ({...prevState, dadosContrato }))
  }

  const updateFieldServico = (event) => {
    const {servico, produtos, contasFinanceira, formasPagamento} = state

    let value = event.target.value
    let name = event.target.name

    if(name === "prod_id"){
      servico.prod_descricao = produtos.list.filter(param => param._id === parseInt(value))[0].descricao
    }

    if(name === "formpag_id"){
      servico.formpag_descricao = formasPagamento.list.filter(param => param.id === parseInt(value))[0].descricao
    }

    if(name === "contfin_id"){
      servico.contfin_descricao = contasFinanceira.list.filter(param => param.id === parseInt(value))[0].descricao
    }

    servico[name] = value
    setState(prevState => ({...prevState, servico }))
  }

  const updateFieldAtivoServico = () => {
    const servico = { ...state.servico }
    servico.ativo = !servico.ativo
    setState(prevState => ({...prevState, servico }))
  }

  const updateFieldMinServico = () => {
    const servico = { ...state.servico }
    servico.habilita_valor_min = !servico.habilita_valor_min
    setState(prevState => ({...prevState, servico }))
  }

  const updateFieldMaxServico = () => {
    const servico = { ...state.servico }
    servico.habilita_valor_max = !servico.habilita_valor_max
    setState(prevState => ({...prevState, servico }))
  }

  const updateFieldInativaUnidadeServico = () => {
    const servico = { ...state.servico }
    servico.inativa_unidade = !servico.inativa_unidade
    setState(prevState => ({...prevState, servico }))
  }

  const validateServico = () => {
    const {servico} = state

    if(servico.prod_id === '') return false
    if(servico.venc_prim_parcela === '') return false
    if(servico.valor === '') return false
    if(servico.formpag_id === '') return false
    if(servico.contfin_id === '') return false

    // if(servico.parametro === '') return false
    // if(servico.habilita_valor_min){
    //   if(servico.valor_min === '') return false
    // }
    // if(servico.habilita_valor_max){
    //   if(servico.valor_max === '') return false
    // }

    return true
  }

  const addItem = () => {
    const {servico, dadosContrato} = state

    if (!validateServico()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios (*)."
        }
      }))
      return
    }

    // if(parseFloat(servico.valor_min.replaceAll(".", "").replace(",", ".")) > parseFloat(servico.valor_max.replaceAll(".", "").replace(",", "."))){
    //   setState(prevState => ({...prevState,
    //     alerta: {
    //       open: true,
    //       severity: 'warning',
    //       message:"O valor mínimo não pode ser superior ao valor máximo!"
    //     }
    //   }))
    //   return
    // }

    servico.parametro = 'FIXO'

    if(servico.index === ""){
      dadosContrato.produtos.push({
        ...servico,
        index: dadosContrato.produtos.length
      })
    }else{
      dadosContrato.produtos[servico.index] = servico
    }

    setState(prevState => ({...prevState,
      dadosContrato,
      servico: {
        prod_id: "",
        prod_descricao: "",
        id: "",
        venc_prim_parcela: "",
        parametro: "",
        valor: "",
        ativo: true,
        habilita_valor_min: false,
        habilita_valor_max: false,
        valor_min: real(0),
        valor_max: real(0),
        formpag_id: '',
        contfin_id: '',
        formpag_descricao: '',
        contfin_descricao: '',
        inativa_unidade: true,
        gerar_nfse: false,
        index: ""
      },
      update: false
    }))
  }

  const removerItem = (index) => {
    const {dadosContrato} = state
    
    let itens = dadosContrato.produtos

    itens.splice(index, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {

      aux.push({
        prod_id: itens[i].prod_id,
        prod_descricao: itens[i].prod_descricao,
        id: itens[i].id,
        venc_prim_parcela: itens[i].venc_prim_parcela,
        parametro: itens[i].parametro,
        valor: itens[i].valor,
        ativo: itens[i].ativo,
        habilita_valor_min: itens[i].habilita_valor_min,
        habilita_valor_max: itens[i].habilita_valor_max,
        inativa_unidade: itens[i].inativa_unidade,
        valor_min: itens[i].valor_min,
        valor_max: itens[i].valor_max,
        index: i
      })
    }

    itens = aux

    dadosContrato.produtos = itens

    setState(prevState => ({...prevState,
      dadosContrato
    }))
  }
  
  const validateForm = () => {
    let {dadosContrato} = state

    if(dadosContrato.data_fim_vig === "") return false
    if(dadosContrato.data_ini_vig === "") return false
    if(dadosContrato.tempo_contrato === "") return false
    if(dadosContrato.unidade_tempo === "") return false
    if(dadosContrato.unidade_id === "") return false
    if(dadosContrato.dias_pagamento === "") return false
    if(dadosContrato.juros === "") return false
    
    return true
  }

  const confirmarNovoContrato = async (value) => {
    if(value){
      let {dadosContrato} = state
  
      if (!validateForm()) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message:"Preencha os campos obrigatorios (*)."
          }
        }))
        return
      }
  
      if(dadosContrato.produtos.length === 0){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message:"Por favor informar ao menos um serviço."
          }
        }))
        return
      }
  
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Cadastrando Contrato...'
        }
      }))

      try {
        let dados = {
          codigo: dadosContrato.codigo,
          data_fim_vig: dadosContrato.data_fim_vig,
          data_ini_vig: dadosContrato.data_ini_vig,
          tempo_contrato: parseInt(dadosContrato.tempo_contrato),
          unidade_id:  parseInt(dadosContrato.unidade_id),
          unidade_tempo: dadosContrato.unidade_tempo,
          dias_pagamento: parseInt(dadosContrato.dias_pagamento),
          juros: parseInt(dadosContrato.juros),
          observacao: dadosContrato.observacao,
          servicos: dadosContrato.produtos.map(servico => {
            return {
              id: servico.id,
              prod_id: parseInt(servico.prod_id),
              venc_prim_parcela: servico.venc_prim_parcela,
              parametro: servico.parametro,
              ativo: servico.ativo,
              valor: parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")),
              habilita_valor_min: servico.habilita_valor_min,
              habilita_valor_max: servico.habilita_valor_max,
              contfin_id: parseInt(servico.contfin_id),
              formpag_id: parseInt(servico.formpag_id),
              inativa_unidade: servico.inativa_unidade,
              valor_min: parseFloat(servico.valor_min.replaceAll(".", "").replace(",", ".")),
              valor_max: parseFloat(servico.valor_max.replaceAll(".", "").replace(",", ".")),
              gerar_nfse: servico.gerar_nfse
            }
          })
        }
  
        setState(prevState => ({...prevState,
          disabledButton: true,
        }))
  
        await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/novoContrato`, dados, getToken())
        
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Contrato cadastrado com sucesso'
          },
          loading:true
        }))
  
        // UNSAFE_componentWillMount()
        handleCloseModalNovoContrato()
        handleCloseModalContrato()
        window.location.reload()

        return 
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          disabledButton: false
        }))
      }
    }else{
      handleCloseModalNovoContrato()
    }
  }

  const updateFieldItem = (e, value) => {
    if(!value){
      setState(prevState => ({...prevState,
        itemSelecionado: {
          id: '',
          descricao: '',
          planocontas_id: '',
          plano_contas: {}
        }
      }))

      return false
    } 

    const {conta} = state

    conta.item_id = value.id
      
    setState(prevState => ({...prevState,
      itemSelecionado: value,
      conta
    }))
  }

  const novoContrato = async () => {
    let dadosContrato ={
      codigo: '',
      data_ini_vig: '',
      data_fim_vig: '',
      unidade_tempo: '',
      tempo_contrato: '',
      unidade_id: '',
      produtos: [],
      dias_pagamento: '',
      juros: '',
      observacao: ''
    }

    await consultaProdutos()

    setState(prevState => ({...prevState,
      dadosContrato,
      openModalNovoContrato: true,
      servico: {
        prod_id: "",
        prod_descricao: "",
        id: "",
        venc_prim_parcela: "",
        parametro: "",
        valor: "",
        ativo: true,
        habilita_valor_min: false,
        habilita_valor_max: false,
        inativa_unidade: true,
        valor_min: real(0),
        valor_max: real(0),
        formpag_id: '',
        contfin_id: '',
        formpag_descricao: '',
        contfin_descricao: '',
        gerar_nfse: false,
        index: ""
      }
    }))
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateFieldGerarNfse = () => {
    let servico = state.servico

    servico.gerar_nfse = !servico.gerar_nfse
    
    setState(prevState => ({...prevState,
      servico
    }))
  }

  const consultarTemplatesDocumento = async (unidade_id) => {
    try {
      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/templatesdocumentos/list`, dados, getToken())

      let array = []

      for (const template of data) {
        for (const versao of template.versoes) {
          if(versao.ativo && versao.publicado && moment(versao.datainicio).isBefore(moment())){
            array.push({
              template_id: template.id,
              versao_id: versao.id,
              titulo: template.titulo,
              assinar: template.assinar,
              versao: versao.versao,
              ativo: versao.ativo
            })
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        template_documentos: {
          list: array
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const updateFieldAssinar = () => {
    const { dadosContrato } = state
    dadosContrato.assinar_contrato = !dadosContrato.assinar_contrato
    setState(prevState => ({...prevState, dadosContrato }))
  }

  const updateFieldUnidade = (event, value) => {
    setState(prevState => ({...prevState,
      unidade: value
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'contrato-unidade')[0]
  
      let acoesTabela = []
  
      if (permissoes.visualizar) {
        acoesTabela.push('viewContrato')
      }
  
      setState(prevState => ({...prevState,
        acoesTabela,
        permissoes
      }))
  
      const pessoa = JSON.parse(localStorage.getItem('pessoa'))
      const unin_tipo = pessoa.fisica.funcionario.contrato.unidadetrabalho.unin_tipo
  
      setState(prevState => ({...prevState,
        unin_tipo
      }))
  
      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }
  
        const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())
  
        setState(prevState => ({...prevState,
          formasPagamento: {
            list: formasPagamento.filter(param => param.ativo === true && param.slug !== 'voucher_produto')
          }
        }))
  
        const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidade/1`, getToken())
        
        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contasFinanceira
          }
        }))
  
        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list`, dados, getToken())
        
        setState(prevState => ({...prevState,
          unidades:{
            list: unidades.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).filter(param => (param.tipo === 'UNIDADE')).map(unidade => {
              return {
                id: unidade.id,
                descricao: unidade.descricao.toUpperCase(),
                status: unidade.status
              }
            })
          }
        }))

        const { data: configuracao } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/1`, getToken())
          
        setState(prevState => ({...prevState,
          possui_emissao_nfse: configuracao ? configuracao.habilitar_emissao : false
        }))
  
        await consultarTemplatesDocumento(1)

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
  
      await filtrarContratos()
      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { filtro, contratos, permissoes, dadosContratoView, financeiros_pendentes, conta, contasFinanceira, 
    formasPagamento, dadosCancelamento, itens_receita_despesa, servico, update, produtos, unidades, itemSelecionado } = state

  return (
    <div className="app-menu-closed" id="app">
      <Main openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        {state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Contratos...</p>
            </div>
          </React.Fragment>
        }
        {!state.loading &&
          <div>
            <Grid
              container
              spacing={1}
              direction="row"
              className="borderBottom"
            >
              <Grid item md={9} xs={12}>
                <h1 className="titulo">Contratos</h1>
              </Grid>
              {permissoes.inserir &&
                <Grid item md={3} xs={12}>
                  <Button className="btnCadastrar" variant="contained" color="primary" onClick={() => novoContrato()}>
                    Cadastrar Contrato
                  </Button>
                </Grid>
              }
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_20">
              <Grid item md={4} xs={12} sm={4}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Unidade"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="unidade_id"
                  value={filtro.unidade_id}
                  onChangeCapture={(e) => updateFiltro(e)}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <option value="">  </option>
                  {unidades.list.map((unidade, key) => {
                    return (
                      <option key={key} value={unidade.id}> {`${unidade.descricao}`} </option>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={4}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Situação"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="situacao"
                  value={filtro.situacao}
                  onChangeCapture={(e) => updateFiltro(e)}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <option value=""></option>
                  <option value="ATIVO">Ativo</option>
                  <option value="CANCELADO">Cancelado</option>
                  <option value="ENCERRADO">Encerrado</option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={3}>
                <Button fullWidth variant="contained" color="warning" onClick={() => filtrarContratos()} disabled={state.loadingImprimir}>
                  Filtrar
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12}>
                <Table 
                  headCell={state.cabecalhoTabela} 
                  rows={contratos.list} 
                  acoes={state.acoesTabela} 
                  viewContrato={e => viewContrato(e)}
                />
              </Grid>
            </Grid>
          </div>
        }
        <ModalContrato 
          open={state.openModalContrato}
          handleClose={e => handleCloseModalContrato()}
          dados={dadosContratoView}
          cancelarContrato={() => cancelarContrato()}
          addServico={() => addServico()}
          renovarContrato={() => renovarContrato()}
          permissoes={permissoes}
        />

        <ModalCancelarContratoUnidade 
          open={state.openModalCancelarContratoUnidade}
          handleClose={e => handleCloseModalCancelarContratoUnidade()}
          financeiros_pendentes={financeiros_pendentes.list}
          dados={dadosCancelamento}
          updateFieldLancFinanceiroCancelamento={() => updateFieldLancFinanceiroCancelamento()}
          conta={conta}
          contasFinanceira={contasFinanceira.list}
          formasPagamento={formasPagamento.list}
          updateFieldConta={e => updateFieldConta(e)}
          updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
          mudarParcelas={e => mudarParcelas(e)}
          recalcularParcela={(e, value) => recalcularParcela(e, value)}
          itens_receita_despesa={itens_receita_despesa.list}
          updateField={(e) => updateFieldCancelamento(e)}
          confirmar={(e) => confirmarCancelamento(e)}
          updateFieldItem={(e, value) => updateFieldItem(e, value)}
          itemSelecionado={itemSelecionado}
        />
        <ModalNovoContrato 
          open={state.openModalNovoContrato}
          handleClose={e => handleCloseModalNovoContrato()}
          dados={state.dadosContrato}
          updateField={e => updateFieldContrato(e)}
          servico={servico}
          update={update}
          produtos={produtos.list}
          updateFieldServico={(e) => updateFieldServico(e)}
          updateFieldAtivoServico={() => updateFieldAtivoServico()}
          updateFieldMinServico={() => updateFieldMinServico()}
          updateFieldMaxServico={() => updateFieldMaxServico()}
          updateFieldInativaUnidadeServico={() => updateFieldInativaUnidadeServico()}
          contasFinanceira={contasFinanceira.list}
          formasPagamento={formasPagamento.list}
          addItem={() => addItem()}
          removerItem={(e) => removerItem(e)}
          disabledButton={state.disabledButton}
          confirmar={e => confirmarNovoContrato(e)}
          updateFieldUnidade={(event, value) => updateFieldUnidade(event, value)}
          unidades={unidades.list}
          unidade={state.unidade}
          disabledUnidade={false}
          possui_emissao_nfse={true}
          updateFieldGerarNfse={() => updateFieldGerarNfse()}
          template_documentos={state.template_documentos.list}
          updateFieldAssinar={() => updateFieldAssinar()}
        />
        <Alert 
          open={state.alerta.open}
          handleClose={e => handleCloseAlerta()} 
          severity={state.alerta.severity}
          message={state.alerta.message} />
      </Main>
      <Nav />
    </div>
  )
}

export default ContratoUnidade
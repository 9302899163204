import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField, Button } from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import real from './../../services/real'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const FormContaPagarReceber = ({ dados, updateField, formasPagamento, verificaDataParcela, 
  updateFieldParcela, mudarParcelas, recalcularParcela, verificaCaixaInicial, cliente, cadastrarCliente,
  pessoasFiltrado, updateFieldPessoa, updateParcelado, updateRecorrencia, contasFinanceira, unidades,
  marcaParcelas, itemSelecionado, itens_receita_despesa, updateFieldItem, logado, marcaParcelasContaFin, travarCampos,
  updateFiltroPessoa, gridRefTipoConta, gridRefItemRecDesp, gridRefDescricao, gridRefEmissao, gridRefValorTotal,
  gridRefCliente, gridRefRepetirACada }) => (
  <React.Fragment>
    {!verificaCaixaInicial().libera &&
      <Grid container direction="row" spacing={1} className="mg_top_20">
        <Grid item md={12} xs={12} sm={12}>
          <p>{verificaCaixaInicial().msg}</p>
        </Grid>
      </Grid>
    }

    {verificaCaixaInicial().libera &&
      <React.Fragment>
        <Grid container direction="row" spacing={1} className="mg_top_20">
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Unidade"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="unidade_id"
              value={dados.unidade_id}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=""> Selecionar Unidade</option>
              {unidades.map(unidade => {
                return (
                  <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
                )
              })}
            </TextField>
          </Grid>
          <Grid ref={gridRefTipoConta} item md={2} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Tipo da Conta"
              variant="outlined"
              className="input"
              fullWidth
              SelectProps={{
                native: true,
              }}
              name="tipo"
              size="small"
              value={dados.tipo}
              onChangeCapture={(e) => updateField(e)}
              InputLabelProps={{ shrink: true }}
              disabled={dados.unidade_id === '' || travarCampos}
            >
              <option value="">Selecionar Tipo</option>
              <option value="PAGAR">Despesa</option>
              <option value="RECEBER">Receita</option>
            </TextField>
            {/* <TextField className="input" label="Tipo da Conta*" variant="outlined" name="tipo" value={dados.tipo === '' ? '' : (dados.tipo === 'PAGAR' ? 'A Pagar' : 'A Receber')} InputLabelProps={{ shrink: true }} disabled/> */}
          </Grid>
          <Grid ref={gridRefItemRecDesp} item md={3} xs={12} sm={6}>
            <Autocomplete
              onChange={(event, value) => updateFieldItem(event, value)}
              freeSolo
              options={itens_receita_despesa}
              value={itemSelecionado}
              getOptionLabel={option => `${option.descricao}`}
              renderInput={(params) => (
                <TextField InputProps={{ ...params.InputProps, type: 'search' }} {...params} size="small" label="Item Receita/Despesa*" margin="normal" variant="outlined" InputLabelProps={{ shrink: true }} value={`${itemSelecionado.descricao}`} />
              )}
            />
          </Grid>
          <Grid ref={gridRefDescricao} item md={4} xs={12} sm={6}>
            <TextField 
              className="input" 
              label="Descrição*" 
              variant="outlined" 
              name="descricao"
              size="small"
              value={dados.descricao} 
              onChange={(e) => updateField(e)} 
              InputLabelProps={{ shrink: true }}
              disabled={dados.tipo === '' ? true: false} />
          </Grid>
          <Grid ref={gridRefEmissao} item md={2} xs={12} sm={6}>
            <TextField 
              type='date'
              className="input" 
              label="Data da Emissão" 
              variant="outlined" 
              name="data_documento" 
              size="small"
              value={dados.data_documento} 
              onChange={(e) => updateField(e)} 
              InputLabelProps={{ shrink: true }}
              disabled={travarCampos ? true : (dados.tipo === '' ? true: false)} />
          </Grid>
          <Grid ref={gridRefValorTotal} item md={2} xs={12} sm={6}>
            <TextField 
              className="input" 
              label="Valor*" 
              variant="outlined" 
              name="valortotal" 
              size="small"
              value={dados.valortotal} 
              onChange={(e) => updateField(e)} 
              InputLabelProps={{ shrink: true }} 
              InputProps={{ inputComponent: CurrencyInput }}
              disabled={travarCampos ? true : (dados.tipo === '' ? true: false)} />
          </Grid>
          <Grid ref={gridRefCliente} item md={4} xs={12} sm={6}>
            {dados.unidade_id === '' && 
              <TextField 
                className="input" 
                label="Cliente/Fornecedor*" 
                variant="outlined" 
                name="descricao" 
                size="small"
                value='Selecionar Unidade...'
                InputLabelProps={{ shrink: true }}
                disabled={true} />
            }
            {dados.unidade_id !== '' &&
              <Autocomplete
                onChange={(event, value) => updateFieldPessoa(event, value)}
                freeSolo
                options={pessoasFiltrado.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)}
                value={cliente}
                getOptionLabel={option => `${option.nome}`}
                renderInput={(params) => (
                  <TextField 
                    InputProps={{ ...params.InputProps, type: 'search' }} 
                    {...params} 
                    size="small" 
                    label="Cliente/Fornecedor*" 
                    margin="normal" 
                    variant="outlined" 
                    InputLabelProps={{ shrink: true }} 
                    value={`${cliente.nome}`}
                    onChange={(e) => updateFiltroPessoa(e)}
                  />
                )}
              />
            }
          </Grid>
          <Grid item md={4} xs={12} sm={6}>
            <Button
              fullWidth 
              color={'warning'} 
              variant="contained" 
              size="small" 
              endIcon={<SaveIcon/>} 
              onClick={e => cadastrarCliente()}
              id="button-cadastro-cliente"
            >
              Cadastrar Cliente/Fornecedor
            </Button>
          </Grid>
        </Grid>
        <hr className="mg_top_10"/>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={2} xs={2} sm={2}>
            <Button
              fullWidth 
              color={dados.parcelado ? 'primary' : 'inherit'} 
              variant="contained" 
              size="small" 
              endIcon={dados.parcelado ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />} 
              onClick={e => updateParcelado()}
              disabled={travarCampos}
              id="button-parcelamento"
            >
              Parcelamento
            </Button>
          </Grid>
          <Grid item md={2} xs={2} sm={2}>
            <Button 
              fullWidth 
              color={dados.recorrencia ? 'secondary' : 'inherit'} 
              variant="contained" 
              size="small" 
              endIcon={dados.recorrencia ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />}
              onClick={e => updateRecorrencia()}
              disabled={travarCampos}
              id="button-recorrencia"
            >
              Recorrência
            </Button>
          </Grid>
          <Grid item md={1} xs={1} sm={1}></Grid>
          {dados.recorrencia && 
            <React.Fragment>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  id='inputRepetirACada'
                  type='number'
                  className="input" 
                  label="Repetir a cada*" 
                  variant="outlined" 
                  name="recorrenciaACada" 
                  size="small"
                  value={dados.recorrenciaACada} 
                  onChange={(e) => updateField(e)} 
                  InputLabelProps={{ shrink: true }}/>
              </Grid>
              <Grid item md={1} xs={12} sm={6}>
                <TextField
                  id="inputTipoRecorrencia"
                  select
                  label=""
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="tipoRecorrencia"
                  value={dados.tipoRecorrencia}
                  onChangeCapture={(e) => updateField(e)}
                >
                  <option value='d'> Dia </option>
                  <option value='w'> Semana </option>
                  <option value='M'> Mês </option>
                  <option value='y'> Ano </option>
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  id="inputNumRecorrencia"
                  type='number'
                  className="input" 
                  label="Num. de Ocorrências*" 
                  variant="outlined" 
                  name="qtdRecorrencia" 
                  size="small"
                  value={dados.qtdRecorrencia} 
                  onChange={(e) => updateField(e)} 
                  InputLabelProps={{ shrink: true }}/>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  id="inputTotalRecorrencia"
                  className="input" 
                  label="Totalizando*" 
                  variant="outlined" 
                  name="qtdRecorrencia" 
                  size="small"
                  value={dados.valortotal === "" ? '0,00' : real(parseFloat(dados.valortotal.replaceAll(".", "").replace(",", ".")) * dados.qtdRecorrencia)} 
                  InputLabelProps={{ shrink: true }}
                  disabled/>
              </Grid>
            </React.Fragment>
          }
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <div id="tabela-parcelas" className='scroll'>
              <table  className="lista sempadding borderTable">
                <thead>
                  <tr className="titulos acompanha">
                    <th>Parcelas</th>
                    <th>Valor*</th>
                    <th>Vencimento*</th>
                    <th>Forma de Pagamento*</th>
                    <th>Contas Bancárias</th>
                    <th>Documento</th>
                  </tr>
                </thead>
                <tbody>
                  {dados.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                    return (
                      <tr key={parcela.index}>
                        <td>
                          <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
                          <span className="separacaoParcelas">/</span>
                          <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={dados.qtd_parcelas} onChange={(e) => updateField(e)} onBlur={(e) => mudarParcelas(e)} InputLabelProps={{ shrink: true }} disabled={!dados.parcelado}/>
                        </td>
                        <td>
                          <TextField 
                            className="inputValor" 
                            variant="outlined" 
                            size="small" 
                            name="valorparcela" 
                            value={parcela.valorparcela} 
                            onBlur={(e) => recalcularParcela(e, parcela.index)} 
                            InputLabelProps={{ shrink: true }} 
                            InputProps={{ inputComponent: CurrencyInput }} 
                            disabled={travarCampos}
                          />
                        </td>
                        <td>
                          <TextField 
                            type="date" 
                            className="inputVencimento" 
                            variant="outlined" 
                            size="small" 
                            name="datavencimento" 
                            value={parcela.datavencimento} 
                            onBlur={(e) => verificaDataParcela(e, parcela.index)} 
                            onChange={(e) => updateFieldParcela(e, parcela.index)} 
                            InputLabelProps={{ shrink: true }}
                            disabled={travarCampos}
                          />
                        </td>
                        <td>
                          <TextField
                            id="standard-select-currency"
                            select
                            variant="outlined"
                            className="inputFormPagamento"
                            fullWidth
                            size="small"
                            SelectProps={{
                              native: true,
                            }}
                            name="formapagto_id"
                            value={parcela.formapagto_id}
                            onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                            onBlur={(e) => marcaParcelas(e, parcela.index)}
                            InputLabelProps={{ shrink: true }}
                          >
                            <option value="">--Escolha--</option>
                            {formasPagamento.map(forma => {
                              return (
                                <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                              )
                            })}
                          </TextField>
                        </td>
                        <td>
                          <TextField
                            id="standard-select-currency"
                            select
                            variant="outlined"
                            className="inputFormPagamento"
                            fullWidth
                            size="small"
                            SelectProps={{
                              native: true,
                            }}
                            name="contfin_id"
                            value={parcela.contfin_id}
                            onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                            onBlur={(e) => marcaParcelasContaFin(e, parcela.index)}
                            InputLabelProps={{ shrink: true }}
                            disabled={travarCampos}
                          >
                            <option value="">--Escolha--</option>
                            {contasFinanceira.filter(param => param.tipo_conta === parcela.habilita || (param.tipo_conta === "CAIXATURNO" && logado === 'caixa') ).map(conta => {
                              return (
                                <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                              )
                            })}
                          </TextField>
                          {/* <Select
                            fullWidth
                            className={`${parcela.contfin_id !== '' ? 'selecionado' : 'nao_selecionado'} inputConta`}
                            variant="outlined"
                            name="contfin_id"
                            value={parcela.contfin_id}
                            onChange={(e) => updateFieldParcela(e, parcela.index)}
                          >
                            <MenuItem value=''>
                              <ListItemIcon>
                              </ListItemIcon>
                              <ListItemText primary={'Selecionar Conta'} />
                            </MenuItem>
                            {contasFinanceira.filter(param => param.tipo_conta === parcela.habilita || (param.tipo_conta === "CAIXATURNO" && logado === 'caixa') ).map(conta => {
                              return (
                                <MenuItem key={conta.id} value={conta.id}>
                                  <ListItemIcon>
                                    <img style={{width: '30px', height: '30px', marginRight: '5px'}} src={conta.conta_corrente ? conta.conta_corrente.conta.banco.logo : 'https://w7.pngwing.com/pngs/196/115/png-transparent-bank-logo-euro-truck-simulator-2-bank-loan-finance-banks-pattern-building-investment-logo-thumbnail.png'} alt='logo'/>
                                  </ListItemIcon>
                                  <ListItemText primary={conta.descricao} />
                                </MenuItem>
                              )
                            })}
                          </Select> */}
                        </td>
                        <td>
                          <TextField className="inputDocumento" variant="outlined" size="small" name="documento" value={parcela.documento} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    }
  </React.Fragment>
)

export default FormContaPagarReceber
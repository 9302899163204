import React, { useState, useEffect, useRef } from 'react';
import './Relatorios.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { saveAs } from 'file-saver'
import Alert from './../../components/templates/Alert'
import Table from '../../components/Table'
import real from './../../services/real'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  filtro: {
    relatorio: "",
    data_inicial: '',
    data_final: '',
    unidade_id: "",
    funcionario_id: ""
  },
  cabecalhoTabela: [],
  acoesTabela: [],
  cabecalhoTabela2: [],
  acoesTabela2: [],
  relatorios: {
    list: []
  },
  relatorios2: {
    list: []
  },
  cabecalhoTabelaModal: [],
  acoesTabelaModal: [],
  relatoriosModal: {
    list: []
  },
  relatorios_options: {
    list: [{
      perm: {
        visualizar: false
      }
    }]
  },
  loading: false,
  urlView: '',
  urlView2: '',
  urlViewModal: '',
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  schedules: {
    list: []
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  unidades:{
    list: []
  },
  planosConta: {
    list: []
  },
  ModalRelatorio: false,
  funcionarios:{
    list:[]
  },
  openModalAjuda: false,
  markdown: ''
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

const RelComissoes = (props) => {
  const [state, setState] = useState({ ...initialState });

  const getToken = () =>{
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const consultaFuncionarios = async (unidade_id) => {
    try {
      let aux = {
        ativo: true,
        nome: '',
        unidade_descricao: '',
        funcao_descricao: ''
      }
  
      aux.unidadesnegocio = [unidade_id]

      const { data: funcionarios } = await axios.post(`${window._env_.REACT_APP_API_URL}/funcionarios/list/filtro`, aux, getToken())

      setState(prevState => ({...prevState,
        funcionarios: {
          list: funcionarios
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldFiltro = async (event) => {
    const filtro = state.filtro

    let name = event.target.name
    let value = event.target.value

    filtro[name] = value

    if(name === 'unidade_id'){
      await consultaFuncionarios(value)
    }

    setState(prevState => ({...prevState, 
      filtro,
      relatorios: {
        list: []
      },
      relatorios2: {
        list: []
      },
      cabecalhoTabela: [],
      acoesTabela: [],
      cabecalhoTabela2: [],
      acoesTabela2: [],
    }))
  }

  const filtrar = async () => {
    const {filtro} = state

    setState(prevState => ({...prevState,
      loading: true
    }))

    if (filtro.unidade_id === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a unidade'
        },
        loading: false
      }))
      return
    }

    if (filtro.data_inicial === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data inicial'
        },
        loading: false
      }))
      return
    }

    if (filtro.data_final === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message:'Por favor informar a data final'
        },
        loading: false
      }))
      return

    }

    if (filtro.funcionario_id === '' || !filtro.funcionario_id) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message:'Por favor informar o atendente!'
        },
        loading: false
      }))
      return
    }
    
    try {
     
      filtro.relatorio = 'comissoes'

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio`, filtro, getToken())     
              
      setState(prevState => ({...prevState,
        cabecalhoTabela: data.tabela_1.cabecalhoTabela,
        acoesTabela: data.tabela_1.acoesTabela,
        relatorios: {
          list: data.tabela_1.list
        },
        urlView: data.tabela_1.urlView,
        cabecalhoTabela2: data.tabela_2.cabecalhoTabela,
        acoesTabela2: data.tabela_2.acoesTabela,
        relatorios2: {
          list: data.tabela_2.list
        },
        urlView2: data.tabela_2.urlView,
        loading: false
      }))
      
      return {
        relatorios: data.tabela_1.list,
        relatorios2: data.tabela_2.list
      }

    } catch (error) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
      console.log(error)
    }


  }

  const imprimir = async () => {
    setState(prevState => ({...prevState,
      loadingDownload: true
    }))

    // const { relatorios, relatorios2 } = await filtrar()

    const { filtro, funcionarios, relatorios, relatorios2} = state

    try {
      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

      const dados = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        })
      }

      filtro.unidadesnegocio = dados.unidadesnegocio

      filtro.list = relatorios
      filtro.list2 = relatorios2

      filtro.atendente = funcionarios.list.filter(param => param._id === parseInt(filtro.funcionario_id))[0].nome

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/relatorio/imprimir`, filtro, getToken())

      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })

      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      setState(prevState => ({...prevState,
        loadingDownload: false
      }))

      saveAs(pdfBlob, 'Comissoes.pdf')
      
    } catch (error) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loadingDownload: false
      }))
      console.log(error)
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'comissoes')
  
      setState(prevState => ({...prevState,
        relatorios_options: {
          list: permissoes.map(value => {
            return {
              descricao: value.tela.descricao,
              slug: value.tela.slug,
              perm: {
                visualizar: value.visualizar
              }
            }
          })
        }
      }))
  
      try {
  
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }
  
        const { data: unidades } = await axios.post(`${window._env_.REACT_APP_API_URL}/unidades/list/grupoAll`, dados, getToken())
  
        setState(prevState => ({...prevState,
          unidades: {
            list: unidades.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0).map(unidade => {
              let descricao = ''
              if (unidade.tipo === 'MANTENEDOR') {
                descricao = `Mantenedor - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              }if (unidade.tipo === 'REPRESENTANTE') {
                descricao = `Representante - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'GRUPOECONOMICO') {
                descricao = `Grupo Econômico - ${unidade.pessoa.tipo === "JURIDICA" ? unidade.pessoa.juridica.razaosocial : unidade.pessoa.fisica.nome}`
              } else if (unidade.tipo === 'UNIDADE') {
                descricao = `Unidade - ${unidade.numero} - ${unidade.descricao}`
              }
  
              return {
                id: unidade.id,
                descricao,
                tipo: unidade.tipo
              }
            })
          }
        }))
  
        if(unidades.length === 1){
          const {filtro} = state
          filtro.unidade_id = unidades[0].id
  
          await consultaFuncionarios(unidades[0].id)
  
          setState(prevState => ({...prevState,filtro}))
        }
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    };
    
    fetchData();
  }, []);

  const { filtro, funcionarios, cabecalhoTabela, 
    loadingDownload,  unidades, urlView, relatorios, acoesTabela } = state

  return (
    <div className="app-menu-closed" id="app">
      <Main history={props.history} openModalAjuda={() => {setState(prevState => ({...prevState,openModalAjuda: true}))}}>
        <Grid container spacing={2} direction="row" className="borderBottom">
          <Grid item md={11} xs={10}>
            <h1 className="titulo">Comissões</h1>
          </Grid>
          {loadingDownload &&
            <Grid item md={1} xs={2}>
              <CircularProgress />
            </Grid>
          }
        </Grid>
        <Grid container spacing={2} direction="row" className="mg_top_20">
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Unidade"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="unidade_id"
              value={filtro.unidade_id}
              onChangeCapture={(e) => updateFieldFiltro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=""> Selecionar Unidade</option>
              {unidades.list.map(unidade => {
                return (
                  <option key={unidade.id} value={unidade.id}> {unidade.descricao} </option>
                )
              })}
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField className="input" type='date' label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField className="input" type='date' label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => updateFieldFiltro(e)} InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item md={3} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Atendente"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="funcionario_id"
              value={filtro.funcionario_id}
              onChangeCapture={(e) => updateFieldFiltro(e)}
              InputLabelProps={{ shrink: true }}
            >
              <option value=""> Selecionar Atendente</option>
              {funcionarios.list.map(func => {
                return (
                  <option key={func._id} value={func._id}> {func.nome} </option>
                )
              })}
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={3}>
            <Button fullWidth variant="contained" color="primary" onClick={() => filtrar()}>
              Filtrar
            </Button>
          </Grid>
          {relatorios.list.length > 0 &&
            <Grid item md={2} xs={12} sm={3}>
              <Button fullWidth variant="contained" color="secondary" onClick={() => imprimir()} disabled={loadingDownload}>
                Imprimir
              </Button>
            </Grid>
          }
        </Grid>
        <hr className="mg_top_10"/>
        {state.loading &&
          <React.Fragment>
            <div className="progressCircular">
              <CircularProgress />
            </div>
            <div className="progressText">
              <p>Carregando Relatório...</p>
            </div>
          </React.Fragment>
        }
        {!state.loading &&
          <React.Fragment>
            {cabecalhoTabela.length > 0 &&
              <React.Fragment>
                <Grid container spacing={2} direction="row" className="mg_top_10">
                  <Grid item md={12} xs={12}>
                    <Table
                      urlView={urlView}
                      headCell={cabecalhoTabela}
                      rows={relatorios.list}
                      acoes={acoesTabela}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} direction="row">
                  <Grid item md={9} xs={12} sm={6}>
                    <h4>Acumulado por Serviço:</h4>
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <h4 style={{textAlign: 'right'}}>Valor Total: R$ {real(somarValores(relatorios.list.map(value => {return parseFloat(value.valor_comissao.replace("R$", "").replaceAll(".", "").replace(",", "."))})))}</h4>
                  </Grid>
                </Grid>
              </React.Fragment>
            }

            {state.cabecalhoTabela2.length > 0 &&
              <Grid container spacing={2} direction="row">
                <Grid item md={12} xs={12}>
                  <Table
                    urlView={state.urlView2}
                    headCell={state.cabecalhoTabela2}
                    rows={state.relatorios2.list}
                    acoes={state.acoesTabela2}
                  />
                </Grid>
              </Grid>
            }
          </React.Fragment>
        }
      </Main>
      <Nav history={props.history}/>
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />
      <ModalAjuda 
        open={state.openModalAjuda}
        tela={"Comissões"}
        handleClose={() => {setState(prevState => ({...prevState,openModalAjuda: false}))}}
        markdown={state.markdown}
      />
    </div>
  )
}

export default RelComissoes
import React from 'react';
import Joyride from 'react-joyride';
import { Button } from '@mui/material'

const JoyrideTuor = ({steps, run, setRun, getIndex}) => (
  <Joyride
    steps={steps}
    run={run}
    continuous
    scrollToFirstStep
    showProgress
    showSkipButton
    disableBeacon={true}
    tooltipComponent={({ index, step, backProps, closeProps, primaryProps, skipProps }) => (
      <div style={{ maxWidth: '90%', marginLeft: '5%' ,padding: "10px", backgroundColor: "#FFF", color: "#000", borderRadius: "8px" }}>
        <p style={{fontSize: 16}} ><b>{step.content}</b></p>
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
          {index > 0 && (
            <Button  {...backProps} size="small" variant="contained" color="secondary">
              VOLTAR
            </Button>
          )}
          {index === 0 && <button {...skipProps} style={{ background: "none", border: "none", color: "#000" }}>Pular</button>}
          {index === steps.length - 1 ? (
            <Button  {...closeProps} size="small" variant="contained" color="primary">
              FINALIZAR
            </Button>
          ) : (
            <Button  {...primaryProps} size="small" variant="contained" color="primary">
              PRÓXIMO
            </Button>
          )}
        </div>
      </div>
    )}
    callback={(data) => {
      if(data.action === "next" && data.lifecycle === "ready"){
        if(getIndex){
          getIndex(data.index)
        }
      }
      if (data.status === "finished" || data.status === "skipped" || data.action === "close") {
        setRun(false);
      }
    }}
  />
)

export default JoyrideTuor

import React, { useState, useEffect } from 'react';
import './Movimentacao.scss'
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@mui/material'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment';
import real from '../../services/real'
import Alert from './../../components/templates/Alert'
import ModalConfirmarConciliacoes from './../../components/modals/ConfirmaConciliacoes'
import ModalAlteraDataMovimentacao from './../../components/modals/AlteraDataMovimentacao'
import ModalConfirmacaoConciliarMov from './../../components/modals/EstornarParcelas'
import Loading from './../../components/templates/Loading'
import { styled } from '@mui/system';
import Color from 'color';
import { useParams, useNavigate } from 'react-router-dom';

const initialState = {
  movimentacao: {

  },
  movimentacoes: {
    list: []
  },
  movimentacoesFiltrado: {
    list: []
  },
  contaFinanceiras: {
    list: []
  },
  filtro: {
    unidadesnegocio: {},
    data_inicial: '',
    data_final: '',
    conciliado: '',
    tipo: '',
    descricao: ''
  },
  cabecalhoTabela: [
    { id: 'data_hora', numeric: false, disablePadding: false, label: 'Data/Hora' },
    { id: 'contfin_descricao', numeric: false, disablePadding: true, label: 'Conta' },
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição', habilitaFiltro: true, filtro:'descricao'},
    { id: 'forma_pgto', numeric: false, disablePadding: true, label: 'Forma de Pagto.', habilitaFiltro: true, filtro:'forma' },
    { id: 'valor', numeric: false, disablePadding: false, label: 'Valor Mov.', habilitaFiltro: true, filtro:'valor' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  movimentacoesAConciliar:[],
  saldo_contas_unidades: {
    list: []
  },
  modalConfirmaConciliacaoOpen: false,
  saldoConta: {
    id: '',
    ordem: '',
    descricao: '',
    saldo_conciliado: '',
    saldo_nao_conciliado: '',
    data: '',
    saldo_total: ''
  },
  saldoContaTotal: {
    id: '',
    ordem: '',
    descricao: '',
    saldo_conciliado: '',
    saldo_nao_conciliado: '',
    data: '',
    saldo_total: ''
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  mov_selecionada:{
    _id: '',
    descricao: '',
    valor: '',
    data_hora_sem_form:''
  },
  openModalAlteraDataMovimentacao: false,
  loadingAlteraData: false,
  filtros_tela: {
    descricao: '',
    forma_pgto: '',
    valor: '',
  },
  modalConfirmacaoConciliarMovOpen: false,
  parcelas_filhos: [],
  event: {},
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesExtrato: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  loading: true,
  mensagemLoading: 'Carregando Movimentações...',
  openModalImportacaoExtrato: false,
  uploadedFiles: [],
  movimentacoes_extrato_filtrado: [],
  movimentacoes_extrato: [],
  conta_extrato: {
    id: '',
    descricao: '',
    data_inicial: '',
    data_final: '',
    saldo_fechamento: 0,
    exibir_conciliado: 'Não'
  },
  extrato_enviado: false,
  mov_selecionadas_extrato: [],
  mov_selecionadas_sistema: [],
  openModalConfirmacao: false,
  modal: {
    mensagem: ''
  }
}

function somarValores(array) {
  var soma = 0;
  for (var i = 0; i < array.length; i++) {
    soma += array[i];
  }
  return soma
}

function searching(term, itemFiltro) {
  return function (x) {
    return x[itemFiltro].toLowerCase().includes(term.toLowerCase()) || !term;
  }
}

const Movimentacao = () => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const backPage = (timeout = 1000) => {
    const self = this

    setTimeout(() => {
      self.navigate("/home");
    }, timeout)
  }

  const updateFiltro = (event) => {
    const filtro = state.filtro

    filtro[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      filtro
    }))
  }

  const filtrar = async () => {
    let filtro = state.filtro

    if (filtro.data_inicial === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data inicial'
        }
      }))
      return
    }

    if (filtro.data_final === '') {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Por favor informar a data final'
        }
      }))
      return
    }

    localStorage.setItem('filtro_movimentacoes', JSON.stringify(filtro));

    window.location.reload()
    
  }

  const conciliarMov = (event) => {
      if(event.conciliado_atual && event.conciliado){
      if(event.valor_pago){
        if(event.valor_pago.parcela.parcelas_filhos.length > 0){
          setState(prevState => ({...prevState,
            modalConfirmacaoConciliarMovOpen: true,
            parcelas_filhos: event.valor_pago.parcela.parcelas_filhos,
            event
          }))
          return
  
        }else if (event.valor_pago.parcela.parcela_pai){
          setState(prevState => ({...prevState,
            modalConfirmacaoConciliarMovOpen: true,
            parcelas_filhos: [{...event.valor_pago.parcela.parcela_pai}],
            event
          }))
          return
        }
      }
    }
    
    continuarConciliacao(true, event)
  }

  const continuarConciliacao = (value, event) => {
    let {movimentacoes, movimentacoesAConciliar} = state 

      const index = movimentacoes.list.findIndex(param => param._id === event._id)
  
      movimentacoes.list[index].conciliado = movimentacoes.list[index].conciliado ? false : true
  
      const indexConc = movimentacoesAConciliar.findIndex(param => param.id === event._id)
  
      if(indexConc === -1){
        movimentacoesAConciliar.push({
          id : event._id,
          valor: parseFloat(event.valor.replace('R$', '').replaceAll(".", "").replace(",", "."))
        })
      }else{
        movimentacoesAConciliar.splice(indexConc, 1)
      }

      if(value){
        if(event.conciliado_atual && !event.conciliado){
          if(event.valor_pago){
            if(event.valor_pago.parcela.parcelas_filhos.length > 0){
              for (const parcela of event.valor_pago.parcela.parcelas_filhos) {
                for (const forma_pagto of parcela.valores_pago) {
                  if(forma_pagto.mov.conciliado){
                    const index = movimentacoes.list.findIndex(param => param._id === forma_pagto.mov.id)
        
                    movimentacoes.list[index].conciliado = movimentacoes.list[index].conciliado ? false : true
                    
                    const indexConc = movimentacoesAConciliar.findIndex(param => param.id === forma_pagto.mov.id)
          
                    if(indexConc === -1){
                      movimentacoesAConciliar.push({
                        id : forma_pagto.mov.id,
                        valor: forma_pagto.mov.valor
                      })
                    }else{
                      movimentacoesAConciliar.splice(indexConc, 1)
                    }
                  }
                }
              }
            }else if (event.valor_pago.parcela.parcela_pai){
              for (const forma_pagto of event.valor_pago.parcela.parcela_pai.valores_pago) {
                if(forma_pagto.mov.conciliado){
                  const index = movimentacoes.list.findIndex(param => param._id === forma_pagto.mov.id)
        
                  movimentacoes.list[index].conciliado = movimentacoes.list[index].conciliado ? false : true
    
                  const indexConc = movimentacoesAConciliar.findIndex(param => param.id === forma_pagto.mov.id)
      
                  if(indexConc === -1){
                    movimentacoesAConciliar.push({
                      id : forma_pagto.mov.id,
                      valor: forma_pagto.mov.valor
                    })
                  }else{
                    movimentacoesAConciliar.splice(indexConc, 1)
                  }
                }
              }
      
            }
          }
        }
      }

    
      setState(prevState => ({...prevState,
        movimentacoes,
        movimentacoesAConciliar
      }))

      handleCloseConfirmacaoConciliarMov()    
  }

  const salvarConciliacoes = async (abreCadastro) => {
    const {movimentacoesAConciliar} = state

    try {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Conciliando Movimentações.'
        }
      }))

      await axios.post(`${window._env_.REACT_APP_API_URL}/movimentoContaFinanceira/conciliar`, movimentacoesAConciliar, getToken())
      
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Atualização realizada com sucesso.'
        }
      }))

      if(abreCadastro){
        navigate("/movimentacao_financeira/cadastro");
        return;
      }else{
        window.location.reload()
      }
    
    }catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleClose = () => {
    setState(prevState => ({...prevState,
      modalConfirmaConciliacaoOpen: false
    }))
  }

  const openCadastro = () => {
    const {movimentacoesAConciliar} = state

    if(movimentacoesAConciliar.length > 0){
      setState(prevState => ({...prevState,
        modalConfirmaConciliacaoOpen: true
      }))
    }else{
      navigate("/movimentacao_financeira/cadastro");
      return;
    }
  }

  const confirmarModal = (event) => {
    if(event){
      salvarConciliacoes(true)
    }else{
      navigate("/movimentacao_financeira/cadastro");
      return;
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const alterarData = (row) => {
    setState(prevState => ({...prevState,
      openModalAlteraDataMovimentacao: true,
      mov_selecionada: row
    }))
  }

  const handleCloseModalAlteraDataMovimentacao = () => {
    setState(prevState => ({...prevState,
      openModalAlteraDataMovimentacao: false,
      mov_selecionada: {
        _id: '',
        descricao: '',
        valor: '',
        data_hora_sem_form: ''
      }
    }))
  }

  const confirmarAlteraData = async (value) => {
    if(value){
      const {mov_selecionada, movimentacoes} = state

      setState(prevState => ({...prevState,loadingAlteraData: true}))
    
      if(mov_selecionada.data_hora_sem_form === '' || !mov_selecionada.data_hora_sem_form){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data!'
          }
        }))
        setState(prevState => ({...prevState,loadingAlteraData: false}))
        return 
      }

      try {
        const dados = {
          data_conc : mov_selecionada.data_hora_sem_form
        }

        await axios.put(`${window._env_.REACT_APP_API_URL}/movimentoContaFinanceira/alteraData/${mov_selecionada._id}`, dados, getToken())

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Atualização realizada com sucesso!'
          }
        }))

        const indexMov = movimentacoes.list.findIndex(param => param._id === mov_selecionada._id)

        movimentacoes.list[indexMov].data_hora = moment(mov_selecionada.data_hora_sem_form).format('DD/MM/YYYY')
        movimentacoes.list[indexMov].data_hora_sem_form = mov_selecionada.data_hora_sem_form


        setState(prevState => ({...prevState,
          movimentacoes
        }))

        setState(prevState => ({...prevState,loadingAlteraData: false}))
        handleCloseModalAlteraDataMovimentacao()
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,loadingAlteraData: false}))
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }else{
      handleCloseModalAlteraDataMovimentacao()
    }
  }

  const updateFieldDataConc = (event) => {
    const {mov_selecionada} = state

    mov_selecionada.data_hora_sem_form = event.target.value 
    
    setState(prevState => ({...prevState,
      mov_selecionada
    }))
  }

  const updateFiltroDescricao = (event) => {
    setState(prevState => ({...prevState, term: event.target.value }))
    const movimentacoes = state.movimentacoes.list
    const filtros_tela = state.filtros_tela

    const list = movimentacoes.filter(searching(event.target.value, event.target.name))

    filtros_tela[event.target.name] =  event.target.value

    setState(prevState => ({...prevState,
      movimentacoesFiltrado: {
        list
      },
      filtros_tela
    }))
  }

  const handleCloseConfirmacaoConciliarMov = () => {
    setState(prevState => ({...prevState,
      modalConfirmacaoConciliarMovOpen: false
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'conta-financeira')[0]

      if(permissoes){
        if (!permissoes.visualizar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: "Você não tem permissão para acessa essa tela!"
            }
          }))
    
          backPage()
        }

        if(permissoes.inserir || permissoes.alterar){
          setState(prevState => ({...prevState,
            acoesTabela: ['conciliar_mov'],
          }))
        }

        setState(prevState => ({...prevState,
          permissoes
        }))
      }

      let permissoesExtrato = perfil.permissoes.filter(param => param.tela.modulo.slug === 'conta-financeira')[0]
      
      if(permissoesExtrato){
        setState(prevState => ({...prevState,
          permissoesExtrato
        }))
      }

      try {
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))

        const dados = {
          filtro: {
            tipo_conta: "",
            descricao: "",
            ativo: "Sim"
          },
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }

        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/list`, dados, getToken())

        const contaFinanceiras = data.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(param => {
          return {
            id: param.id,
            descricao: param.descricao,
            ordem: param.ordem,
          }
        })

        setState(prevState => ({...prevState,
          contaFinanceiras: {
            list: contaFinanceiras
          }
        }))

        const { data: saldo } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/saldoContas`, dados, getToken())

        setState(prevState => ({...prevState,
          saldo_contas_unidades: {
            list: saldo
          }
        }))

        const filtro_movimentacoes = JSON.parse(localStorage.getItem('filtro_movimentacoes'))

        let filtro = {}

        if (filtro_movimentacoes) {
          filtro = filtro_movimentacoes

          setState(prevState => ({...prevState,
            filtro
          }))
        }else{
          let dataAtual = new Date()
          dataAtual = moment(dataAtual).format('YYYY-MM-DD')
    
          let data7dias = new Date()
          data7dias.setDate(data7dias.getDate() + 7)
          data7dias = moment(data7dias).format('YYYY-MM-DD')
    
          filtro = {
            unidadesnegocio: acesso_atual.map(acesso => {
              return acesso.id
            }),
            data_inicial: dataAtual,
            data_final: data7dias,
            conciliado: '',
            tipo: '',
            descricao: '',
            contfin_id: ''
          }

          setState(prevState => ({...prevState,
            filtro
          }))
        }


        const { data: movimentacoes_resp } = await axios.post(`${window._env_.REACT_APP_API_URL}/movimentoContaFinanceira/list`, filtro, getToken())

        const movimentacoes = movimentacoes_resp.list.map(param => {
          let descricao = param.descricao
          
          if(param.valor_pago){
            if(param.valor_pago.parcela.conta.pessoa){
              descricao = param.descricao + ` Cliente: ${param.valor_pago ? (param.valor_pago.parcela.conta.pessoa.tipo === 'FISICA' ? `${param.valor_pago.parcela.conta.pessoa.fisica.nome} ${param.valor_pago.parcela.conta.pessoa.fisica.sobrenome ? param.valor_pago.parcela.conta.pessoa.fisica.sobrenome : ''}` : param.valor_pago.parcela.conta.pessoa.juridica.razaosocial) : ''}`
            }
          }

          return {
            _id: param.id,
            descricao,
            forma_pgto: param.valor_pago ? param.valor_pago.forma_pagto.descricao : '',
            contfin_descricao: param.conta_financeira.descricao,
            valor: 'R$ ' + real(param.valor),
            tipo: param.tipo,
            conciliado: param.conciliado,
            conciliado_atual: param.conciliado,
            conciliavel: param.conciliavel,
            data_hora: moment(param.data_hora_conta).format('DD/MM/YYYY'),
            data_hora_sem_form: moment(param.data_hora_conta).format('YYYY-MM-DD'),
            contfin_conciliavel: param.conta_financeira.conciliavel,
            valor_pago: param.valor_pago
          }
        })  

        if(movimentacoes_resp.saldoContas[0]){
          setState(prevState => ({...prevState,
            saldoConta: movimentacoes_resp.saldoContas[0],
            saldoContaTotal:movimentacoes_resp.saldoContas[1]
          }))
        }

        setState(prevState => ({...prevState,
          movimentacoes: {
            list: movimentacoes
          },
          movimentacoesFiltrado: {
            list: movimentacoes
          }
        }))
      }catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }

      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { filtro, saldoConta, movimentacoesFiltrado, saldo_contas_unidades, saldoContaTotal, 
    movimentacoesAConciliar, permissoes, permissoesExtrato } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main>
            <Grid container spacing={1} direction="row" className="borderBottom">
              <Grid item md={9} xs={12}>
                <h1 className="titulo">Conciliação Bancária</h1>
              </Grid>
              {permissoes.inserir &&
                <Grid item md={3} xs={12}>
                  <Button size='small' onClick={e => openCadastro()} className="btnCadastrar" variant="contained" color="primary">
                    Cadastrar Movimentação
                  </Button>
                </Grid>
              }
            </Grid>
            {saldo_contas_unidades.list.map(unidade => {
              return (
                <React.Fragment>
                  <Grid container direction="row" spacing={1} className='mg_top_10'>
                    <Grid item md={10} xs={12}>
                      <h6>{unidade.unin_descricao}</h6>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    <Grid item md={1} xs={false}></Grid>
                    <Grid item md={10} xs={12}>
                      <table className="lista sempadding borderTable">
                        <thead>
                          <tr className="titulos acompanha">
                            <th></th>
                            {unidade.saldoContas.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(conta => {
                              return (
                                <th key={conta.id}>{`${conta.descricao}`}</th>
                              )
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Disponível</td>
                            {unidade.saldoContas.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(conta => {
                              return (
                                <td className={conta.saldo_conciliado > 0 ? 'positivo' : 'negativo'} key={conta.id}>{real(conta.saldo_conciliado)}</td>
                              )
                            })}
                          </tr>
                          <tr>
                            <td>A Conciliar</td>
                            {unidade.saldoContas.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(conta => {
                              return (
                                <td className={conta.saldo_nao_conciliado > 0 ? 'positivo' : 'negativo'} key={conta.id}>{real(conta.saldo_nao_conciliado)}</td>
                              )
                            })}
                          </tr>
                          <tr>
                            <td>Total</td>
                            {unidade.saldoContas.sort((a, b) => (a.descricao > b.descricao) ? 1 : ((b.descricao > a.descricao) ? -1 : 0)).map(conta => {
                              return (
                                <td className={conta.saldo_total > 0 ? 'positivo' : 'negativo'} key={conta.contfin_id}>{real(conta.saldo_total)}</td>
                              )
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </Grid>
                    <Grid item md={1} xs={false}></Grid>
                  </Grid>
                  <hr className="mg_top_10"/>
                </React.Fragment>
              )
            })}
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={2} xs={12} sm={3}>
                <TextField className="input" type="date" label="Data Incial" variant="outlined" size="small" name="data_inicial" value={filtro.data_inicial} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={3}>
                <TextField className="input" type="date" label="Data Final" variant="outlined" size="small" name="data_final" value={filtro.data_final} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Conciliado"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="conciliado"
                  value={filtro.conciliado}
                  onChangeCapture={(e) => updateFiltro(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=""></option>
                  <option value={true}>Sim</option>
                  <option value={false}>Não</option>
                </TextField>
              </Grid>
              <Grid item md={5} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Conta"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="contfin_id"
                  value={filtro.contfin_id}
                  onChangeCapture={(e) => updateFiltro(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=""></option>
                  {state.contaFinanceiras.list.map(conta => {
                    return (
                      <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item md={1} xs={12} sm={6}>
                <Button color='warning' size='small' fullWidth variant="contained" onClick={() => filtrar()}>
                  Filtrar
                </Button>
              </Grid>
            </Grid>
            {saldoConta.data !== '' &&
              <Grid container spacing={1} direction="row" className="mg_top_10">
                <Grid item md={3} xs={12}>
                <h6 style={{textAlign:"left"}}>Saldo até o dia:  {saldoConta.data}</h6>
                </Grid>
                <Grid item md={3} xs={12}>
                  <h6 style={{textAlign:"left", color: '#006400'}}>Conciliado: R$ {real(saldoConta.saldo_conciliado)}</h6>
                </Grid>
                <Grid item md={3} xs={12}>
                  <h6 style={{textAlign:"left", color: '#FF0000'}}>Não Conciliado: R$ {real(saldoConta.saldo_nao_conciliado)}</h6>
                </Grid>
                <Grid item md={3} xs={12}>
                  <h6 style={{textAlign:"left"}}>Selecionados: R$ {real(somarValores(movimentacoesAConciliar.map(value => {return value.valor})))}</h6>
                </Grid>
              </Grid>
            }
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12}>
                <Table 
                  urlUpdate="/movimentacao_financeira/cadastro/" 
                  urlView="/movimentacao_financeira/view/" 
                  headCell={state.cabecalhoTabela} 
                  rows={movimentacoesFiltrado.list} 
                  acoes={state.acoesTabela} 
                  conciliarMov={e => conciliarMov(e)}
                  alterarData={e => alterarData(e)}
                  qtdLinhas={7}
                  updateFiltroDescricao={e => updateFiltroDescricao(e)}
                  filtros_tela={state.filtros_tela}
                />
              </Grid>
            </Grid>
            {saldoConta.data !== '' &&
              <Grid container spacing={1} direction="row" className="mg_top_10" >
                <Grid item md={3} xs={12}>
                <h6 style={{textAlign:"left"}}>Saldo fechamento dia:  {moment(filtro.data_final).format('DD/MM/YYYY')}</h6>
                </Grid>
                <Grid item md={3} xs={12}>
                  <h6 style={{textAlign:"left", color: '#006400'}}>Conciliado: R$ {real(saldoContaTotal.saldo_conciliado)}</h6>
                </Grid>
                <Grid item md={3} xs={12}>
                  <h6 style={{textAlign:"left", color: '#FF0000'}}>Não Conciliado: R$ {real(saldoContaTotal.saldo_nao_conciliado)}</h6>
                </Grid>
              </Grid>
            }
            <Grid container direction="row" spacing={1}>
              <Grid item md={8} xs={false} sm={4}> </Grid>
              {!permissoesExtrato.inserir &&
                <Grid item md={2} xs={false} sm={4}> </Grid>
              }
              {!(permissoes.inserir || permissoes.alterar) &&
                <Grid item md={2} xs={false} sm={4}> </Grid>
              }
              {permissoesExtrato.inserir &&
                <Grid item md={2} xs={12} sm={4}>
                  <Link to="/movimentacao_financeira/importar_extrato">
                    <Button color='secondary' size='small' fullWidth variant="contained">
                      Importar Extrato
                    </Button>
                  </Link>
                </Grid>
              }
              {(permissoes.inserir || permissoes.alterar) &&
                <Grid item md={2} xs={12} sm={4}>
                  <Button color='success' size='small' fullWidth variant="contained" onClick={() => salvarConciliacoes(false)}>
                    Salvar Conciliações
                  </Button>
                </Grid>
              }
            </Grid>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
          
          <ModalConfirmarConciliacoes 
            open={state.modalConfirmaConciliacaoOpen} 
            handleClose={e => handleClose(e)} 
            confirmar={e => confirmarModal(e)} 
          />

          <ModalAlteraDataMovimentacao 
            open={state.openModalAlteraDataMovimentacao}
            handleClose={e => handleCloseModalAlteraDataMovimentacao(e)} 
            mov_selecionada={state.mov_selecionada}
            confirmar={e => confirmarAlteraData(e)} 
            updateField={e => updateFieldDataConc(e)}
            disabledButtons={state.loadingAlteraData}
          />

          <ModalConfirmacaoConciliarMov
            open={state.modalConfirmacaoConciliarMovOpen}
            handleClose={e => handleCloseConfirmacaoConciliarMov(e)} 
            parcelas_filhos={state.parcelas_filhos} 
            confirmar={e => continuarConciliacao(e, state.event)} 
            tela='movimentacao'
          />

        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )
}
export default Movimentacao
import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, FormControlLabel, Button, Switch, FormLabel, FormControl } from '@mui/material'
import CurrencyInput from './../mask/CurrencyInput'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import real  from '../../services/real'
import Table from './../Table'
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment'
import CPForCNPJInput from './../../components/mask/CPForCNPJInput';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';

const cabecalhoTabela = [
  { id: 'descricao', numeric: false, disablePadding: true, label: 'Serviço/Produto' },
  { id: 'valor_contrato', numeric: true, disablePadding: true, label: 'Valor de Contrato' },
  { id: 'parcelas', numeric: false, disablePadding: true, label: 'Parcelas' },
  { id: 'venc_prim_parcela', numeric: false, disablePadding: true, label: 'Venc. Prim. Parcela'},
  { id: 'forma_pagto', numeric: false, disablePadding: true, label: 'Forma de Pagamento' },
  { id: 'conta_fin', numeric: false, disablePadding: true, label: 'Contas Bancárias' },
  { id: 'gerar_nfse', numeric: false, disablePadding: true, label: 'Gerar NFS-e' },
]

const acoesTabela = ['remove', 'editar']

const dataHoje = new Date()
const dataHojeFormatado = moment(dataHoje).format('YYYY-MM-DD')

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '96%',
  marginLeft: '2%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const inputTexts = [
  {
    name: "razaoSocial",
    label: "Razão Social*",
    type: "text"
  },
  {
    name: "nomeFantasia",
    label: "Nome Fantasia*",
    type: "text"
  },
  {
    name: "cnpj",
    label: "CNPJ",
    type: "text"
  },
  {
    name: "inscricaoEstadual",
    label: "Inscrição Estadual",
    type: "text"
  },
  {
    name: "dataFundacao",
    label: "Data Fundação",
    type: "date"
  },
]

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function SimpleModal(props) {
  const theme = useTheme();
  const {dados, servico, produtos, formasPagamento, contasFinanceira, produtoSelecionado, alteraServico, disabledButton, tab, handleChange,
    buscarResp, enableBuscaResp, responsavel, updateFieldPessoaFisicaResponsavel, validarCpfCnpjResponsavel, updateFieldPessoaJuridicaResponsavel, 
    updateFieldEnderecoResponsavel, pegaEndereco, updateFieldContatoResponsavel, formataTelefone, updateFieldEmailResponsavel,
    searchResp,updateFieldSearchResp, template_documentos, updateFieldAssinar, updateFieldCobrarMulta, updateFieldAgruparVencidas,
    updateFieldBonusPontualidade, exibir_opcao_bonus_indicacao_contrato, updateFieldBonusIndicacao, clientes, clienteIndicacao } = props

  const {updateFieldConta, mudarParcelas, recalcularParcela, updateFieldParcela, travarCampos, exibir_opcao_multa_contrato,
    marcaParcelas, marcaParcelasContaFin, logado, conta, verificaDataParcela, possui_emissao_nfse, updateFieldGerarNfse, exibir_opcao_pontualidade_contrato
  } = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme}>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Novo Contrato</h5>
            </Grid>
          </Grid>
          <br />

          <AppBar position="static">
            <Tabs id="viewCliente" variant="scrollable" scrollButtons="auto" value={tab} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Dados do Contrato" {...a11yProps(0)} />
              <Tab label="Dados do Responsável" {...a11yProps(1)} />
            </Tabs>
          </AppBar>

          <TabPanel value={tab} index={0}>
            <Grid container direction="row" spacing={1} className="mg_top_20">
              <Grid item md={3} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Codigo" 
                  variant="outlined" 
                  size="small"
                  name="codigo" 
                  value={dados.codigo} 
                  onChange={(e) => props.updateField(e)} 
                  InputLabelProps={{ shrink: true }} 
                />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField type="date"
                  className="input"
                  label="Data Inicio Vigência*"
                  variant="outlined"
                  name="data_ini_vig"
                  value={dados.data_ini_vig}
                  onChange={(e) => props.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
              </Grid>
              <Grid item md={3} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Unidade Tempo Duração"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="unidade_tempo"
                  value={dados.unidade_tempo}
                  disabled={dados.data_ini_vig === ''}
                  onChangeCapture={(e) => props.updateField(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value=''> Selecionar Unidade Tempo </option>
                  <option value='DIA'> Dia </option>
                  <option value='MÊS'> Mês </option>
                  {/* <option value='SEMESTRE'> Semestre </option> */}
                  <option value='ANO'> Ano </option>
                  
                </TextField>
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField 
                  className="input" 
                  label="Tempo Contrato" 
                  type="number"
                  variant="outlined" 
                  size="small" 
                  name="tempo_contrato" 
                  value={dados.tempo_contrato} 
                  disabled={dados.unidade_tempo === ''}
                  onChange={(e) => props.updateField(e)} 
                  InputLabelProps={{ shrink: true }} 
                />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField type="date"
                  className="input"
                  label="Data Fim Vigência"
                  variant="outlined"
                  name="data_fim_vig"
                  value={dados.data_fim_vig}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              {exibir_opcao_multa_contrato &&
                <React.Fragment>
                  <Grid item md={2} xs={12} sm={2}>
                    <FormControl component="fieldset" style={{marginTop: -10}}>
                      <FormLabel component="legend">Agrupar Contas Vencidas?</FormLabel>
                      <FormControlLabel className="mg_left_10"
                        control={
                          <Switch
                            name="agrupar_vencidas"
                            color="primary"
                            checked={dados.agrupar_vencidas}
                          />
                        }
                        size="small"
                        label={dados.agrupar_vencidas ? 'Sim' : 'Não'}
                        name="agrupar_vencidas"
                        onChange={(e) => updateFieldAgruparVencidas(e)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={1} xs={12} sm={2}>
                    <FormControl component="fieldset" style={{marginTop: -10}}>
                      <FormLabel component="legend">Cobrar Multa?</FormLabel>
                      <FormControlLabel className="mg_left_10"
                        control={
                          <Switch
                            name="Ativo"
                            color="primary"
                            checked={dados.cobrar_multa}
                          />
                        }
                        size="small"
                        label={dados.cobrar_multa ? 'Sim' : 'Não'}
                        name="cobrar_multa"
                        onChange={(e) => updateFieldCobrarMulta(e)}
                      />
                    </FormControl>
                  </Grid>
                  {dados.cobrar_multa &&
                    <React.Fragment>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          label="Tipo da Multa"
                          variant="outlined"
                          className="input"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name="tipo_multa"
                          value={dados.tipo_multa}
                          onChangeCapture={(e) => props.updateField(e)}
                          InputLabelProps={{ shrink: true }}
                        >
                          <option value=''> Selecionar Tipo </option>
                          <option value='FIXO'> Valor Fixo (R$) </option>
                          <option value='PORCENTAGEM'> Valor em Porcentagem (%)</option>
                          
                        </TextField>
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField 
                          className="input" 
                          label="Valor da Multa"
                          variant="outlined" 
                          size="small" 
                          name="valor_multa" 
                          value={dados.valor_multa} 
                          disabled={dados.tipo_multa === ''}
                          onChange={(e) => props.updateField(e)} 
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputComponent: CurrencyInput }}
                        />
                      </Grid>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
              {exibir_opcao_pontualidade_contrato &&
                <React.Fragment>
                  <Grid item md={2} xs={12} sm={2}>
                    <FormControl component="fieldset" style={{marginTop: -10}}>
                      <FormLabel component="legend">Bônus por Pontualidade?</FormLabel>
                      <FormControlLabel className="mg_left_10"
                        control={
                          <Switch
                            name="bonus_pont"
                            color="primary"
                            checked={dados.bonus_pont}
                          />
                        }
                        size="small"
                        label={dados.bonus_pont ? 'Sim' : 'Não'}
                        name="bonus_pont"
                        onChange={(e) => updateFieldBonusPontualidade(e)}
                      />
                    </FormControl>
                  </Grid>
                  {dados.bonus_pont &&
                    <Grid item md={2} xs={12} sm={6}>
                      <TextField 
                        className="input" 
                        label="Porc. do Bônus (%)"
                        variant="outlined" 
                        size="small" 
                        name="porc_bonus_pont" 
                        value={dados.porc_bonus_pont} 
                        onChange={(e) => props.updateField(e)} 
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputComponent: CurrencyInput }}
                      />
                    </Grid>
                  }
                </React.Fragment>
              }
              {template_documentos.length > 0 &&
                <React.Fragment>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Selecionar Documento"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{ native: true }}
                      name="template_versao_id"
                      value={dados.template_versao_id}
                      onChangeCapture={(e) => props.updateField(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value=""> Não Emitir Documento</option>
                      {template_documentos.map(documento => {
                        return (
                          <option key={documento.versao_id} value={documento.versao_id}> {documento.titulo} - Versão: {documento.versao} </option>
                        )
                      })}
                    </TextField>
                  </Grid>
                  {dados.template_versao_id !== "" &&
                    <Grid item md={2} xs={12} sm={1}>
                      <FormControl component="fieldset" style={{marginLeft: 20}}>
                        <FormLabel component="legend">Assinar?</FormLabel>
                        <FormControlLabel
                          control={
                            <Switch
                              name="Ativo"
                              color="primary"
                              checked={dados.assinar_contrato}
                            />
                          }
                          size="small"
                          label={dados.assinar_contrato ? 'Sim' : 'Não'}
                          name="assinar_contrato"
                          onChange={(e) => updateFieldAssinar(e)}
                        />
                      </FormControl>
                    </Grid>
                  }
                </React.Fragment>
              }
            </Grid>
            {exibir_opcao_bonus_indicacao_contrato &&
              <React.Fragment>
                <hr className="mg_top_10"/>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={2} xs={12} sm={2}>
                    <FormControl component="fieldset" style={{marginTop: -10}}>
                      <FormLabel component="legend">Bônus de Indicação?</FormLabel>
                      <FormControlLabel className="mg_left_10"
                        control={
                          <Switch
                            name="Ativo"
                            color="primary"
                            checked={dados.bonus_indicacao}
                          />
                        }
                        size="small"
                        label={dados.bonus_indicacao ? 'Sim' : 'Não'}
                        name="bonus_indicacao"
                        onChange={(e) => updateFieldBonusIndicacao(e)}
                      />
                    </FormControl>
                  </Grid>
                  {dados.bonus_indicacao &&
                    <React.Fragment>
                      <Grid item md={4} xs={12} sm={2}>
                        <Autocomplete
                          onChange={(event, value) => props.updateFieldCliente(event, value)}
                          freeSolo
                          options={clientes}
                          value={clienteIndicacao}
                          id='cliente'
                          getOptionLabel={option => `${option.nome}`}
                          renderInput={(params) => (
                            <TextField 
                              InputProps={{ ...params.InputProps, type: 'search' }} 
                              {...params} 
                              size="small" 
                              label="Cliente Indicação*" 
                              margin="normal"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }} 
                              value={`${clienteIndicacao.nome}`}
                              onChange={(e) => props.updateFiltroPessoa(e)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField 
                          className="input" 
                          label="Valor do Bônus"
                          variant="outlined" 
                          size="small" 
                          name="valor_indicacao" 
                          value={dados.valor_indicacao} 
                          disabled={dados.cliente_indicacao === ''}
                          onChange={(e) => props.updateField(e)} 
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ inputComponent: CurrencyInput }}
                        />
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="input"
                          label="Forma Pagto. Bônus"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name="formapagto_indicacao_id"
                          value={dados.formapagto_indicacao_id}
                          onChangeCapture={(e) => props.updateField(e)}
                          InputLabelProps={{ shrink: true }}
                          disabled={dados.cliente_indicacao === ''}
                        >
                          <option value="">--Escolha--</option>
                          {formasPagamento.list.map(forma => {
                            return (
                              <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                            )
                          })}
                        </TextField>
                      </Grid>
                      <Grid item md={2} xs={12} sm={6}>
                        <TextField
                          id="standard-select-currency"
                          select
                          variant="outlined"
                          className="input"
                          label="Conta Fin. Bônus"
                          fullWidth
                          size="small"
                          SelectProps={{
                            native: true,
                          }}
                          name="contfin_indicacao_id"
                          value={dados.contfin_indicacao_id}
                          onChangeCapture={(e) => props.updateField(e)}
                          InputLabelProps={{ shrink: true }}
                          disabled={travarCampos}
                        >
                          <option value="">--Escolha--</option>
                          {contasFinanceira.list.filter(param => param.tipo_conta === dados.habilita || (param.tipo_conta === "CAIXATURNO" && logado === 'caixa') ).map(conta => {
                            return (
                              <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                            )
                          })}
                        </TextField>
                      </Grid>
                    </React.Fragment>
                  }
                </Grid>
              </React.Fragment>
            }
            <hr className="mg_top_10"/>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={2} xs={2} sm={2}>
                <Button
                  fullWidth 
                  color={servico.parcelado ? 'primary' : 'inherit'} 
                  variant="contained" 
                  size="small" 
                  endIcon={servico.parcelado ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />} 
                  onClick={e => props.updateParcelado()}
                >
                  Parcelamento
                </Button>
              </Grid>
              <Grid item md={2} xs={2} sm={2}>
                <Button 
                  fullWidth 
                  color={servico.recorrencia ? 'secondary' : 'inherit'} 
                  variant="contained" 
                  size="small" 
                  endIcon={servico.recorrencia ?  <CheckBoxIcon/> :<CheckBoxOutlineBlankIcon />}
                  onClick={e => props.updateRecorrencia()}
                >
                  Recorrência
                </Button>
              </Grid>
              <Grid item md={1} xs={1} sm={1}></Grid>
            </Grid>
            {servico.recorrencia && 
              <Grid container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={4} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Serviço"
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{ native: true }}
                    name="produto_id"
                    value={servico.produto_id}
                    onChangeCapture={(e) => props.updateServico(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value=""> Selecionar Produto</option>
                    {produtos.list.map(produto => {
                      return (
                        <option key={produto.id} value={produto.id}> {produto.descricao} </option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={1} xs={12} sm={6}>
                  <TextField className="input" label="Valor*" variant="outlined" size="small" name="valor" value={servico.valor} onChange={(e) => props.updateServico(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    type='number'
                    className="input" 
                    label="Repetir a cada*" 
                    variant="outlined" 
                    name="recorrenciaACada" 
                    size="small"
                    value={servico.recorrenciaACada} 
                    onChange={(e) => props.updateServico(e)} 
                    InputLabelProps={{ shrink: true }}/>
                </Grid>
                <Grid item md={1} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label=""
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="tipoRecorrencia"
                    value={servico.tipoRecorrencia}
                    onChangeCapture={(e) => props.updateServico(e)}
                  >
                    <option value='d'> Dia </option>
                    <option value='w'> Semana </option>
                    <option value='M'> Mês </option>
                    <option value='y'> Ano </option>
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    type='number'
                    className="input" 
                    label="Num. de Ocorrências*" 
                    variant="outlined" 
                    name="qtdRecorrencia" 
                    size="small"
                    value={servico.qtdRecorrencia} 
                    onChange={(e) => props.updateServico(e)} 
                    InputLabelProps={{ shrink: true }}/>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField className="input" type='date' label="Venc. Prim. Recor.*" variant="outlined" size="small" name="venc_prim_parcela" value={servico.venc_prim_parcela} onChange={(e) => props.updateServico(e)} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{ native: true }}
                    name="formapagto_id"
                    value={servico.formapagto_id}
                    label="Forma de Pgto.*"
                    onChangeCapture={(e) => props.updateServico(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">Selecionar Forma de Pgto.</option>
                    {formasPagamento.list.map(forma => {
                      return (
                        <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="contfin_id"
                    value={servico.contfin_id}
                    label="Contas Bancárias.*"
                    onChangeCapture={(e) => props.updateServico(e)}
                    InputLabelProps={{ shrink: true }}
                  >
                    <option value="">--Escolha--</option>
                    {contasFinanceira.list.map(conta => {
                      return (
                        <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={6}>
                  <TextField 
                    className="input" 
                    label="Totalizando*" 
                    variant="outlined" 
                    name="qtdRecorrencia" 
                    size="small"
                    value={servico.valor ? real(parseFloat(servico.valor.replaceAll(".", "").replace(",", ".")) * servico.qtdRecorrencia) : real(0)} 
                    InputLabelProps={{ shrink: true }}
                    disabled/>
                </Grid>
                {possui_emissao_nfse &&
                  <Grid item md={1} xs={12} sm={1}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Gerar NFSE?</FormLabel>
                      <FormControlLabel className="mg_left_10"
                        control={
                          <Switch
                            name="Ativo"
                            color="primary"
                            checked={servico.gerar_nfse}
                          />
                        }
                        size="small"
                        label={servico.gerar_nfse ? 'Sim' : 'Não'}
                        name="gerar_nfse"
                        onChange={(e) => updateFieldGerarNfse(e)}
                      />
                    </FormControl>
                  </Grid>
                }
              </Grid>
            }
            {servico.parcelado && 
              <React.Fragment>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={4} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Serviço/Produto"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{ native: true }}
                      name="produto_id"
                      value={servico.produto_id}
                      onChangeCapture={(e) => props.updateServico(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value=""> Selecionar Produto</option>
                      {produtos.list.map(produto => {
                        return (
                          <option key={produto.id} value={produto.id}> {produto.descricao} </option>
                        )
                      })}
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField 
                      className="input" 
                      label="Valor Total*" 
                      variant="outlined" 
                      size="small" 
                      name="valor" 
                      value={servico.valor} 
                      onChange={(e) => props.updateServico(e)} 
                      InputLabelProps={{ shrink: true }} 
                      InputProps={{ inputComponent: CurrencyInput }}
                      disabled={servico.produto_id === ''}
                    />
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Unidade Tempo Duração"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="intervalo_parcela"
                      value={servico.intervalo_parcela}
                      onChangeCapture={(e) => props.updateServico(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value=''> Selecionar Intervalo </option>
                      <option value='DIA'> Dia </option>
                      <option value='MÊS'> Mês </option>
                      <option value='ANO'> Ano </option>
                      
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Cobrar aos Sábados?"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="cobrar_sabados"
                      value={servico.cobrar_sabados}
                      onChangeCapture={(e) => props.updateServico(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value='SIM'> Sim </option>
                      <option value='NAO'> Não </option>
                    </TextField>
                  </Grid>
                  <Grid item md={2} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Cobrar aos Domingos?"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="cobrar_domingos"
                      value={servico.cobrar_domingos}
                      onChangeCapture={(e) => props.updateServico(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value='SIM'> Sim </option>
                      <option value='NAO'> Não </option>
                    </TextField>
                  </Grid>
                  {possui_emissao_nfse &&
                    <Grid item md={1} xs={12} sm={1}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Gerar NFSE?</FormLabel>
                        <FormControlLabel className="mg_left_10"
                          control={
                            <Switch
                              name="Ativo"
                              color="primary"
                              checked={servico.gerar_nfse}
                            />
                          }
                          size="small"
                          label={servico.gerar_nfse ? 'Sim' : 'Não'}
                          name="gerar_nfse"
                          onChange={(e) => updateFieldGerarNfse(e)}
                        />
                      </FormControl>
                    </Grid>
                  }
                </Grid>
                {servico.valor !== '' &&
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={12} xs={12} sm={12}>
                      <div className='scroll'>
                        <table className="lista sempadding borderTable">
                          <thead>
                            <tr className="titulos acompanha">
                              <th>Parcelas</th>
                              <th>Valor*</th>
                              <th>Vencimento*</th>
                              <th>Forma de Pagamento*</th>
                              <th>Contas Bancárias</th>
                              <th>Documento</th>
                            </tr>
                          </thead>
                          <tbody>
                            {conta.parcelas.sort((a, b) => (a.index > b.index) ? 1 : (b.index > a.index) ? -1 : 0).map(parcela => {
                              return (
                                <tr key={parcela.index}>
                                  <td>
                                    <TextField className="inputParcela" variant="outlined" size="small" name="index" value={parcela.index} InputLabelProps={{ shrink: true }} disabled />
                                    <span className="separacaoParcelas">/</span>
                                    <TextField className="inputParcela" variant="outlined" size="small" name="qtd_parcelas" value={conta.qtd_parcelas} onChange={(e) => updateFieldConta(e)} onBlur={(e) => mudarParcelas(e)} InputLabelProps={{ shrink: true }}/>
                                  </td>
                                  <td>
                                    <TextField 
                                      className="inputValor" 
                                      variant="outlined" 
                                      size="small" 
                                      name="valorparcela" 
                                      value={parcela.valorparcela} 
                                      onBlur={(e) => recalcularParcela(e, parcela.index)} 
                                      InputLabelProps={{ shrink: true }} 
                                      InputProps={{ inputComponent: CurrencyInput }} 
                                      disabled={travarCampos}
                                    />
                                  </td>
                                  <td>
                                    <TextField 
                                      type="date" 
                                      className="inputVencimento" 
                                      variant="outlined" 
                                      size="small" 
                                      name="datavencimento" 
                                      value={parcela.datavencimento} 
                                      onChange={(e) => updateFieldParcela(e, parcela.index)} 
                                      onBlur={(e) => verificaDataParcela(e, parcela.index)} 
                                      InputLabelProps={{ shrink: true }}
                                      disabled={travarCampos}
                                    />
                                  </td>
                                  <td>
                                    <TextField
                                      id="standard-select-currency"
                                      select
                                      variant="outlined"
                                      className="inputFormPagamento"
                                      fullWidth
                                      size="small"
                                      SelectProps={{
                                        native: true,
                                      }}
                                      name="formapagto_id"
                                      value={parcela.formapagto_id}
                                      onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                                      onBlur={(e) => marcaParcelas(e, parcela.index)}
                                      InputLabelProps={{ shrink: true }}
                                    >
                                      <option value="">--Escolha--</option>
                                      {formasPagamento.list.map(forma => {
                                        return (
                                          <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                                        )
                                      })}
                                    </TextField>
                                  </td>
                                  <td>
                                    <TextField
                                      id="standard-select-currency"
                                      select
                                      variant="outlined"
                                      className="inputFormPagamento"
                                      fullWidth
                                      size="small"
                                      SelectProps={{
                                        native: true,
                                      }}
                                      name="contfin_id"
                                      value={parcela.contfin_id}
                                      onChangeCapture={(e) => updateFieldParcela(e, parcela.index)}
                                      onBlur={(e) => marcaParcelasContaFin(e, parcela.index)}
                                      InputLabelProps={{ shrink: true }}
                                      disabled={travarCampos}
                                    >
                                      <option value="">--Escolha--</option>
                                      {contasFinanceira.list.filter(param => param.tipo_conta === parcela.habilita || (param.tipo_conta === "CAIXATURNO" && logado === 'caixa') ).map(conta => {
                                        return (
                                          <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                        )
                                      })}
                                    </TextField>
                                  </td>
                                  <td>
                                    <TextField className="inputDocumento" variant="outlined" size="small" name="documento" value={parcela.documento} onChange={(e) => updateFieldParcela(e, parcela.index)} InputLabelProps={{ shrink: true }} />
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Grid>
                  </Grid>
                }
              </React.Fragment>
            }
            <Grid container direction="row" spacing={1} className="mg_top_10">
              {produtoSelecionado.preco &&
                <React.Fragment>
                  <Grid item md={2} xs={12} sm={3}>
                    <p style={{marginBottom: '0px'}}>Valores Sugeridos da Tabela: </p>
                  </Grid>
                  <Grid item md={2} xs={12} sm={2}>
                    <TextField className="input" label="Valor Mínimo*" variant="outlined" size="small" name="valor_minimo" value={` R$ ${real(produtoSelecionado.preco.valor_minimo)}`} InputLabelProps={{ shrink: true }} disabled/>
                  </Grid>
                  <Grid item md={2} xs={12} sm={2}>
                    <TextField className="input" label="Valor Máximo*" variant="outlined" size="small" name="valor_maximo" value={` R$ ${real(produtoSelecionado.preco.valor_maximo)}`} InputLabelProps={{ shrink: true }} disabled/>
                  </Grid>
                </React.Fragment>
              } 
              {!produtoSelecionado.preco &&
                <Grid item md={6} xs={12} sm={7}></Grid>
              }
              <Grid item md={4} xs={12} sm={1}></Grid>
              <Grid item md={2} xs={12} sm={3}>
                <Button color='warning' fullWidth variant="contained" className="btn_salvar" size="small" startIcon={<AddIcon />} onClick={e => props.addServico(e)}>{alteraServico ? 'Alterar' : 'Adicionar'}</Button>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1} className="mg_top_10">
              <Grid item md={12} xs={12} sm={12}>
                <Table
                remove={e => props.removerServico(e)}
                editar={e => props.editarServico(e)}
                headCell={cabecalhoTabela}
                rows={dados.produtos.map(value => {
                  return {
                    produto_id: value.produto_id,
                    descricao: value.descricao,
                    formapagto_id: value.formapagto_id,
                    forma_pagto: value.forma_pagto,
                    valor_contrato: 'R$ '  + (value.recorrencia ? real(parseFloat(value.valor.replaceAll(".", "").replace(",", ".")) * parseInt(value.qtdRecorrencia)) : value.valor),
                    parcelas: value.descricao_parcelamento,
                    venc_prim_parcela: moment(value.venc_prim_parcela).format('DD/MM/YYYY'),
                    conta_fin: value.conta_fin,
                    contfin_id: value.contfin_id,
                    parcelado: value.parcelado,
                    recorrencia: value.recorrencia,
                    gerar_nfse: value.gerar_nfse,
                    index: value.index
                  }
                })}
                acoes={acoesTabela} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Grid container direction="row" spacing={1} className="mg_top_20" >
              <Grid item md={3} xs={12} sm={4}>
                <CPForCNPJInput
                  label="CPF/CNPJ do Responsável"
                  className="inputCpfCnpj"
                  name="cpf"
                  value={searchResp.cpf}
                  onChange={(e) => updateFieldSearchResp(e)}
                  style={{marginTop: '-10px'}}
                />
              </Grid>
              <Grid item md={2} xs={12} sm={3}>
                <Button fullWidth onClick={() => buscarResp()} color="primary" variant="contained">Buscar</Button>
              </Grid>
            </Grid>
            {!enableBuscaResp &&
              <React.Fragment>
                <hr className="mg_top_10"/>
                {responsavel.tipoPessoa === 'FISICA' &&
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField className="input" label="Nome*" variant="outlined" size="small" name="nome" value={responsavel.pessoaFisica.nome} onChange={(e) => updateFieldPessoaFisicaResponsavel(e)} InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField className="input" label="Sobrenome" variant="outlined" size="small" name="sobrenome" value={responsavel.pessoaFisica.sobrenome} onChange={(e) => updateFieldPessoaFisicaResponsavel(e)} InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField
                        fullWidth
                        className="input"
                        type='date'
                        name='dataNascimento'
                        label="Data Nascimento"
                        value={responsavel.pessoaFisica.dataNascimento}
                        variant="outlined"
                        size="small"
                        onChange={(e) => updateFieldPessoaFisicaResponsavel(e)}
                        InputProps={{ inputProps: { max: dataHojeFormatado } }}
                        InputLabelProps={{
                          shrink: true,
                        }} />
                    </Grid>
                    <Grid item md={3} xs={12} sm={6}>
                      <TextField inputProps={{ maxLength: 11 }} className="input" label="CPF*" variant="outlined" size="small" name="cpf" value={responsavel.pessoaFisica.cpf} onChange={(e) => updateFieldPessoaFisicaResponsavel(e)} InputLabelProps={{ shrink: true }} onBlur={e => validarCpfCnpjResponsavel(e)} disabled />
                    </Grid>
                  </Grid>
                }
                {responsavel.tipoPessoa === 'JURIDICA' &&
                  <Grid container direction="row" spacing={1} className="mg_top_10">
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      className="mg_top_20"
                    >
                      {inputTexts.map(input => {
                        if (input.name === "cnpj") {
                          return (
                            <Grid item md={3} xs={12} sm={6} key={input.name}>
                              <TextField
                                fullWidth
                                inputProps={{ maxLength: 14 }}
                                className="input"
                                type={input.type}
                                name={input.name}
                                label={input.label}
                                value={responsavel.pessoaJuridica[input.name]}
                                variant="outlined"
                                size="small"
                                onChange={(e) => updateFieldPessoaJuridicaResponsavel(e)}
                                onBlur={e => validarCpfCnpjResponsavel(e)}
                                InputLabelProps={{
                                  shrink: true
                                }} 
                                disabled
                              />
                            </Grid>
                          )
                        } else {
                          return (
                            <Grid item md={3} xs={12} sm={6} key={input.name}>
                              <TextField
                                fullWidth
                                className="input"
                                type={input.type}
                                name={input.name}
                                label={input.label}
                                value={responsavel.pessoaJuridica[input.name]}
                                variant="outlined"
                                size="small"
                                onChange={(e) => updateFieldPessoaJuridicaResponsavel(e)}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </Grid>
                          )
                        }
                      })}
                    </Grid>
                  </Grid>
                }
                <hr className="mg_top_10"/>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h6 className='titulo'>
                      Endereço:
                    </h6>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_10">
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      onBlur={e => pegaEndereco(e)}
                      fullWidth
                      className="input"
                      label="CEP*"
                      variant="outlined"
                      size="small"
                      name="cep"
                      value={responsavel.endereco.cep}
                      inputProps={{ maxLength: 8 }}
                      onChange={(e) => updateFieldEnderecoResponsavel(e)}
                      InputLabelProps={{
                        shrink: true
                      }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField fullWidth className="input" label="Rua*" variant="outlined" size="small" name="rua" value={responsavel.endereco.rua} onChange={(e) => updateFieldEnderecoResponsavel(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField fullWidth className="input" label="Número*" variant="outlined" size="small" name="numero" value={responsavel.endereco.numero} onChange={(e) => updateFieldEnderecoResponsavel(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField fullWidth className="input" label="Complemento" variant="outlined" size="small" name="complemento" value={responsavel.endereco.complemento} onChange={(e) => updateFieldEnderecoResponsavel(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row" className="mg_top_20">
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField fullWidth className="input" label="Bairro*" variant="outlined" size="small" name="bairro" value={responsavel.endereco.bairro} onChange={(e) => updateFieldEnderecoResponsavel(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField disabled={true} fullWidth className="input" label="Cidade*" variant="outlined" size="small" name="cidade" value={responsavel.endereco.cidade} onChange={(e) => updateFieldEnderecoResponsavel(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField disabled={true} fullWidth className="input" label="Estado*" variant="outlined" size="small" name="estado" value={responsavel.endereco.estado} onChange={(e) => updateFieldEnderecoResponsavel(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Tipo Endereço*"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="tipoEndereco"
                      value={responsavel.endereco.tipoEndereco}
                      onChangeCapture={(e) => updateFieldEnderecoResponsavel(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value="RESIDENCIAL">
                        RESIDENCIAL
                      </option>
                      <option value="COMERCIAL">
                        COMERCIAL
                      </option>
                    </TextField>
                  </Grid>
                </Grid>
                <hr className="mg_top_10"/>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h6 className='titulo'>
                      Contato:
                    </h6>
                  </Grid>
                </Grid>
                <Grid  container spacing={1} direction="row">
                  <Grid item md={4} xs={12} sm={6}>
                    <TextField
                      fullWidth
                      className="input"
                      label="Número*"
                      variant="outlined"
                      size="small"
                      name="numero"
                      value={responsavel.contato.numero}
                      onChange={(e) => updateFieldContatoResponsavel(e)}
                      onBlur={(e) => formataTelefone(e)}
                      InputLabelProps={{ shrink: true}}
                      />
                  </Grid>
                  <Grid item md={3} xs={12} sm={6}>
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Tipo Contato"
                      variant="outlined"
                      className="input"
                      fullWidth
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="tipoContato"
                      value={responsavel.contato.tipoContato}
                      onChangeCapture={(e) => updateFieldContatoResponsavel(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value="COMERCIAL">
                        COMERCIAL
                      </option>
                      <option value="RESIDENCIAL">
                        RESIDENCIAL
                      </option>
                    </TextField>
                  </Grid>
                </Grid>
                <hr className="mg_top_10"/>
                <Grid container direction="row" spacing={1} className="mg_top_10">
                  <Grid item md={12} xs={12} sm={12}>
                    <h6 className='titulo'>
                      Email:
                    </h6>
                  </Grid>
                </Grid>
                <Grid container spacing={1} direction="row">
                  <Grid item md={7} xs={12} sm={6}>
                    <TextField fullWidth className="input" label="E-mail" variant="outlined" size="small" name="email" value={responsavel.email.email} onChange={(e) => updateFieldEmailResponsavel(e)} InputLabelProps={{ shrink: true }} />
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          </TabPanel>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1} className="mg_top_10">
            <Grid item md={6} xs={6} sm={4}></Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="secondary" variant="contained" className="btn_salvar" size="small" startIcon={<CloseIcon />} onClick={e => props.confirmar(false)} disabled={disabledButton}>Voltar</Button>
            </Grid>
            <Grid item md={3} xs={6} sm={4}>
              <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<CheckIcon />} onClick={e => props.confirmar(true)} disabled={disabledButton}>Confirmar</Button>
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import './ContaFinanceira.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button, TextField } from '@mui/material'
import axios from 'axios'
import real from '../../services/real'
import Alert from './../../components/templates/Alert'
import Loading from './../../components/templates/Loading'
import ModalAjuda from './../../components/modals/Ajuda'
import Joyride from './../../components/templates/Joyride'

const initialState = {
  contaFinanceira: {

  },
  contaFinanceiras: {
    list: []
  },
  contaFinanceirasFiltrado: {
    list: []
  },
  filtro: {
    tipo_conta: "",
    descricao: "",
    ativo: "Sim"
  },
  cabecalhoTabela: [
    { id: 'url_banco', numeric: false, disablePadding: true, label: 'Logo' },
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'unin_descricao', numeric: false, disablePadding: false, label: 'Unidade' },
    { id: 'saldo_atual', numeric: false, disablePadding: false, label: 'Saldo Atual' },
    { id: 'saldo_nao_conciliado', numeric: false, disablePadding: false, label: 'Saldo Não Conciliado' },
    { id: 'limite', numeric: false, disablePadding: false, label: 'Limite de Crédito' },
    { id: 'tipo_conta', numeric: false, disablePadding: false, label: 'Tipo Conta' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  permissoesCorrigeSaldo: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  loading: true,
  mensagemLoading: 'Carregando Contas Bancárias...',
  openModalAjuda: false,
  markdown: ''
}

const ContaFinanceiras = (props) => {
  const [state, setState] = useState({ ...initialState });
  const [run, setRun] = useState(false);

  const gridRefFiltro = useRef(null);
  const gridRefTable = useRef(null);

  const steps = [
    {
      target: "#button-cadastrar",
      content: "Neste botão, você terá acesso à tela de cadastro do itens de receita e despesa.",
      disableBeacon: true
    },
    {
      target: gridRefFiltro.current,
      content: "Aqui você encontrará todos os filtros para a seleção dos itens.",
    },
    {
      target: "#button-filtrar",
      content: "Após selecionar os filtros, clique neste botão para confirmá-los.",
    },
    {
      target: gridRefTable.current,
      content: "Esta é a tabela onde todas os itens de receita e despesa são listadas com base nos critérios informados nos filtros.",
    },
    {
      target: "#coluna-acoes",
      content: `No final da tabela, há uma coluna com opções para editar ou remover o item.`,
      placement: 'left'
    },
    {
      target: "#paginacao-tabela",
      content: `Na parte inferior da tabela, você encontrará os botões de paginação, onde pode selecionar o número de linhas por página e navegar entre as páginas.`,
    },
  ]

  const getToken = () => {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  const updateFiltro = (event) => {
    const filtro = state.filtro

    filtro[event.target.name] = event.target.value

    setState(prevState => ({...prevState,
      filtro
    }))
  }

  const filtrarContas = async () => {
    const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
    const filtro = state.filtro

    const dados = {
      filtro,
      unidadesnegocio: acesso_atual.map(acesso => {
        return acesso.id
      })
    }

    try {
      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/list`, dados, getToken())

      const contaFinanceiras = data.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(param => {
        return {
          _id: param.id,
          descricao: param.descricao,
          ordem: param.ordem,
          saldo_atual: 'R$ ' + real(param.saldo_atual),
          saldo_nao_conciliado: 'R$ ' + real(param.saldo_nao_conciliado),
          tipo_conta: param.tipo_conta,
          ativo: param.ativo ? 'Sim' : 'Não',
          unin_descricao: param.num_unidade + ' - ' + param.unidade,
          limite: 'R$ ' + real(param.limite)
        }
      })
  
      setState(prevState => ({...prevState,
        contaFinanceiras: {
          list: contaFinanceiras
        }
      }))
    }  catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const startTour = () => {
    setRun(true); // Inicia o tour
  };

  useEffect(() => {
    const fetchData = async () => {
      try {

        const perfil = JSON.parse(localStorage.getItem('perfil'))
  
        let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'conta-financeira')[0]
        let permissoesCorrigeSaldo = perfil.permissoes.filter(param => param.tela.modulo.slug === 'ajuste_saldo_conta')[0]
  
        if (!permissoes.visualizar) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: "Você não tem permissão para acessa essa tela!"
            }
          }))
        }
  
        const acoesTabela = []
  
        if (permissoes.alterar) {
          acoesTabela.push('update')
        }
  
        if(permissoesCorrigeSaldo){
          if (permissoesCorrigeSaldo.inserir || permissoesCorrigeSaldo.alterar) {
            acoesTabela.push('corrigeSaldo')
          }
        }
  
        setState(prevState => ({...prevState, acoesTabela, permissoes }))
  
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
        const filtro = state.filtro
  
        const dados = {
          filtro,
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }
        
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/contaFinanceira/list`, dados, getToken())
  
        const contaFinanceiras = data.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(param => {
          return {
            _id: param.id,
            descricao: param.descricao,
            ordem: param.ordem,
            saldo_atual: 'R$ ' + real(param.saldo_atual),
            saldo_nao_conciliado: 'R$ ' + real(param.saldo_nao_conciliado),
            tipo_conta: param.tipo_conta,
            ativo: param.ativo ? 'Sim' : 'Não',
            unin_descricao: param.num_unidade + ' - ' + param.unidade,
            limite: 'R$ ' + real(param.limite),
            url_banco: param.logo
          }
        })
  
        setState(prevState => ({...prevState,
          contaFinanceiras: {
            list: contaFinanceiras
          },
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
  
      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { filtro, contaFinanceiras, permissoes } = state
  
  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main history={props.history} openTour={startTour} exibirAjuda={true}>
            <Grid container spacing={1} direction="row" className="borderBottom" >
              <Grid item md={10} xs={12}>
                <h1 className="titulo">Contas Bancárias</h1>
              </Grid>
              {permissoes.inserir &&
                <Grid item md={2} xs={12}>
                  <Link to="/conta_financeira/cadastro">
                    <Button id="button-cadastrar" className="btnCadastrar" variant="contained" color="primary">
                      Cadastrar Conta
                    </Button>
                  </Link>
                </Grid>
              }
            </Grid>
            <Grid ref={gridRefFiltro} container direction="row" spacing={1} className="mg_top_20">
              <Grid item md={3} xs={12} sm={6}>
                <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={filtro.descricao} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField className="input" label="Tipo Conta" variant="outlined" size="small" name="tipo_conta" value={filtro.tipo_conta} onChange={(e) => updateFiltro(e)} InputLabelProps={{ shrink: true }} placeholder='Pesquisar' />
              </Grid>
              <Grid item md={2} xs={12} sm={6}>
                <TextField
                  id="standard-select-currency"
                  select
                  label="Contas Ativas*"
                  variant="outlined"
                  className="input"
                  fullWidth
                  size="small"
                  SelectProps={{
                    native: true,
                  }}
                  name="ativo"
                  value={filtro.ativo}
                  onChangeCapture={(e) => updateFiltro(e)}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value='Sim'> Sim </option>
                  <option value='Não'> Não </option>
                </TextField>
              </Grid>
              <Grid item md={3} xs={false} sm={false}></Grid>
              <Grid item md={2} xs={12} sm={6}>
                <Button color='warning' fullWidth variant="contained" onClick={() => filtrarContas()}>
                  Filtrar
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={1} direction="row" className="mg_top_10">
              <Grid item md={12} xs={12}>
                <Table
                  urlUpdate="/conta_financeira/cadastro/"
                  urlView="/conta_financeira/view/"
                  urlCorrigeSaldo="/conta_financeira/corrige_saldo/"
                  headCell={state.cabecalhoTabela}
                  rows={contaFinanceiras.list}
                  acoes={state.acoesTabela} />
              </Grid>
            </Grid>
          </Main>
          <Nav history={props.history}/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <Joyride
        steps={steps}
        run={run}
        setRun={(e) => setRun(e)}
      />
    </React.Fragment>
  )
}

export default ContaFinanceiras

import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import ReactPlayer from 'react-player'

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  marginTop: 30,
  width: '60%', 
  marginLeft: '20%',
  overflowY: 'auto',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },

  // Media Queries para diferentes tamanhos de tela
  [theme.breakpoints.only('xs')]: {
    width: '90%', // para telas pequenas 
    marginLeft: '5%',
  },

  [theme.breakpoints.only('md')]: {
    width: '50%', // para telas pequenas 
    marginLeft: '25%',
  }
}));

export default function SimpleModal(props) {

  const {documento, handleClose, open} = props
  const theme = useTheme();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme} id='view'>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={1} sm={1}>
              <Tooltip title='Fechar' aria-label="add">
                <IconButton color="error" aria-label="view" onClick={handleClose} style={{position: 'absolute', top:5, right: 5}}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              {(documento.formato === 'image/jpeg' || documento.formato === 'image/png') &&
                <img style={{width: '90%', marginLeft: '5%'}} src={documento.url} alt={documento.nome} />
              }
              {(documento.formato === 'video/mp4' || documento.formato === 'video/webm' || documento.formato === 'video/ogg') &&
                <div>
                  <video 
                    width="600" 
                    controls 
                    autoPlay 
                    style={{ borderRadius: '8px' }}
                  >
                    <source src={documento.url} type="video/mp4" />
                    Seu navegador não suporta a tag de vídeo.
                  </video>
                </div>
              }
            </Grid>
          </Grid>
        </StyledPaper>
      </Modal>
    </div>
  );
}

import React from 'react'
import './Templates.scss'
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

const main = props => {
	return (
		<React.Fragment>
			<main className="content container-fluid">
				<div className={`p-3 mt-3 h-90 ${props.tela ? props.tela: '' }`}>
					{props.children}
				</div>
			</main>
			<span style={{marginRight: 60}} className='clicksistemas'>Desenvolvido por Click Sistemas LTDA 2024® </span>
			{props.exibirAjuda &&
				<Tooltip title='Ajuda' aria-label="add" arrow>
					<HelpIcon id="start-tour-btn" className='botao_ajuda' onClick={() => props.openTour()}/>
				</Tooltip>
			}
		</React.Fragment>
	)
}


export default main
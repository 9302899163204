import React from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { Grid, TextField, Button } from '@mui/material'
import CurrencyInput from './../mask/CurrencyInput'
import Table from './../Table'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';

let cabecalhoTabela = [
  { id: 'prod_descricao', numeric: false, disablePadding: true, label: 'Serviço' },
  { id: 'venc_prim_parcela_form', numeric: false, disablePadding: true, label: 'Prim. Vencimento' },
  // { id: 'parametro', numeric: false, disablePadding: true, label: 'Parâmetro' },
  { id: 'valor_form', numeric: true, disablePadding: true, label: 'Valor' },
  { id: 'formpag_descricao', numeric: true, disablePadding: true, label: 'Forma de Pagamento' },
  { id: 'contfin_descricao', numeric: true, disablePadding: true, label: 'Conta Financeira' },
  // { id: 'habilita_valor_min', numeric: true, disablePadding: true, label: 'Hab. Mínimo' },
  // { id: 'valor_min', numeric: true, disablePadding: true, label: 'Valor Mínimo' },
  // { id: 'habilita_valor_max', numeric: true, disablePadding: true, label: 'Hab. Máximo' },
  // { id: 'valor_max', numeric: true, disablePadding: true, label: 'Valor Máximo' },
  { id: 'inativa_unidade', numeric: false, disablePadding: true, label: 'Inativa Unidade' },
  { id: 'gerar_nfse', numeric: false, disablePadding: true, label: 'Gerar NFS-e' },
  { id: 'ativo', numeric: false, disablePadding: true, label: 'Ativo' },
]

let acoesTabela = []

let tabela_paramentros = [
  {
    parametro: 'FIXO',
    value: 'Fixo'
  },
  {
    parametro: 'ALUNOS',
    value: 'Por Alunos'
  },
  {
    parametro: 'NUM_WHATS',
    value: 'Por Números do Whats'
  }
]

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  width: '96%',
  marginLeft: '2%',
  marginTop: '30px',
  backgroundColor: '#FFF',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  overflowY: 'auto',
  listStyle: 'none',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '0.7em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(100,149,237, .5)',
    outline: '1px solid slategrey',
  },
}));

export default function SimpleModal(props) {
  const theme = useTheme();
  const {dados, cancelarContrato, renovarContrato} = props

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <StyledPaper theme={theme} id='view'>
          <Grid container direction="row" spacing={1} className="borderBottom">
            <Grid item md={12} xs={12} sm={12}>
              <h5 className="tituloConfirmacao">Contrato</h5>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1} className="mg_top_20">
            {/* <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input" 
                label="Codigo" 
                variant="outlined" 
                size="small"
                name="codigo" 
                value={dados.codigo} 
                disabled 
                InputLabelProps={{ shrink: true }} 
              />
            </Grid> */}
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input"
                label="Data Inicio Vigência"
                variant="outlined"
                name="data_ini_vig"
                value={dados.data_ini_vig}
                disabled
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input" 
                label="Tempo Contrato" 
                type="number"
                variant="outlined" 
                size="small" 
                name="tempo_contrato" 
                value={dados.tempo_contrato} 
                disabled 
                InputLabelProps={{ shrink: true }} 
              />
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField
                id="standard-select-currency"
                select
                label="Unidade Tempo Duração"
                variant="outlined"
                className="input"
                fullWidth
                size="small"
                SelectProps={{
                  native: true,
                }}
                name="unidade_tempo"
                value={dados.unidade_tempo}
                disabled
                InputLabelProps={{ shrink: true }}
              >
                <option value=''> Selecionar Unidade </option>
                {/* <option value='DIA'> Dia </option> */}
                <option value='MÊS'> Mês </option>
                {/* <option value='SEMESTRE'> Semestre </option> */}
                <option value='ANO'> Ano </option>
                
              </TextField>
            </Grid>
            <Grid item md={2} xs={12} sm={6}>
              <TextField 
                className="input"
                label="Data Fim Vigência"
                variant="outlined"
                name="data_fim_vig"
                value={dados.data_fim_vig}
                disabled
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField 
                className="input" 
                label="Qtd. Dias Pagamento"
                type="number"
                variant="outlined" 
                size="small"
                name="dias_pagamento" 
                value={dados.dias_pagamento} 
                disabled 
                InputLabelProps={{ shrink: true }} 
              />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField 
                className="input" 
                label="% Juros"
                variant="outlined" 
                size="small" 
                name="juros" 
                value={dados.juros} 
                InputProps={{ inputComponent: CurrencyInput }}
                disabled 
                InputLabelProps={{ shrink: true }} 
              />
            </Grid>
            <Grid item md={2} xs={12} sm={3}>
              <TextField 
                className="input" 
                label="Situação Atual"
                variant="outlined" 
                size="small" 
                name="situacao" 
                value={dados.situacao} 
                disabled 
                InputLabelProps={{ shrink: true }} 
              />
            </Grid>
          </Grid>
          <hr className="mg_top_10"/>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <h4>Serviços:</h4>
            </Grid>
          </Grid>
          <Grid container direction="row" spacing={1}>
            <Grid item md={12} xs={12} sm={12}>
              <Table
                headCell={cabecalhoTabela}
                rows={dados.produtos.map(value => {
                  return {
                    ...value,
                    valor_form: value.valor, 
                    parametro: tabela_paramentros.filter(param => param.parametro === value.parametro)[0].value,
                    valor_min: value.habilita_valor_min ? value.valor_min : 'N/A',
                    valor_max: value.habilita_valor_max ? value.valor_max : 'N/A',
                    venc_prim_parcela_form: moment(value.venc_prim_parcela).format('DD/MM/YYYY'),
                  }
                })}
                acoes={acoesTabela}
                noRemove={false} 
              />
            </Grid>
          </Grid>
          {dados.situacao === 'ATIVO' &&
            <Grid container direction="row" spacing={1}>
              <Grid item md={3} xs={12} sm={4}>
                <Button
                  size='small'
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={props.handleClose}
                  startIcon={<ArrowBackIcon />}
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item md={6} xs={12} sm={4}></Grid>
              <Grid item md={3} xs={12} sm={4}>
                <Button
                  size='small'
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => cancelarContrato()}
                >
                  Cancelar Contrato
                </Button>
              </Grid>
            </Grid>
          }

          {dados.situacao === 'ENCERRADO' &&
            <Grid container direction="row" spacing={1}>
              <Grid item md={3} xs={12} sm={4}>
                <Button
                  size='small'
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={props.handleClose}
                  startIcon={<ArrowBackIcon />}
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item md={6} xs={12} sm={4}></Grid>
              <Grid item md={3} xs={12} sm={4}>
                <Button
                  size='small'
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => renovarContrato()}
                >
                  Renovar Contrato
                </Button>
              </Grid>
            </Grid>
          }

          {dados.situacao === 'CANCELADO' &&
            <Grid container direction="row" spacing={1}>
              <Grid item md={3} xs={12} sm={4}>
                <Button
                  size='small'
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={props.handleClose}
                  startIcon={<ArrowBackIcon />}
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item md={9} xs={12} sm={8}></Grid>
            </Grid>
          }
        </StyledPaper>
      </Modal>
    </div>
  );
}

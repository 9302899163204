import React, { useState, useEffect, useRef } from 'react';
import './PlanoContas.scss'
import './../../main/ultil.scss'
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import { Grid, Button } from '@mui/material'
import axios from 'axios'
import Loading from './../../components/templates/Loading'
import Alert from './../../components/templates/Alert'
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Joyride from './../../components/templates/Joyride'

const initialState = {
  planoConta: {

  },
  planoContas: {
    list: []
  },
  planoContasFiltrado: {
    list: []
  },
  filtro: {
    codigo: "",
    descricao: ""
  },
  cabecalhoTabela: [
    { id: 'codigo', numeric: false, disablePadding: true, label: 'Codigo' },
    { id: 'descricao', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'tipo', numeric: false, disablePadding: true, label: 'Tipo' },
    { id: 'unidade_cadastro', numeric: false, disablePadding: true, label: 'Unidade' },
    { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
  ],
  acoesTabela: [],
  unin_tipo: "",
  loading: true,
  mensagemLoading: 'Carregando Plano de Contas...',
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  planosSegundoNivel: {
    list: []
  },
  planosTerceiroNivel: {
    list: []
  },
  planosQuartoNivel: {
    list: []
  },
  planosQuintoNivel: {
    list: []
  },
  primeiroNivelSelecionado: false,
  segundoNivelSelecionado: false,
  terceiroNivelSelecionado: false,
  quartoNivelSelecionado: false,
  centros_custo: {
    list: []
  },
  openModalAjuda: false,
  markdown: ''
}

const PlanoContas = (props) => {

  const [state, setState] = useState({ ...initialState });
  const [run, setRun] = useState(false);

  const gridRefPlano = useRef(null);

  const steps = [
    {
      target: "#button-cadastrar-plano",
      content: "Neste botão, você terá acesso à tela de cadastro do plano de contas.",
      disableBeacon: true
    },
    {
      target: gridRefPlano.current,
      content: `Aqui, você visualizará os três níveis do plano de contas: "Conta", "Categoria" e "Subcategoria", além dos centros de custos vinculados ao plano selecionado. Para visualizar cada nível do plano de contas, basta clicar no plano desejado para expandir e exibir o próximo nível. Para editar ou visualizar um plano de contas, primeiro selecione o plano desejado e, em seguida, os botões de edição e visualização aparecerão na parte superior.`,
      placement: 'auto'
    },
  ]

  const getToken = () =>{
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }
  
  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const selecionarPrimeiroNivel = (plano) => {
    setState(prevState => ({...prevState,
      segundoNivelSelecionado: false,
      terceiroNivelSelecionado: false,
      quartoNivelSelecionado: false,
      planosSegundoNivel: {
        list: []
      },
      planosTerceiroNivel: {
        list: []
      },
      planosQuartoNivel: {
        list: []
      },
      planosQuintoNivel: {
        list: []
      }
    }))

    const planos = state.planoContasFiltrado.list

    for (let i = 0; i < planos.length; i++) {
      if(planos[i].index !== plano.index){
        planos[i].plano_selecionado = false
      }
    }

    let index = planos.findIndex(param => param.index === plano.index)

    planos[index].plano_selecionado = !planos[index].plano_selecionado
    
    setState(prevState => ({...prevState,
      primeiroNivelSelecionado: planos[index].plano_selecionado
    }))

    if(planos[index].plano_selecionado){
      setState(prevState => ({...prevState,
        planosSegundoNivel: {
          list: plano.niveis_inferior.sort((a, b) => (a.sequencia > b.sequencia) ? 1 : ((b.sequencia > a.sequencia) ? -1 : 0)).map((value, key) => {
            return {
              ...value,
              index: key,
              plano_selecionado: false
            }
          })
        },
        planoContasFiltrado: {
          list: planos
        },
        centros_custo: {
          list: planos[plano.index].centro_custos.map(value => {
            return {
              valor_percentual: value.valor_percentual,
              centro_custo:{
                desc: value.centro_custo.descricao
              }
            }
          })
        }
      }))
    }
  }

  const selecionarSegundoNivel = (plano) => {
    const planos = state.planosSegundoNivel.list

    setState(prevState => ({...prevState,
      terceiroNivelSelecionado: false,
      quartoNivelSelecionado: false,
      planosTerceiroNivel: {
        list: []
      },
      planosQuartoNivel: {
        list: []
      },
      planosQuintoNivel: {
        list: []
      }
    }))

    for (let i = 0; i < planos.length; i++) {
      if(planos[i].index !== plano.index){
        planos[i].plano_selecionado = false
      }
    }

    let index = planos.findIndex(param => param.index === plano.index)

    planos[index].plano_selecionado = !planos[index].plano_selecionado

    setState(prevState => ({...prevState,
      segundoNivelSelecionado: planos[index].plano_selecionado
    }))

    if(planos[index].plano_selecionado){
      setState(prevState => ({...prevState,
        planosTerceiroNivel: {
          list: plano.niveis_inferior.sort((a, b) => (a.sequencia > b.sequencia) ? 1 : ((b.sequencia > a.sequencia) ? -1 : 0)).map((value, key) => {
            return {
              ...value,
              index: key,
              plano_selecionado: false
            }
          })
        },
        planosSegundoNivel: {
          list: planos
        },
        centros_custo: {
          list: planos[plano.index].centro_custos.map(value => {
            return {
              valor_percentual: value.valor_percentual,
              centro_custo:{
                desc: value.centro_custo.descricao
              }
            }
          })
        }
      }))
    }
  }

  const selecionarTerceiroNivel = (plano) => {
    const planos = state.planosTerceiroNivel.list

    setState(prevState => ({...prevState,
      quartoNivelSelecionado: false,
      planosQuartoNivel: {
        list: []
      },
      planosQuintoNivel: {
        list: []
      }
    }))

    for (let i = 0; i < planos.length; i++) {
      if(planos[i].index !== plano.index){
        planos[i].plano_selecionado = false
      }
    }

    let index = planos.findIndex(param => param.index === plano.index)

    planos[index].plano_selecionado = !planos[index].plano_selecionado

    setState(prevState => ({...prevState,
      terceiroNivelSelecionado: planos[index].plano_selecionado
    }))

    if(planos[index].plano_selecionado){
      setState(prevState => ({...prevState,
        planosQuartoNivel: {
          list: plano.niveis_inferior.sort((a, b) => (a.sequencia > b.sequencia) ? 1 : ((b.sequencia > a.sequencia) ? -1 : 0)).map((value, key) => {
            return {
              ...value,
              index: key,
              plano_selecionado: false
            }
          })
        },
        planosTerceiroNivel: {
          list: planos
        },
        centros_custo: {
          list: planos[plano.index].centro_custos
        }
      }))
    }
  }

  const startTour = () => {
    setRun(true); // Inicia o tour
  };

  useEffect(() => {
    const fetchData = async () => {
      const perfil = JSON.parse(localStorage.getItem('perfil'))

      let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'plano-contas')[0]
  
      let acoesTabela = []
  
      if (permissoes.visualizar) {
        acoesTabela.push('view')
      }
  
      if (permissoes.alterar) {
        acoesTabela.push('update')
      }
  
      setState(prevState => ({...prevState,
        acoesTabela,
        permissoes
      }))
  
      try {
  
        const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
        const dados = {
          unidadesnegocio: acesso_atual.map(acesso => {
            return acesso.id
          })
        }
  
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/planoContas/listGrupo`, dados,getToken())
  
        const planoContas = data.map((param, key) => {
          return {
            ...param,
            plano_selecionado: false,
            index: key
          }
        })
  
        setState(prevState => ({...prevState,
          planoContas: {
            list: planoContas
          },
          planoContasFiltrado: {
            list: planoContas
          }
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
  
      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { permissoes, planoContasFiltrado, planosSegundoNivel, planosTerceiroNivel, centros_custo } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main history={props.history} openTour={startTour} exibirAjuda={true}>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={9} xs={12}>
                  <h1 className="titulo">Planos de Contas</h1>
                </Grid>
                {permissoes.inserir &&
                  <Grid item md={3} xs={12}>
                    <Link to="/plano_contas/cadastro">
                      <Button id="button-cadastrar-plano" className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Plano de Contas
                      </Button>
                    </Link>
                  </Grid>
                }
              </Grid>
              <Grid ref={gridRefPlano} container direction="row" className="mg_top_20">
                <Grid item md={3} xs={12} sm={6} className='cardPlano'>
                  <Grid container direction="row" className='titulo'>
                    <Grid item md={8} xs={8} sm={6}>
                      <h6>Conta</h6>
                    </Grid>
                    {state.primeiroNivelSelecionado &&
                      <React.Fragment>
                        {!permissoes.alterar &&
                          <Grid item md={2} xs={2} sm={3}></Grid>
                        }
                        <Grid item md={2} xs={2} sm={3}>
                          <Link to={`/plano_contas/view/${planoContasFiltrado.list.filter(param => param.plano_selecionado === true)[0].id }`}>
                            <IconButton color="primary" aria-label="view">
                              <VisibilityIcon />
                            </IconButton>
                          </Link>
                        </Grid>
                        {permissoes.alterar && 
                          <Grid item md={2} xs={2} sm={3}>
                            <Link to={`/plano_contas/cadastro/${planoContasFiltrado.list.filter(param => param.plano_selecionado === true)[0].id }`}>
                              <IconButton color="error" aria-label="view">
                                <CreateIcon />
                              </IconButton>
                            </Link>
                          </Grid>
                        }
                      </React.Fragment>
                    }
                  </Grid>
                  <Grid container direction="row" className='conteudo'>
                    <Grid item md={12} xs={12} sm={12}>
                      {planoContasFiltrado.list.length === 0 &&
                        <Grid container direction="row" className='sem_plano'>
                          <Grid item md={8} xs={12} sm={12}>
                            <span>Sem Plano.</span>
                          </Grid>
                        </Grid>
                      }
                      {planoContasFiltrado.list.sort((a, b) => (a.codigo > b.codigo) ? 1 : ((b.codigo > a.codigo) ? -1 : 0)).map(plano => {
                        return (
                          <Grid container direction="row" className={`linha_plano ${plano.plano_selecionado ? 'active' : ''}`} onClick={e => selecionarPrimeiroNivel(plano)}>
                            <Grid item md={2} xs={2} sm={2} className='plano_codigo'>
                              <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.codigo}</span>
                            </Grid>
                            <Grid item md={8} xs={8} sm={12} className='plano_descricao'>
                              <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.descricao}</span>
                            </Grid>
                            <Grid item md={2} xs={2} sm={12} className='plano_niveis_inferior'>
                              <div>
                                <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.niveis_inferior.length}</span>
                              </div>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={12} sm={6} className='cardPlano'>
                  <Grid container direction="row" className='titulo'>
                    <Grid item md={8} xs={8} sm={6}>
                      <h6>Categoria</h6>
                    </Grid>
                    {state.segundoNivelSelecionado &&
                      <React.Fragment>
                        {!permissoes.alterar &&
                          <Grid item md={2} xs={2} sm={3}></Grid>
                        }
                        <Grid item md={2} xs={2} sm={3}>
                          <Link to={`/plano_contas/view/${planosSegundoNivel.list.filter(param => param.plano_selecionado === true)[0].id }`}>
                            <IconButton color="primary" aria-label="view">
                              <VisibilityIcon />
                            </IconButton>
                          </Link>
                        </Grid>
                        {permissoes.alterar &&
                          <Grid item md={2} xs={2} sm={3}>
                            <Link to={`/plano_contas/cadastro/${planosSegundoNivel.list.filter(param => param.plano_selecionado === true)[0].id }`}>
                              <IconButton color="error" aria-label="view">
                                <CreateIcon />
                              </IconButton>
                            </Link>
                          </Grid>
                        }
                      </React.Fragment>
                    }
                  </Grid>
                  <Grid container direction="row" className='conteudo'>
                    <Grid item md={12} xs={12} sm={12}>
                      {planosSegundoNivel.list.length === 0 &&
                        <Grid container direction="row" className='sem_plano'>
                          {state.primeiroNivelSelecionado &&
                            <Grid item md={12} xs={12} sm={12}>
                              <span>Sem Categoria.</span>
                            </Grid>
                          }
                        </Grid>
                      }
                      {planosSegundoNivel.list.sort((a, b) => (a.codigo > b.codigo) ? 1 : ((b.codigo > a.codigo) ? -1 : 0)).map(plano => {
                        return (
                          <Grid container direction="row" className={`linha_plano ${plano.plano_selecionado ? 'active' : ''}`} onClick={e => selecionarSegundoNivel(plano)}>
                            <Grid item md={2} xs={3} sm={12} className='plano_codigo'>
                              <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.codigo}</span>
                            </Grid>
                            <Grid item md={8} xs={7} sm={12} className='plano_descricao'>
                              <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.descricao}</span>
                            </Grid>
                            <Grid item md={2} xs={2} sm={12} className='plano_niveis_inferior'>
                              <div>
                                <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.niveis_inferior.length}</span>
                              </div>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={12} sm={6} className='cardPlano'>
                  <Grid container direction="row" className='titulo'>
                    <Grid item md={8} xs={8} sm={6}>
                      <h6>Sub Categoria</h6>
                    </Grid>
                    {state.terceiroNivelSelecionado &&
                      <React.Fragment>
                        {!permissoes.alterar &&
                          <Grid item md={2} xs={2} sm={3}></Grid>
                        }
                        <Grid item md={2} xs={2} sm={3}>
                          <Link to={`/plano_contas/view/${planosTerceiroNivel.list.filter(param => param.plano_selecionado === true)[0].id }`}>
                            <IconButton color="primary" aria-label="view">
                              <VisibilityIcon />
                            </IconButton>
                          </Link>
                        </Grid>
                        {permissoes.alterar &&
                          <Grid item md={2} xs={2} sm={3}>
                            <Link to={`/plano_contas/cadastro/${planosTerceiroNivel.list.filter(param => param.plano_selecionado === true)[0].id }`}>
                              <IconButton color="error" aria-label="view">
                                <CreateIcon />
                              </IconButton>
                            </Link>
                          </Grid>
                        }
                      </React.Fragment>
                    }
                  </Grid>
                  <Grid container direction="row" className='conteudo'>
                    <Grid item md={12} xs={12} sm={12}>
                      {planosTerceiroNivel.list.length === 0 &&
                        <Grid container direction="row" className='sem_plano'>
                          {state.segundoNivelSelecionado && 
                            <Grid item md={8} xs={12} sm={12}>
                              <span>Sem Sub Categoria.</span>
                            </Grid>
                          }
                        </Grid>
                      }
                      {planosTerceiroNivel.list.sort((a, b) => (a.codigo > b.codigo) ? 1 : ((b.codigo > a.codigo) ? -1 : 0)).map(plano => {
                        return (
                          <Grid container direction="row" className={`linha_plano ${plano.plano_selecionado ? 'active' : ''}`} onClick={e => selecionarTerceiroNivel(plano)}>
                            <Grid item md={2} xs={3} sm={12} className='plano_codigo'>
                              <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.codigo}</span>
                            </Grid>
                            <Grid item md={8} xs={7} sm={12} className='plano_descricao'>
                              <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.descricao}</span>
                            </Grid>
                            <Grid item md={2} xs={2} sm={12} className='plano_niveis_inferior'>
                              <div>
                                <span style={{textDecoration: !plano.ativo ? 'line-through' : 'none'}}>{plano.centro_custos.length}</span>
                              </div>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={12} sm={6} className='cardPlano'>
                  <Grid container direction="row" className='titulo'>
                    <Grid item md={12} xs={12} sm={12}>
                      <h6>Centro de Custo</h6>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" className='conteudo'>
                    <Grid item md={12} xs={12} sm={12}>
                      {centros_custo.list.length === 0 &&
                        <Grid container direction="row" className='sem_plano'>
                          {state.primeiroNivelSelecionado && 
                            <Grid item md={8} xs={12} sm={12}>
                              <span>Sem Centro de Custo.</span>
                            </Grid>
                          }
                        </Grid>
                      }
                      {centros_custo.list.map(centro => {
                        return (
                          <Grid container direction="row" className={`linha_plano`}>
                            <Grid item md={10} xs={9} sm={12} className='plano_descricao'>
                              <span style={{marginLeft: '10px'}}>{centro.centro_custo.desc}</span>
                            </Grid>
                            <Grid item md={2} xs={3} sm={12} className='plano_codigo'>
                              <span>{centro.valor_percentual} %</span>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Main>
          <Nav history={props.history}/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
      <Joyride
        steps={steps}
        run={run}
        setRun={(e) => setRun(e)}
      />
    </React.Fragment>
  )
}

export default PlanoContas
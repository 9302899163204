import React, { Component } from 'react'
import './WhatsApp.scss'
import './../../main/ultil.scss'
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Table from '../../components/Table'
import { Grid, Button } from '@mui/material'
import axios from 'axios'
import Alert from './../../components/templates/Alert'
import ModalAjuda from './../../components/modals/Ajuda'

const initialState = {
  numeros: {
    list: []
  },
  numerosFiltrado: {
    list: []
  },
  loading: true,
  cabecalhoTabela: [
    { id: 'nome', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'numero', numeric: false, disablePadding: true, label: 'Número' },
    { id: 'conectado', numeric: false, disablePadding: true, label: 'Conectado' },
    { id: 'ativo', numeric: false, disablePadding: true, label: 'Ativo' },
  ],
  acoesTabela: [],
  unin_tipo: "",
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  filtro: {
    descricao: "",
  },
  permissoes: {
    alterar: false,
    inserir: false,
    visualizar: false,
    deletar: false
  },
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  openModalAjuda: false,
  markdown: ''
}

function formataTelefone(numero) {

  if(numero){
    let texto = numero.trim()

		texto = texto.replace(/\D/g,""); 
    
    if(texto.length === 11 || texto.length === 10){
      texto = texto.replace(/^(\d{2})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 12){
      texto = texto.replace(/^(\d{3})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 8){
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }
  
    return texto
  }

  return ''
}

export default class WhatsApp extends Component {
  state = { ...initialState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {
    const helpPath = require("./../../help/Listagem.md")

    fetch(helpPath)
      .then(response => {
        return response.text()
      })
      .then(text => {
        this.setState({
          markdown: text
        })
      })

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'api-whatsapp')[0]

    let acoesTabela = []

    if (permissoes.alterar) {
      acoesTabela.push('update')
    }

    this.setState({
      acoesTabela,
      permissoes
    })

    try {
      const { data: numeros } = await axios.get(`${window._env_.REACT_APP_API_URL}/whatsApp/listInstancia`, this.getToken())

      this.setState({
        numeros: {
          list: numeros.map((value, key) => {
            return {
              _id: value.id,
              nome: value.nome,
              numero: formataTelefone(value.numero),
              conectado: value.conectado,
              ativo: value.ativo,
              index: key
            }
          })
        }
      })

    } catch (error) {
      console.log(error)
      this.setState({
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      })
    }

    this.setState({
      loading: false
    })
  }

  handleCloseAlerta(){
    this.setState({
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: this.state.alerta.severity,
        message: ''
      }
    })
  }

  render() {
    const { permissoes, numeros } = this.state
    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history} openModalAjuda={() => {this.setState({openModalAjuda: true})}}>
          {this.state.loading &&
            <React.Fragment>
              <div className="progressCircular">
                <CircularProgress />
              </div>
              <div className="progressText">
                <p>Carregando Números...</p>
              </div>
            </React.Fragment>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={9} xs={12} sm={6}>
                  <h1 className="titulo">Números WhatsApp</h1>
                </Grid>
                {(permissoes.inserir && window._env_.REACT_APP_APLICACAO !== "homologacao") &&
                  <Grid item md={3} xs={12} sm={6}>
                    <Link to="/api_whatsapp/cadastro">
                      <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                        Cadastrar Número
                      </Button>
                    </Link>
                  </Grid>
                }
                <Grid item md={3} xs={12} sm={6}>
                  <Link to="/api_whatsapp/cadastro">
                    <Button size="small" className="btnCadastrar" variant="contained" color="primary">
                      Cadastrar Número
                    </Button>
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" className="mg_top_20">
                <Grid item md={12} xs={12}>
                  <Table 
                    urlUpdate="/api_whatsapp/cadastro/" 
                    urlView="/api_whatsapp/view/" 
                    headCell={this.state.cabecalhoTabela} 
                    rows={numeros.list} 
                    acoes={this.state.acoesTabela} 
                  />
                </Grid>
              </Grid>
            </div>
          }

        </Main>
         <Nav history={this.props.history}/>
        <Alert 
          open={this.state.alerta.open}
          handleClose={e => this.handleCloseAlerta()} 
          severity={this.state.alerta.severity}
          message={this.state.alerta.message} 
        />
        <ModalAjuda 
          open={this.state.openModalAjuda}
          tela={"Listagem de Números Whats"}
          handleClose={() => {this.setState({openModalAjuda: false})}}
          markdown={this.state.markdown}
        />
      </div>
    )
  }
}
import React, { useState, useEffect } from 'react';
import './../../main/ultil.scss'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Cadastrar from './components/Cadastro'
import { Grid } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';

const initialState = {
  openModalAjuda: false,
  markdown: '',
  openInfo: false
}

const CadastroContasPR = (props) => {
  const [state, setState] = useState({ ...initialState });
  
  useEffect(() => {
    const fetchData = async () => {
      const helpPath = require("./../../help/contaPagarReceber/Cadastro.md")

      fetch(helpPath)
        .then(response => {
          return response.text()
        })
        .then(text => {
          setState(prevState => ({...prevState,
            markdown: text
          }))
        })
    };
    
    fetchData();
  }, []);
  
  return (
    <div className="app-menu-closed" id="app">
      <Main history={props.history}>
        <Cadastrar 
          {...props}
        />
      </Main>
      <Nav history={props.history}/>
      
    </div>
  )
}

export default CadastroContasPR
import React from 'react'
import CurrencyInput from './../mask/CurrencyInput'
import { Grid, TextField, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import Table from './../Table'

const verificaDisabledCampos = (dados) => {
  if (dados.situacao === 'BAIXADO' || dados.situacao === 'CANCELADO' || dados.situacao === 'ESTORNADO') {
    return true
  }

  return false
}

const verificaDisabledCamposValor = (dados) => {
  if(dados.plancontas_slug === 'mensalidade' || dados.plancontas_slug === 'matricula'){
    return true
  }
  
  if (dados.situacao === 'BAIXADO' || dados.situacao === 'CANCELADO' || dados.situacao === 'ESTORNADO') {
    return true
  }

  return false
}

const cabecalhoTabela = [
  { id: 'valor_pagamento', numeric: false, disablePadding: true, label: 'Valor Pagamento' },
  { id: 'contfin_descricao', numeric: false, disablePadding: true, label: 'Contas Bancárias' },
  { id: 'formapagto_descricao', numeric: false, disablePadding: true, label: 'Forma de Pagamento' },
]

const cabecalhoTabelaPagamento = [
  { id: 'valor_pagamento', numeric: false, disablePadding: true, label: 'Valor Pagamento' },
  { id: 'contfin_descricao', numeric: false, disablePadding: true, label: 'Contas Bancárias' },
  { id: 'formapagto_descricao', numeric: false, disablePadding: true, label: 'Forma de Pagamento' },
  { id: 'tipo_chave', numeric: false, disablePadding: true, label: 'Tipo Pix' },
  { id: 'chave_pix', numeric: false, disablePadding: true, label: 'Chave Pix' },
]

const acoesTabela = ['editar', 'remove']

const FormParcelaConta = ({ dados, updateField, updateFieldPagamento, removeFormaPagto, updateForma,
  contasFinanceira, formasPagamento, logado, perfilLogado, addFormaPagto, updateFieldFormaPgto, 
  verificaData, forma_pgto, editarFormaPgto, ajustaCentros, gridRefDadosConta, gridRefDadosParcela,
  gridRefTabelaFormPagto, gridRefDadosValorPago }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_20">
      <Grid item md={3} xs={12} sm={4}>
        <TextField id="input-cadastro-em" className="input" label="Cadastrado em" variant="outlined" size="small" name="criado_em" value={dados.criado_em} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField id="input-cadastro-por" className="input" label="Cadastrado por" variant="outlined" size="small" name="criador" value={dados.criador} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
      {dados.situacao === "BAIXADO" &&
        <React.Fragment>
          {/* <Grid item md={3} xs={12} sm={4}>
            <TextField className="input" label="Baixado por" variant="outlined" size="small" name="usuario_baixa" value={dados.usuario_baixa} InputLabelProps={{ shrink: true }} disabled />
          </Grid> */}
        </React.Fragment>
      }
    </Grid>
    <hr className="mg_top_10"/>
    <Grid ref={gridRefDadosConta} container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={4} xs={12} sm={5}>
        <TextField className="input" label="Descricão" variant="outlined" size="small" name="contpr_descricao" value={dados.contpr_descricao} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} disabled={verificaDisabledCampos(dados) ? true : logado.caixa} />
      </Grid>
      <Grid item md={4} xs={12} sm={5}>
        <TextField className="input" label="Cliente/Fornecedor" variant="outlined" size="small" name="cliente_fornecedor" value={dados.cliente_fornecedor} InputLabelProps={{ shrink: true }} disabled={true} />
      </Grid>
      <Grid item md={4} xs={12} sm={5}>
        <TextField className="input" label="Resp. Financeiro" variant="outlined" size="small" name="responsavel" value={dados.responsavel} InputLabelProps={{ shrink: true }} disabled={true} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Valor Total da Conta*" variant="outlined" size="small" name="valortotal" value={dados.valortotalconta} InputLabelProps={{ shrink: true }} disabled={true}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Valor Pago da Conta" variant="outlined" size="small" name="valortotal" value={dados.valor_pago} InputLabelProps={{ shrink: true }} disabled={true}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Valor Restante da Conta" variant="outlined" size="small" name="valor_aberto" value={dados.valor_aberto} InputLabelProps={{ shrink: true }} disabled={true}/>
      </Grid>
      <Grid item md={6} xs={12} sm={6}>
        {dados.itens_conta[0] &&
          <TextField className="input" label="Item Receita e Despesa" variant="outlined" size="small" name="itens_conta" value={dados.itens_conta[0].descricao} InputLabelProps={{ shrink: true }} disabled={true}/>
        }
        {dados.produtos !== '' &&
          <TextField className="input" label="Serviços" variant="outlined" size="small" name="produtos" value={dados.produtos} InputLabelProps={{ shrink: true }} disabled={true}/>
        }     
      </Grid>
    </Grid>
    <hr className="mg_top_10"/>
    <Grid ref={gridRefDadosParcela} container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={2} xs={12} sm={4}>
        <TextField className="input" label="Situação" variant="outlined" size="small" name="situacao" value={dados.situacao} InputLabelProps={{ shrink: true }} disabled />
      </Grid>
      <Grid item md={2} xs={12} sm={2}>
        <TextField className="input" label="Parcela" variant="outlined" size="small" name="num" value={`${dados.num}/${dados.contpr_numparcela}`} InputLabelProps={{ shrink: true }} disabled={verificaDisabledCampos(dados) ? true : logado.caixa} />
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        {verificaDisabledCampos(dados) &&
          <TextField type="date" className="input" label="Data Vencimento*" variant="outlined" size="small" name="datavencimento" value={dados.datavencimento} onBlur={(event) => verificaData(event)} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} disabled />
        }
        {!verificaDisabledCampos(dados) &&
          <TextField type="date" className="input" label="Data Vencimento*" variant="outlined" size="small" name="datavencimento" value={dados.datavencimento} onBlur={(event) => verificaData(event)} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} disabled={perfilLogado === 'caixa'} />
        }
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" type='date' label="Data Pagamento*" variant="outlined" size="small" name="datapagamento" value={dados.datapagamento} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} disabled={verificaDisabledCampos(dados)} />
      </Grid>
      <Grid item md={1} xs={12} sm={6}  id='viewBlue'>
        <TextField className="input" label="Valor*" variant="outlined" size="small" name="valorparcela" value={dados.valorparcela} onBlur={(event) => ajustaCentros(event)} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={verificaDisabledCamposValor(dados)}/>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <TextField className="input" label="Juros/Multa ou Desc." variant="outlined" size="small" name="multajuros" value={dados.multajuros} onBlur={(event) => ajustaCentros(event)} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={verificaDisabledCampos(dados)} />
      </Grid>
      <Grid item md={1} xs={12} sm={6}  id='viewBlue'>
        <TextField className="input" label="Valor Total" variant="outlined" size="small" name="valor_total" value={dados.valortotal} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} disabled={true} />
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Documento" variant="outlined" size="small" name="documento" value={dados.documento} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} disabled={verificaDisabledCampos(dados)} />
      </Grid>
      {dados.boleto_transaction_id &&
        <React.Fragment>
          <Grid item md={2} xs={12} sm={6}>
            <TextField className="input" label="Cod. Transação do Boleto" variant="outlined" size="small" name="boleto_transaction_id" value={dados.boleto_transaction_id} InputLabelProps={{ shrink: true }} disabled />
          </Grid>
        </React.Fragment>
      }
      {dados.usuario_cancelamento &&
        <React.Fragment>
          <Grid item md={3} xs={12} sm={6}>
            <TextField className="input" label="Data Cancelamento/Estorno*" variant="outlined" size="small" name="data_cancelamento" value={dados.data_cancelamento} onChange={(event) => updateField(event)} InputLabelProps={{ shrink: true }} disabled />
          </Grid>
          <Grid item md={3} xs={12} sm={4}>
            <TextField className="input" label="Cancelado/Estornado por" variant="outlined" size="small" name="usuario_cancelamento" value={dados.usuario_cancelamento} InputLabelProps={{ shrink: true }} disabled />
          </Grid>
        </React.Fragment>
      }
      {dados.situacao === 'CANCELADO' &&
        <Grid item md={4} xs={12} sm={4}>
          <TextField className="input" label="Motivo Cancelamento" variant="outlined" size="small" name="motivo_cancelamento" value={dados.motivo_cancelamento} InputLabelProps={{ shrink: true }} disabled={verificaDisabledCampos(dados)} />
        </Grid>
      }
      {dados.situacao === 'ESTORNADO' &&
        <Grid item md={4} xs={12} sm={4}>
          <TextField className="input" label="Motivo do Estorno" variant="outlined" size="small" name="motivo_cancelamento" value={dados.motivo_cancelamento} InputLabelProps={{ shrink: true }} disabled={verificaDisabledCampos(dados)} />
        </Grid>
      }
    </Grid>
    <hr className="mg_top_10" />
    {!dados.baixado &&
      <React.Fragment>
        {dados.situacao !== 'CANCELADO' && 
          <React.Fragment>
            {dados.situacao !== 'ESTORNADO' && 
              <Grid ref={gridRefDadosValorPago} container direction="row" spacing={1} className="mg_top_10">
                <Grid item md={2} xs={12} sm={4}>
                  <TextField className="input" label="Valor Pago*" variant="outlined" size="small" name="valor_pagamento" value={forma_pgto.valor_pagamento} onChange={(e) => updateFieldFormaPgto(e)} InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: CurrencyInput }} />
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  {perfilLogado === 'contas-pagar-receber' &&
                    <TextField
                      id="standard-select-currency"
                      select
                      variant="outlined"
                      className="input"
                      fullWidth
                      label="Conta*"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="contfin_id"
                      value={forma_pgto.contfin_id}
                      onChangeCapture={(e) => updateFieldFormaPgto(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <option value="">--Escolha--</option>
                      {contasFinanceira.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(conta => {
                        return (
                          <option key={conta.id} value={conta.id}>{conta.ordem} - {conta.descricao}</option>
                        )
                      })}
                    </TextField>
                  }
                  {perfilLogado === 'caixa' &&
                    <TextField
                      id="standard-select-currency"
                      select
                      variant="outlined"
                      className="input"
                      fullWidth
                      label="Conta Bancária*"
                      size="small"
                      SelectProps={{
                        native: true,
                      }}
                      name="contfin_id"
                      value={forma_pgto.contfin_id}
                      InputLabelProps={{ shrink: true }}
                      disabled={true}
                    >
                      <option value="">--Escolha--</option>
                      {contasFinanceira.sort((a, b) => (a.ordem > b.ordem) ? 1 : ((b.ordem > a.ordem) ? -1 : 0)).map(conta => {
                        return (
                          <option key={conta.id} value={conta.id}>{conta.ordem} - {conta.descricao}</option>
                        )
                      })}
                    </TextField>
                  }
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <TextField
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    className="input"
                    fullWidth
                    label="Forma de Pagamento*"
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    name="formapagto_id"
                    value={forma_pgto.formapagto_id}
                    onChangeCapture={(e) => updateFieldFormaPgto(e)}
                    InputLabelProps={{ shrink: true }}
                    disabled={verificaDisabledCampos(dados) ? true: (forma_pgto.contfin_id === '' ? true : false)}
                  >
                    <option value={forma_pgto.formapagto_id === '' ? '' : forma_pgto.formapagto_id}>{forma_pgto.formapagto_id === '' ? '--Escolha--' : forma_pgto.formapagto_descricao}</option>
                    {formasPagamento.map(forma => {
                      if (forma.id !== forma_pgto.formapagto_id) {
                        if (forma_pgto.contfin_tipo_conta === 'BANCO') {
                          if (forma.slug !== 'dinheiro') {
                            return (
                              <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                            )
                          }
                        } else if (forma_pgto.contfin_tipo_conta === 'COFRE') {
                          if (forma.slug === 'dinheiro') {
                            return (
                              <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                            )
                          }
                        } else {
                          return (
                            <option key={forma.id} value={forma.id}>{forma.descricao}</option>
                          )
                        }
                      }
                      return false
                    })}
                  </TextField>
                </Grid>
                <Grid item md={2} xs={12} sm={4}>
                  <Button color='warning' fullWidth size="small" variant="contained" className="btn_salvar" startIcon={<AddIcon />} onClick={e => addFormaPagto(e)} >{updateForma ? 'Alterar Pagamento' : 'Adicionar Pagamento'}</Button>
                </Grid>
              </Grid>
            }
          </React.Fragment>
        }
      </React.Fragment>
    }
    <Grid container spacing={1} direction="row" className="mg_top_10">
      <Grid ref={gridRefTabelaFormPagto} item md={12} xs={12}>
        <Table
          headCell={cabecalhoTabela}
          rows={dados.formas_pgto}
          acoes={dados.situacao === 'VENCIDO' || dados.situacao === 'A VENCER' ? acoesTabela : []}
          editar={e => editarFormaPgto(e)}
          remove={e => removeFormaPagto(e)} 
          updateFieldPagamento={(e, index) => updateFieldPagamento(e, index) }
        />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default FormParcelaConta
import React, { useState, useEffect } from 'react';
import './Faqs.scss'
import './../../main/ultil.scss'
import Loading from './../../components/templates/Loading'
import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Alert from './../../components/templates/Alert'
import { Grid } from '@mui/material'
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const initialState = {
  loading: true,
  mensagemLoading: 'Carregando Perguntas Frequentes...',
  alerta: {
    open: false,
    severity: 'error',
    message: ''
  },
  modulo_selecionado: {
    id: 0,
    modulo: "",
    questions: []
  },
  faqData: [
    {
      id: 1,
      modulo: "Recursos Humanos",
      questions: [
        {
          question: "Como cadastrar um departamento?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>RECURSOS HUMANOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>RECURSOS HUMANOS</b>, clique na seção <b>DEPARTAMENTOS</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>DEPARTAMENTOS</b>, será exibida uma tela com a listagem dos departamentos cadastrados. No canto superior direito, clique no botão <b>CADASTRAR DEPARTAMENTO</b>.</p>
            <p><b>4º Passo:</b> Após clicar em <b>CADASTRAR DEPARTAMENTO</b>, você será redirecionado para a tela de cadastro. Nesta tela, informe a descrição do departamento e adicione os centros de custo correspondentes.</p>
            <p><b>5º Passo:</b> Após preencher todas as informações, clique no botão azul <b>SALVAR</b> para concluir o cadastro.</p>
            <p><b>Observação 1:</b> Para adicionar um centro de custo/lucro, informe a descrição e clique no botão laranja <b>ADICIONAR</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/departamentos/cadastro" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de cadastro</a>.</p>
          `
        },
        {
          question: "Como cadastrar um centro de custo/lucro?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>RECURSOS HUMANOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>RECURSOS HUMANOS</b>, clique na seção <b>DEPARTAMENTOS</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>DEPARTAMENTOS</b>, será exibida uma tela com a listagem dos departamentos cadastrados. Caso o departamento já esteja cadastrado, clique no ícone de <b>lápis</b> ao lado do departamento desejado para incluir o centro de custo. Se o departamento ainda não estiver cadastrado, clique no botão <b>CADASTRAR DEPARTAMENTO</b>.</p>
            <p><b>4º Passo:</b> Dentro da tela de cadastro do departamento, você verá a seção onde poderá informar a descrição do centro de custo, definir se ele está ativo ou inativo, e adicionar o centro de custo/lucro ao departamento clicando no botão laranja <b>ADICIONAR</b>.</p>
            <p><b>5º Passo:</b> Após adicionar todos os centros de custo/lucro, clique no botão azul <b>SALVAR</b> para concluir o cadastro.</p>
            <p><b>Acesso rápido:</b> <a href="/departamentos" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de departamentos</a>.</p>
          `
        },
        {
          question: "Como cadastrar um(a) colaborador(a)?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>RECURSOS HUMANOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>RECURSOS HUMANOS</b>, clique na seção <b>COLABORADORES</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>COLABORADORES</b>, será exibida uma tela com a listagem dos colaboradores cadastrados. No canto superior direito, clique no botão <b>CADASTRAR COLABORADOR</b>.</p>
            <p><b>4º Passo:</b> Na tela de cadastro, informe o CPF do colaborador(a) e clique no botão <b>BUSCAR</b>. Se o CPF já estiver cadastrado em sua base, os dados do colaborador(a) serão preenchidos automaticamente. Caso contrário, você precisará preencher as informações manualmente.</p>
            <p><b>5º Passo:</b> Na primeira tela de cadastro, insira os dados pessoais do colaborador(a). Após isso, clique no botão <b>INFORMAR CONTRATO</b>.</p>
            <p><b>6º Passo:</b> Na próxima tela, informe os dados contratuais, como departamento, cargo, função e data de admissão. Após preencher os dados, clique no botão <b>PRÓXIMO</b>.</p>
            <p><b>7º Passo:</b> Na tela seguinte, informe os dados bancários do colaborador(a). Esta informação não é obrigatória. Para avançar para a próxima tela, clique no botão <b>PRÓXIMO</b>.</p>
            <p><b>8º Passo:</b> Por fim, informe os dados de acesso do colaborador(a) no sistema, como perfil, e-mail e senha. O cadastro de usuário não é obrigatório para o colaborador(a). Para finalizar o cadastro, clique no botão <b>SALVAR</b>.</p>
            <p><b>Observação 1:</b> O colaborador(a) poderá alterar a sua senha no primeiro acesso ao sistema.</p>
            <p><b>Acesso rápido:</b> <a href="/funcionario/cadastro" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de cadastro</a>.</p>
          `
        },
        {
          question: "Como demitir um(a) colaborador(a)?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>RECURSOS HUMANOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>RECURSOS HUMANOS</b>, clique na seção <b>COLABORADORES</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>COLABORADORES</b>, será exibida uma tela com a listagem dos colaboradores cadastrados. No canto superior direito, clique no botão <b>CADASTRAR COLABORADOR</b>.</p>
            <p><b>4º Passo:</b> Faça uma busca pelo colaborador(a) que deseja demitir e clique no ícone de <b>olho</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>5º Passo:</b> Você será redirecionado para a tela de dados do colaborador(a). Na parte superior da tela, haverá uma barra de navegação, onde você deverá clicar na aba <b>DADOS DO CONTRATO</b>.</p>
            <p><b>6º Passo:</b> Na tela de dados do contrato, desça até o final da página e clique no botão <b>DEMITIR FUNCIONÁRIO</b>. Você será redirecionado para a tela de demissão.</p>
            <p><b>7º Passo:</b> Na tela de demissão, você verá os dados do contrato e os campos para informar a data de demissão e as observações. Após preencher os campos, clique no botão <b>SALVAR</b> para concluir o processo.</p>
            <p><b>Acesso rápido:</b> <a href="/funcionario" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de colaboradores</a>.</p>
          `
        },
        {
          question: "Como alterar o usuário/senha de um(a) colaborador(a)?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>RECURSOS HUMANOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>RECURSOS HUMANOS</b>, clique na seção <b>COLABORADORES</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>COLABORADORES</b>, será exibida uma tela com a listagem dos colaboradores cadastrados. No canto superior direito, clique no botão <b>CADASTRAR COLABORADOR</b>.</p>
            <p><b>4º Passo:</b> Faça uma busca pelo colaborador(a) que deseja alterar e clique no ícone de <b>lápis</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>5º Passo:</b> Avance pelas telas até chegar na última tela, onde você poderá alterar os dados do usuário. Após realizar as alterações, clique no botão <b>SALVAR</b> para concluir.</p>
            <p><b>Acesso rápido:</b> <a href="/funcionario" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de colaboradores</a>.</p>
          `
        }
      ]
    },
    {
      id: 2,
      modulo: "Produtos e Serviços",
      questions: [
        {
          question: "Como funciona a divisão dos grupos de produto/serviço?",
          answer: `
            <p>O cadastro de Produtos/Serviços no nosso sistema é dividido em três níveis principais: <b>Grupo de Produtos, Subgrupo de Produtos e Produto/Serviço</b>. Essa estrutura hierárquica tem como objetivo proporcionar uma organização eficiente, facilitando a gestão e a consulta dos produtos cadastrados.</p>
            <p><b>Grupo de Produtos</b>: Esse nível é o mais amplo e serve para agrupar os produtos de acordo com suas características gerais. Por exemplo, um grupo pode ser "Eletrônicos", "Vestuário", "Alimentos", etc. Esse agrupamento inicial permite uma visão macro dos produtos e facilita a categorização no sistema.</p>
            <p><b>Subgrupo de Produtos</b>: Dentro de cada grupo, criamos os subgrupos, que são uma subdivisão mais específica. Eles ajudam a refinar ainda mais a categorização, tornando a busca por produtos mais direcionada. Por exemplo, dentro do grupo "Eletrônicos", podemos ter subgrupos como "Celulares", "Computadores", "Aparelhos de TV", etc. Essa segmentação aprimora a organização e torna a navegação mais intuitiva.</p>
            <p><b>Produto</b>: O nível final é o próprio produto, que é o item específico a ser cadastrado no sistema. Cada produto possui informações detalhadas, como nome, preço, descrição, código de barras, e outras características essenciais para sua identificação e venda. Esse nível é crucial para a gestão de inventário, controle de estoque e registro das transações comerciais.</p>
            <p>Essa divisão em três níveis tem como principal benefício a <b>organização eficiente</b> e a <b>facilidade de gerenciamento</b>, permitindo aos usuários do sistema localizar, editar e controlar os produtos de maneira rápida e eficaz. A hierarquia também permite uma <b>melhor análise e relatórios</b> sobre o desempenho de diferentes categorias de produtos, ajudando na tomada de decisões estratégicas para o negócio.</p>
          `
        },
        {
          question: "Como cadastrar um grupo de produto/serviço?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>PRODUTOS E SERVIÇOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>PRODUTOS E SERVIÇOS</b>, clique na seção <b>GRUPO DE PRODUTOS</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>GRUPO DE PRODUTOS</b>, será exibida uma tela com a listagem dos grupos cadastrados. No canto superior direito, clique no botão <b>CADASTRAR GRUPO DE PRODUTOS</b>.</p>
            <p><b>4º Passo:</b> Após clicar em <b>CADASTRAR GRUPO DE PRODUTOS</b>, você será redirecionado para a tela de cadastro. Nesta tela, informe a descrição do grupo, defina sua visibilidade e selecione se ele estará ativo ou inativo.</p>
            <p><b>5º Passo:</b> Após preencher todas as informações, clique no botão <b>SALVAR</b> para concluir o cadastro.</p>
            <p><b>Acesso rápido:</b> <a href="/grupo_produtos/cadastro" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de cadastro</a>.</p>
          `
        },
        {
          question: "Como cadastrar um sub-grupo de produto/serviço?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>PRODUTOS E SERVIÇOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>PRODUTOS E SERVIÇOS</b>, clique na seção <b>SUBGRUPO DE PRODUTOS</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>SUBGRUPO DE PRODUTOS</b>, será exibida uma tela com a listagem dos subgrupos cadastrados. No canto superior direito, clique no botão <b>CADASTRAR SUBGRUPO DE PRODUTOS</b>.</p>
            <p><b>4º Passo:</b> Após clicar em <b>CADASTRAR SUBGRUPO DE PRODUTOS</b>, você será redirecionado para a tela de cadastro. Nesta tela, selecione o grupo ao qual esse subgrupo pertence, informe a descrição do subgrupo e defina se ele estará ativo ou inativo.</p>
            <p><b>5º Passo:</b> Após preencher todas as informações, clique no botão <b>SALVAR</b> para concluir o cadastro.</p>
            <p><b>Acesso rápido:</b> <a href="/sub_grupo_produtos/cadastro" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de cadastro</a>.</p>
          `
        },
        {
          question: "Como cadastrar um produto/serviço?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>PRODUTOS E SERVIÇOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>PRODUTOS E SERVIÇOS</b>, clique na seção <b>PRODUTOS E SERVIÇOS</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>PRODUTOS E SERVIÇOS</b>, será exibida uma tela com a listagem dos produtos/serviços cadastrados. No canto superior direito, clique no botão <b>CADASTRAR PRODUTO/SERVIÇO</b>.</p>
            <p><b>4º Passo:</b> Após clicar em <b>CADASTRAR PRODUTO/SERVIÇO</b>, você será redirecionado para a tela de cadastro.</p>
            <p>Nessa tela, informe todos os dados do produto/serviço, como o grupo e subgrupo ao qual pertence, a descrição, a unidade de medida, a visibilidade e o tipo (produto ou serviço).</p>
            <p>Se o tipo selecionado for <b>Serviço</b>, será possível definir a duração do serviço, entre outras configurações específicas.</p>
            <p>Mais abaixo, você poderá configurar os centros de custo/lucro do produto/serviço. Esses centros serão utilizados para vincular o produto/serviço ao plano de contas e serão obrigatórios para operações de vendas e compras.</p>
            <p><b>Acesso rápido:</b> <a href="/produtos/cadastro" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de cadastro</a>.</p>
          `
        },
        {
          question: "Como cadastrar uma tabela de preço?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>PRODUTOS E SERVIÇOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>PRODUTOS E SERVIÇOS</b>, clique na seção <b>TABELA DE PREÇOS</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>TABELA DE PREÇOS</b>, será exibida uma tela com a listagem das tabelas de preços cadastradas. No canto superior direito, clique no botão <b>CADASTRAR TABELA</b>.</p>
            <p><b>4º Passo:</b> Na tela de cadastro, informe a descrição da tabela, a data inicial e final de vigência e se ela será a tabela padrão. A tabela padrão define qual tabela principal será utilizada pelo sistema quando a forma de pagamento não for um voucher configurado com outra tabela.</p>
            <p><b>5º Passo:</b> Em seguida, adicione os produtos/serviços à tabela, informando o preço mínimo e máximo de cada item. Para incluir um produto/serviço na listagem, clique no botão laranja <b>ADICIONAR</b>.</p>
            <p><b>6º Passo:</b> Após concluir o cadastro, clique no botão <b>SALVAR</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/tabela_preco/cadastro" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de cadastro</a>.</p>
          `
        },
        {
          question: "Como criar uma configuração de voucher?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>PRODUTOS E SERVIÇOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>PRODUTOS E SERVIÇOS</b>, clique na seção <b>CONFIGURAÇÃO DE VOUCHER</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>CONFIGURAÇÃO DE VOUCHER</b>, será exibida uma tela com a listagem das configurações cadastradas. No canto superior direito, clique no botão <b>CADASTRAR CONFIGURAÇÃO</b>.</p>
            <p><b>4º Passo:</b> Ao clicar no botão <b>CADASTRAR CONFIGURAÇÃO</b>, será exibida uma tela para o cadastro da configuração. Nessa tela, você vai informar o nome da configuração, o tipo (Produto ou Crédito), a validade do voucher (Data Fixa ou Quantidade de Dias), a tabela de preços para as validades promocional e normal e, por último, o item de receita das contas de receita que serão geradas na venda do voucher.</p>
            <p><b>5º Passo:</b> Após preencher todos os campos, clique no botão <b>SALVAR</b> para concluir o cadastro.</p>
            <p><b>Acesso rápido:</b> <a href="/config_voucher" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de configuração de voucher</a>.</p>
          `
        },
        {
          question: "Como realizar a venda de um voucher?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>PRODUTOS E SERVIÇOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>PRODUTOS E SERVIÇOS</b>, clique na seção <b>VENDA DE VOUCHER</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>VENDA DE VOUCHER</b>, será exibida uma tela com a listagem das vendas de voucher. No canto superior direito, clique no botão <b>VENDER VOUCHER</b>.</p>
            <p><b>4º Passo:</b> Ao clicar no botão <b>VENDER VOUCHER</b>, você acessará a tela de venda de voucher, onde deverá informar o cliente comprador do voucher. Em seguida, selecione o tipo de voucher e a configuração correspondente. Se a configuração for um voucher de crédito, informe o valor do voucher. Se for um voucher de produto, informe os produtos que fazem parte do voucher.</p>
            <p><b>5º Passo:</b> Depois, informe os dependentes (presenteados) do voucher, adicionando o cliente e clicando para incluí-lo na listagem.</p>
            <p><b>6º Passo:</b> Por fim, preencha os dados de pagamento do voucher. Após informar os dados, clique no botão <b>SALVAR</b> para concluir a venda.</p>
            <p><b>Acesso rápido:</b> <a href="/venda_voucher" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem dos voucher</a>.</p>
          `
        },
        {
          question: "Como cancelar um voucher?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>PRODUTOS E SERVIÇOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>PRODUTOS E SERVIÇOS</b>, clique na seção <b>VENDA DE VOUCHER</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>VENDA DE VOUCHER</b>, será exibida uma tela com a listagem das vendas de voucher. Faça a busca pelo voucher que deseja cancelar e clique no ícone de <b>olho</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de visualização do voucher, onde terá acesso a todos os dados do voucher. Role a tela até o final e clique no botão laranja <b>CANCELAR VOUCHER</b>.</p>
            <p><b>5º Passo:</b> Após clicar no botão, confirme se deseja realizar o cancelamento clicando em <b>SIM</b>. Caso não queira prosseguir, clique em <b>NÃO</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/venda_voucher" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem dos vouchers</a>.</p>
          `
        },
        {
          question: "Como alterar o comprador de um voucher?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>PRODUTOS E SERVIÇOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>PRODUTOS E SERVIÇOS</b>, clique na seção <b>VENDA DE VOUCHER</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>VENDA DE VOUCHER</b>, será exibida uma tela com a listagem das vendas de vouchers. Faça a busca pelo voucher que deseja alterar e clique no ícone de <b>olho</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de visualização do voucher, onde terá acesso a todos os dados. Role a tela até o final e clique no botão azul claro <b>ALTERAR COMPRADOR</b>.</p>
            <p><b>5º Passo:</b> Após clicar no botão, uma janela será aberta para que o novo comprador seja informado. Após preencher, clique no botão <b>CONFIRMAR</b> para finalizar a alteração.</p>
            <p><b>Acesso rápido:</b> <a href="/venda_voucher" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem dos vouchers</a>.</p>
          `
        },
        {
          question: "Como alterar os vencimentos de um voucher?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>PRODUTOS E SERVIÇOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>PRODUTOS E SERVIÇOS</b>, clique na seção <b>VENDA DE VOUCHER</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>VENDA DE VOUCHER</b>, será exibida uma tela com a listagem das vendas de vouchers. Faça a busca pelo voucher que deseja alterar e clique no ícone de <b>olho</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de visualização do voucher, onde terá acesso a todos os dados. Role a tela até o final e clique no botão azul escuro <b>ALTERAR VENCIMENTOS</b>.</p>
            <p><b>5º Passo:</b> Após clicar no botão, uma janela será aberta para que sejam informados os novos vencimentos. Nessa tela, será exibido o vencimento atual e os campos para alteração do novo vencimento. Caso deseje registrar um lançamento financeiro, clique no botão <b>Lançar Financeiro?</b> e marque a opção <b>Sim</b>. Ao selecionar essa opção, será possível preencher os detalhes financeiros logo abaixo.</p>
            <p><b>6º Passo:</b> Após preencher os campos, clique no botão <b>CONFIRMAR</b> para finalizar a alteração dos vencimentos.</p>
            <p><b>Acesso rápido:</b> <a href="/venda_voucher" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem dos vouchers</a>.</p>
          `
        },
        {
          question: "Como incluir novos dependetes(presenteados) no voucher?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>PRODUTOS E SERVIÇOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>PRODUTOS E SERVIÇOS</b>, clique na seção <b>VENDA DE VOUCHER</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>VENDA DE VOUCHER</b>, será exibida uma tela com a listagem das vendas de vouchers. Faça a busca pelo voucher que deseja alterar e clique no ícone de <b>olho</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de visualização do voucher, onde terá acesso a todos os dados e poderá visualizar os presenteados do voucher.</p>
            <p><b>5º Passo:</b> Informe o novo dependente (presenteado) no campo <b>Novo Dependente</b>. Após selecionar o cliente, clique no botão <b>ADICIONAR</b>, e o novo dependente (presenteado) será adicionado ao voucher.</p>
            <p><b>Acesso rápido:</b> <a href="/venda_voucher" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem dos vouchers</a>.</p>
          `
        },
        {
          question: "Como incluir novos produtos ao voucher de produto?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>PRODUTOS E SERVIÇOS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>PRODUTOS E SERVIÇOS</b>, clique na seção <b>VENDA DE VOUCHER</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>VENDA DE VOUCHER</b>, será exibida uma tela com a listagem das vendas de vouchers. Faça a busca pelo voucher que deseja alterar e clique no ícone de <b>olho</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de visualização do voucher, onde terá acesso a todos os dados e poderá visualizar os produtos do voucher.</p>
            <p><b>5º Passo:</b> Na seção <b>USO DOS PRODUTOS</b>, você poderá adicionar novos produtos ao voucher, selecionando o produto/serviço e seu valor. Após isso, clique no botão <b>ADICIONAR SERVIÇO</b>.</p>
            <p><b>6º Passo:</b> Ao finalizar, role a tela para baixo e clique no botão <b>SALVAR VOUCHER</b>. Se necessário, o sistema solicitará o lançamento financeiro dos produtos/serviços inseridos no voucher. Após preencher as informações financeiras, clique no botão <b>SALVAR</b>, e os produtos/serviços serão adicionados ao voucher.</p>
            <p><b>Acesso rápido:</b> <a href="/venda_voucher" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem dos vouchers</a>.</p>
          `
        }
      ]
    },
    {
      id: 3,
      modulo: "Cliente e Fornecedores",
      questions: [
        {
          question: "Como cadastrar um novo cliente/fornecedor?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>CLIENTES E FORNECEDORES</b>, clique na seção <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>CLIENTES E FORNECEDORES</b>, será exibida uma tela com a listagem dos clientes/fornecedores. No canto superior direito, clique no botão <b>CADASTRAR</b>.</p>
            <p><b>4º Passo:</b> Na tela de cadastro, informe primeiramente o tipo de cadastro (CLIENTE, FORNECEDOR(A) ou REVENDEDOR(A)), podendo escolher mais de um.</p>
            <p><b>5º Passo:</b> Em seguida, informe os dados pessoais, selecionando o tipo de pessoa (FÍSICA ou JURÍDICA). Os dados de endereço, contas e e-mails não são obrigatórios.</p>
            <p><b>6º Passo:</b> Ao informar os dados pessoais, você pode ou não criar um contrato para esse cliente, clicando no botão <b>INFORMAR CONTRATO</b>. Caso não queira, clique em <b>SALVAR</b> para finalizar o cadastro.</p>
            <p><b>7º Passo:</b> Caso opte por informar o contrato, a próxima tela será onde você informará os dados do contrato, incluindo o tempo de duração e os produtos/serviços do contrato, selecionando entre parcelamento ou recorrência.</p>
            <p><b>8º Passo:</b> Após selecionar os produtos/serviços, clique no botão <b>ADICIONAR</b> para incluí-los na listagem. Ao finalizar, você terá a opção de informar um responsável financeiro para esse cliente. Caso o cliente seja menor de idade, essa informação será obrigatória.</p>
            <p><b>9º Passo:</b> Após finalizar o preenchimento do contrato, clique em <b>SALVAR</b> para concluir o processo.</p>
            <p><b>Acesso rápido:</b> <a href="/cliente/cadastro" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de cadastro</a>.</p>
          `
        },
        {
          question: "Como alterar um cliente/fornecedor?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>CLIENTES E FORNECEDORES</b>, clique na seção <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>CLIENTES E FORNECEDORES</b>, será exibida uma tela com a listagem dos clientes/fornecedores. Faça a busca pelo cliente/fornecedor que deseja alterar e clique no ícone de <b>Lápis</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>4º Passo:</b> Ao clicar no botão de edição, você será redirecionado para a tela de edição do cliente. Para editar um endereço, telefone ou e-mail, clique no ícone de <b>Lápis</b> na respectiva coluna <b>AÇÕES</b> e depois clique no botão <b>ALTERAR</b> para confirmar a alteração.</p>
            <p><b>5º Passo:</b> Para finalizar a alteração, clique no botão <b>SALVAR</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/cliente" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como visualizar o contrato de um cliente?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>CLIENTES E FORNECEDORES</b>, clique na seção <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>CLIENTES E FORNECEDORES</b>, será exibida uma tela com a listagem dos clientes/fornecedores. Faça a busca pelo cliente que deseja visualizar o contrato e clique no ícone de <b>Olho</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>4º Passo:</b> Na tela de visualização do cliente, na aba de navegação superior, clique na opção <b>CONTRATOS</b>.</p>
            <p><b>5º Passo:</b> Nessa tela, você verá uma lista com os contratos do cliente. Para visualizar um contrato específico, clique no ícone de <b>Olho</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/cliente" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como cancelar o contrato de um cliente?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>CLIENTES E FORNECEDORES</b>, clique na seção <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>CLIENTES E FORNECEDORES</b>, será exibida uma tela com a listagem dos clientes/fornecedores. Faça a busca pelo cliente cujo contrato deseja cancelar e clique no ícone de <b>Olho</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>4º Passo:</b> Na tela de visualização do cliente, na aba de navegação superior, clique na opção <b>CONTRATOS</b>.</p>
            <p><b>5º Passo:</b> Nessa tela, você verá uma lista com os contratos do cliente. Clique no ícone de <b>Olho</b> na coluna <b>AÇÕES</b> do contrato que deseja cancelar.</p>
            <p><b>6º Passo:</b> Na tela de detalhes do contrato, role até o final e clique no botão <b>CANCELAR CONTRATO</b>. Ao clicar, uma janela será exibida mostrando os financeiros pendentes e abertos. Abaixo, haverá um botão para definir se deseja lançar um financeiro de conciliação ou não.</p>
            <p><b>7º Passo:</b> Caso opte por lançar um financeiro de conciliação, informe os dados de pagamento, como item de receita, descrição, valor e parcelamentos.</p>
            <p><b>8º Passo:</b> Para finalizar, informe o motivo do cancelamento e clique no botão <b>SIM</b>. Caso deseje voltar, clique no botão <b>NÃO</b> para retornar à tela do contrato.</p>
            <p><b>Acesso rápido:</b> <a href="/cliente" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como renovar um contrato de um cliente?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>CLIENTES E FORNECEDORES</b>, clique na seção <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>CLIENTES E FORNECEDORES</b>, será exibida uma tela com a listagem dos clientes/fornecedores. Faça a busca pelo cliente cujo contrato deseja renovar e clique no ícone de <b>Olho</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>4º Passo:</b> Na tela de visualização do cliente, na aba de navegação superior, clique na opção <b>CONTRATOS</b>.</p>
            <p><b>5º Passo:</b> Nessa tela, você verá uma lista com os contratos do cliente. Clique no ícone de <b>Olho</b> na coluna <b>AÇÕES</b> do contrato que deseja renovar.</p>
            <p><b>6º Passo:</b> Na tela de detalhes do contrato, role até o final e clique no botão <b>RENOVAR CONTRATO</b>. Ao clicar, uma janela será exibida, onde você poderá informar o acréscimo do contrato e adicionar novos serviços clicando no botão <b>INFORMAR SERVIÇO</b>. Após preencher os detalhes do serviço, clique no botão <b>ADICIONAR</b> para incluí-lo na lista de serviços.</p>
            <p><b>7º Passo:</b> Após preencher as informações, clique no botão <b>CONFIRMAR</b> para finalizar a renovação. Caso deseje voltar e desfazer a renovação, clique no botão <b>VOLTAR</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/cliente" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como adicionar um novo serviço a um contrato de um cliente?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>CLIENTES E FORNECEDORES</b>, clique na seção <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>CLIENTES E FORNECEDORES</b>, será exibida uma tela com a listagem dos clientes/fornecedores. Faça a busca pelo cliente cujo contrato deseja adicionar um serviço e clique no ícone de <b>Olho</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>4º Passo:</b> Na tela de visualização do cliente, na aba de navegação superior, clique na opção <b>CONTRATOS</b>.</p>
            <p><b>5º Passo:</b> Nessa tela, você verá uma lista com os contratos do cliente. Clique no ícone de <b>Olho</b> na coluna <b>AÇÕES</b> do contrato ao qual deseja adicionar o serviço.</p>
            <p><b>6º Passo:</b> Na tela de detalhes do contrato, role até o final e clique no botão <b>ADICIONAR SERVIÇO</b>. Ao clicar, uma janela será exibida, onde você poderá informar os detalhes do novo serviço. Após preencher todas as informações, clique no botão <b>ADICIONAR</b> para concluir a adição do serviço ao contrato.</p>
            <p><b>Acesso rápido:</b> <a href="/cliente" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como adicionar um novo contrato a um cliente já cadastrado?",
          answer:`
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>CLIENTES E FORNECEDORES</b>, clique na seção <b>CLIENTES E FORNECEDORES</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>CLIENTES E FORNECEDORES</b>, será exibida uma tela com a listagem dos clientes/fornecedores. Faça a busca pelo cliente ao qual deseja adicionar um contrato e clique no ícone de <b>Olho</b> na coluna <b>AÇÕES</b>.</p>
            <p><b>4º Passo:</b> Na tela de visualização do cliente, na aba de navegação superior, clique na opção <b>CONTRATOS</b>.</p>
            <p><b>5º Passo:</b> Nessa tela, você verá uma lista com os contratos do cliente. Clique no botão <b>ADICIONAR CONTRATO</b>.</p>
            <p><b>6º Passo:</b> Ao clicar no botão, você será redirecionado para uma tela onde deverá informar todos os dados do contrato, como o tempo de vigência, configuração de multas, pontualidade e indicação.</p>
            <p><b>7º Passo:</b> Para informar os serviços do contrato, clique em <b>PARCELAMENTO</b> ou em <b>RECORRÊNCIA</b> e, em seguida, informe os dados do serviço, valores e forma de pagamento. Para adicionar o serviço, clique em <b>ADICIONAR</b>.</p>
            <p><b>8º Passo:</b> Para finalizar a adição do contrato, clique no botão <b>CONFIRMAR</b> no final da tela.</p>
            <p><b>Acesso rápido:</b> <a href="/cliente" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        }
      ]
    },
    {
      id: 4,
      modulo: "Financeiro",
      questions: [
        {
          question: "Como cadastrar um novo plano de contas?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>PLANOS DE CONTAS</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>PLANOS DE CONTAS</b>, será exibida uma tela com a listagem dos planos de contas, que é dividida em quatro grupos: <b>CONTA, CATEGORIA, SUBCATEGORIA e CENTRO DE CUSTO</b>. Para cadastrar um novo plano de contas, clique no botão <b>CADASTRAR PLANO DE CONTAS</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de cadastro do plano de contas. Aqui, você deverá selecionar o nível do plano de contas, informar o nome, definir o tipo (se é um plano de receita ou despesa) e indicar se ele é um totalizador*.</p>
            <p><b>5º Passo:</b> Mais abaixo, você pode informar os centros de custo que poderão ser utilizados por esse plano de contas, selecionando o departamento, o centro de custo e informando a porcentagem do rateio. Para adicionar o centro de custo, clique no botão <b>ADICIONAR</b>.</p>
            <p><b>6º Passo:</b> Para finalizar o cadastro, clique no botão <b>SALVAR</b> no final da tela.</p>
            <p><b>Obs*:</b> O Totalizador significa que esse plano de contas conterá outros níveis de planos abaixo dele e que ele somará os valores de todos os níveis abaixo.</p>
            <p><b>Acesso rápido:</b> <a href="/plano_contas/cadastro" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de cadastro</a>.</p>
          `
        },
        {
          question: "Como editar um plano de contas?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>PLANOS DE CONTAS</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>PLANOS DE CONTAS</b>, será exibida uma tela com a listagem dos planos de contas, dividida em quatro grupos: <b>CONTA, CATEGORIA, SUBCATEGORIA e CENTRO DE CUSTO</b>. Para editar um plano de contas, busque pelo plano desejado, navegando pelos níveis inferiores até encontrá-lo.</p>
            <p><b>4º Passo:</b> Ao localizar o plano que deseja alterar, clique nele e depois clique no ícone do <b>Lápis</b> localizado acima do título do grupo ao qual esse plano pertence.</p>
            <p><b>5º Passo:</b> Você será redirecionado para a tela de edição do plano de contas, onde poderá alterar o nome do plano, o tipo (se é um plano de receita ou despesa), se ele é um totalizador* ou não, e se está ativo ou inativo.</p>
            <p><b>6º Passo:</b> Mais abaixo, você pode alterar a relação de centros de custos do plano de contas, inserindo novos centros de custos ou removendo os já inseridos.</p>
            <p><b>7º Passo:</b> Para finalizar a alteração do plano de contas, clique no botão <b>SALVAR</b>.</p>
            <p><b>Obs*:</b> O Totalizador significa que esse plano de contas conterá outros níveis de planos abaixo dele e somará os valores de todos os níveis inferiores.</p>
            <p><b>Acesso rápido:</b> <a href="/plano_contas" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como cadastrar um item de receita/despesa?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>ITEM DE RECEITA E DESPESA</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>ITEM DE RECEITA E DESPESA</b>, será exibida uma tela com a listagem dos itens. Para cadastrar um novo item, clique no botão <b>CADASTRAR ITEM</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de cadastro, onde deverá informar o tipo do item (Receita ou Despesa), o plano de contas ao qual esse item pertence, a descrição dele, e se ele está ativo ou inativo.</p>
            <p><b>5º Passo:</b> Para finalizar o cadastro, clique no botão <b>SALVAR</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/item_receita_despesa" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como alterar um item de receita/despesa?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>ITEM DE RECEITA E DESPESA</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>ITEM DE RECEITA E DESPESA</b>, será exibida uma tela com a listagem dos itens. Faça uma busca pelo item que deseja alterar.</p>
            <p><b>4º Passo:</b> Após localizar o item que deseja alterar, clique no ícone do <b>Lápis</b> na coluna <b>AÇÕES</b>. Você será redirecionado para uma tela onde poderá alterar os dados do item de receita ou despesa selecionado.</p>
            <p><b>Acesso rápido:</b> <a href="/item_receita_despesa" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como remover um item de receita/despesa?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>ITEM DE RECEITA E DESPESA</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>ITEM DE RECEITA E DESPESA</b>, será exibida uma tela com a listagem dos itens. Faça uma busca pelo item que deseja remover.</p>
            <p><b>4º Passo:</b> Após localizar o item que deseja remover, clique no ícone de <b>Lixeira</b> na coluna <b>AÇÕES</b>. Você só poderá remover um item que ainda não tenha sido utilizado. Caso o item já tenha sido utilizado, você pode inativá-lo clicando no botão <b>Lápis</b> para editar o item e inativá-lo.</p>
            <p><b>Acesso rápido:</b> <a href="/item_receita_despesa" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como cadastrar uma conta bancária?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>CONTAS BANCÁRIAS</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>CONTAS BANCÁRIAS</b>, será exibida uma tela com a listagem das suas contas bancárias. Para cadastrar uma nova conta bancária, clique no botão <b>CADASTRAR CONTA</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de cadastro de contas. Nessa tela, haverá uma aba de navegação na parte superior com três opções: <b>DADOS DA CONTA</b>, <b>DADOS BANCÁRIOS</b> e <b>EMISSÃO DE BOLETO</b>.</p>
            <p><b>5º Passo:</b> Na aba <b>DADOS DA CONTA</b>, informe os dados como a descrição da conta, o saldo e a data do saldo inicial, o tipo da conta (BANCO ou COFRE) e se ela está ativa.</p>
            <p><b>6º Passo:</b> Caso esteja cadastrando uma conta do tipo <b>BANCO</b>, será necessário preencher os <b>DADOS BANCÁRIOS</b>, selecionando o banco e informando os dados da conta, como o número da agência e o número da conta.</p>
            <p><b>7º Passo:</b> Se a conta bancária for de um parceiro do Mais Financeiro, acesse a aba <b>EMISSÃO DE BOLETO</b>. Nessa seção, informe o parceiro, as chaves de integração e as configurações de boleto.</p>
            <p><b>8º Passo:</b> Para finalizar o cadastro, role a tela até o final e clique no botão <b>SALVAR</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/conta_financeira/cadastro" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de cadastro</a>.</p>
          `
        },
        {
          question: "Como realizar uma conciliação bancária?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>CONCILIAÇÃO BANCÁRIA</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>CONCILIAÇÃO BANCÁRIA</b>, será exibida uma tela com a listagem das suas contas bancárias e seus saldos. Abaixo, haverá uma listagem das suas movimentações bancárias. Realize uma busca pela movimentação que deseja conciliar.</p>
            <p><b>4º Passo:</b> Após encontrar a movimentação que deseja conciliar, clique no botão em formato de círculo na coluna <b>AÇÕES</b>, na linha da movimentação. Assim, ela ficará selecionada.</p>
            <p><b>5º Passo:</b> Após selecionar todas as movimentações que deseja conciliar, role a tela para baixo e clique no botão verde <b>SALVAR CONCILIAÇÕES</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/movimentacao_financeira" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como criar uma movimentação bancária?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>MOVIMENTAÇÃO FINANCEIRA</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>MOVIMENTAÇÃO FINANCEIRA</b>, será exibida uma tela com a listagem das suas contas bancárias e seus saldos. Abaixo, haverá uma listagem das suas movimentações bancárias. Para cadastrar uma nova movimentação, clique no botão <b>CADASTRAR MOVIMENTAÇÃO</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de cadastro, onde deverá selecionar o tipo da movimentação, os dados das contas envolvidas, o valor e a data da movimentação.</p>
            <p><b>5º Passo:</b> Para finalizar o cadastro da movimentação, clique no botão <b>SALVAR</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/movimentacao_financeira" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como criar uma movimentação bancária?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>MOVIMENTAÇÃO FINANCEIRA</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>MOVIMENTAÇÃO FINANCEIRA</b>, será exibida uma tela com a listagem das suas contas bancárias e seus saldos. Abaixo, haverá uma listagem das suas movimentações bancárias. Para cadastrar uma nova movimentação, clique no botão <b>CADASTRAR MOVIMENTAÇÃO</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de cadastro, onde deverá selecionar o tipo da movimentação, os dados das contas envolvidas, o valor e a data da movimentação.</p>
            <p><b>5º Passo:</b> Para finalizar o cadastro da movimentação, clique no botão <b>SALVAR</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/movimentacao_financeira" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como lançar uma receita ou despesa?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>CONTAS A PAGAR/RECEBER</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>CONTAS A PAGAR/RECEBER</b>, será exibida uma tela com a listagem das suas receitas e despesas. Para cadastrar uma nova conta, clique no botão <b>CADASTRAR CONTA</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de cadastro, onde deverá selecionar o tipo da conta, o item de receita/despesa, a descrição, a data de emissão, o valor, o cliente e escolher entre as opções de parcelamento ou recorrência, informando as formas de pagamento.</p>
            <p><b>5º Passo:</b> Para informar o centro de custos dessa conta, clique na aba superior <b>CENTRO DE CUSTO</b>. Selecione o departamento e o centro de custo desejado e clique em <b>ADICIONAR</b> para incluí-lo na listagem.</p>
            <p><b>6º Passo:</b> Para finalizar o cadastro, você terá duas opções:</p>
            <ul>
              <li><b>Salvar:</b> Clique no botão azul <b>SALVAR</b> para manter a conta aberta.</li>
              <li><b>Salvar e Baixar:</b> Clique no botão verde <b>SALVAR E BAIXAR</b> para salvar e registrar o pagamento imediatamente.</li>
            </ul>
            <p><b>Acesso rápido:</b> <a href="/conta_pagar_receber/cadastro" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de cadastro</a>.</p>
          `
        },
        {
          question: "Como baixar uma receita ou despesa?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>CONTAS A PAGAR/RECEBER</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>CONTAS A PAGAR/RECEBER</b>, será exibida uma tela com a listagem das suas receitas e despesas. Aqui, você terá duas opções para realizar a baixa da conta.</p>
            
            <p><b>Opção 1: Baixa rápida</b></p>
            <p>Se não for necessário alterar o valor recebido/pago da conta, localize a coluna <b>BAIXAR CONTA</b> e selecione a conta que deseja dar baixa. Após a seleção, um botão verde <b>BAIXAR CONTAS</b> aparecerá.</p>
            <p>Clique nele e uma janela será exibida para confirmar a data e a forma de pagamento. Para finalizar a baixa, clique no botão <b>CONFIRMAR</b>. Caso deseje cancelar, clique em <b>CANCELAR</b>.</p>
            
            <p><b>Opção 2: Baixa com ajustes</b></p>
            <p>Se for necessário alterar dados da conta, como valor da parcela, multa, juros, data de vencimento ou pagamento, localize a conta desejada e clique no ícone de <b>Lápis</b> na coluna <b>AÇÕES</b>.</p>
            <p>Você será redirecionado para a tela de atualização da parcela. Após realizar as alterações:</p>
            <ul>
              <li><b>Para baixar a conta imediatamente:</b> clique no botão <b>BAIXAR</b>.</li>
              <li><b>Para apenas salvar as alterações sem baixar:</b> clique no botão <b>SALVAR SEM BAIXAR</b>.</li>
            </ul>
        
            <p><b>Acesso rápido:</b> <a href="/conta_pagar_receber" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como cancelar uma receita ou despesa?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>CONTAS A PAGAR/RECEBER</b>.</p>
            <p><b>3º Passo:</b> Na tela de <b>CONTAS A PAGAR/RECEBER</b>, será exibida a listagem das suas receitas e despesas. Utilize a busca para localizar a conta que deseja cancelar.</p>
            <p><b>4º Passo:</b> Após encontrar a conta desejada, clique no ícone de <b>Lápis</b> na coluna <b>AÇÕES</b> para acessar a tela de atualização da parcela.</p>
            <p><b>5º Passo:</b> Role até o final da tela e clique no botão <b>CANCELAR</b>.</p>
            <p><b>6º Passo:</b> Uma janela será aberta para que você informe o motivo do cancelamento. Caso a conta seja recorrente, selecione se deseja cancelar apenas esta parcela ou todas as recorrências futuras.</p>
            <p><b>7º Passo:</b> Para confirmar o cancelamento, clique no botão <b>SIM</b>. Caso não queira cancelar, clique em <b>NÃO</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/conta_pagar_receber" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como estornar uma receita ou despesa já baixada?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>FINANCEIRO</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>FINANCEIRO</b>, clique na seção <b>CONTAS A PAGAR/RECEBER</b>.</p>
            <p><b>3º Passo:</b> Na tela de <b>CONTAS A PAGAR/RECEBER</b>, será exibida a listagem das suas receitas e despesas. Utilize a busca para localizar a conta que deseja estornar.</p>
            <p><b>4º Passo:</b> Após encontrar a conta desejada, clique no ícone de <b>Lápis</b> na coluna <b>AÇÕES</b> para acessar a tela de edição da parcela.</p>
            <p><b>5º Passo:</b> Role até o final da tela e clique no botão <b>ESTORNAR</b>.</p>
            <p><b>6º Passo:</b> Uma janela será aberta para que você informe o motivo do estorno.</p>
            <p><b>7º Passo:</b> Para confirmar o estorno, clique no botão <b>SIM</b>. Caso não queira estornar, clique em <b>NÃO</b>.</p>
            <p><b>Observação:</b> Após o estorno, uma nova parcela será gerada como pendente, permitindo que você realize uma nova baixa com as correções necessárias.</p>
            <p><b>Acesso rápido:</b> <a href="/conta_pagar_receber" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        }
      ]
    },
    {
      id: 5,
      modulo: "Vendas e Compras",
      questions: [
        {
          question: "Como cadastrar uma venda de serviço/produto?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>VENDAS E COMPRAS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>VENDAS E COMPRAS</b>, clique na seção <b>VENDAS</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>VENDAS</b>, será exibida uma tela com a listagem de todas as vendas. Para cadastrar uma nova venda, clique em <b>CADASTRAR VENDA</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de cadastro da venda, onde deverá informar o cliente e a data da venda.</p>
            <p><b>5º Passo:</b> Em seguida, adicione os produtos/serviços da venda, informando a quantidade, o valor, eventuais descontos ou acréscimos. Para incluir o item na listagem, clique no botão <b>ADICIONAR</b>.</p>
            <p><b>6º Passo:</b> Depois, selecione a forma de recebimento da venda, o número de parcelas, os valores e a forma de pagamento.</p>
            <p><b>7º Passo:</b> Para finalizar a venda, você tem duas opções:</p>
            <ul>
              <li>Para lançar a venda já baixada, clique em <b>SALVAR E BAIXAR</b>.</li>
              <li>Para lançar a venda com o financeiro pendente, clique em <b>SALVAR</b>.</li>
            </ul>
            <p><b>Acesso rápido:</b> <a href="/vendas/cadastro" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de cadastro</a>.</p>
          `
        },
        {
          question: "Como cancelar uma venda de serviço/produto?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>VENDAS E COMPRAS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>VENDAS E COMPRAS</b>, clique na seção <b>VENDAS</b>.</p>
            <p><b>3º Passo:</b> Na tela de <b>VENDAS</b>, será exibida a listagem de todas as vendas. Utilize a busca para localizar a venda que deseja cancelar.</p>
            <p><b>4º Passo:</b> Após encontrar a venda desejada, clique no ícone de <b>Olho</b> na coluna <b>AÇÕES</b> para acessar a tela de visualização da venda.</p>
            <p><b>5º Passo:</b> Role até o final da tela e clique no botão <b>CANCELAR VENDA</b>. Uma janela será aberta para que você informe o motivo do cancelamento. Caso a venda já tenha um financeiro baixado, será necessário registrar um lançamento de devolução do valor pago pelo cliente.</p>
            <p><b>6º Passo:</b> Para concluir o cancelamento, clique no botão <b>CONTINUAR</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/vendas" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como cadastrar uma compra de serviço/produto?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>VENDAS E COMPRAS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>VENDAS E COMPRAS</b>, clique na seção <b>COMPRAS</b>.</p>
            <p><b>3º Passo:</b> Ao acessar a seção <b>COMPRAS</b>, será exibida uma tela com a listagem de todas as compras. Para cadastrar uma nova compra, clique em <b>CADASTRAR COMPRA</b>.</p>
            <p><b>4º Passo:</b> Você será redirecionado para a tela de cadastro da compra, onde deverá informar o cliente e a data da compra.</p>
            <p><b>5º Passo:</b> Em seguida, adicione os produtos/serviços da compra, informando a quantidade, o valor, eventuais descontos ou acréscimos. Para incluir o item na listagem, clique no botão <b>ADICIONAR</b>.</p>
            <p><b>6º Passo:</b> Depois, selecione a forma de pagamento da compra, o número de parcelas, os valores e a forma de pagamento.</p>
            <p><b>7º Passo:</b> Para finalizar a compra, você tem duas opções:</p>
            <ul>
              <li>Para lançar a compra já baixada, clique em <b>SALVAR E BAIXAR</b>.</li>
              <li>Para lançar a compra com o financeiro pendente, clique em <b>SALVAR</b>.</li>
            </ul>
            <p><b>Acesso rápido:</b> <a href="/compras/cadastro" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de cadastro</a>.</p>
          `
        },
        {
          question: "Como cancelar uma compra de serviço/produto?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>VENDAS E COMPRAS</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>VENDAS E COMPRAS</b>, clique na seção <b>COMPRAS</b>.</p>
            <p><b>3º Passo:</b> Na tela de <b>COMPRAS</b>, será exibida a listagem de todas as compras. Utilize a busca para localizar a compra que deseja cancelar.</p>
            <p><b>4º Passo:</b> Após encontrar a compra desejada, clique no ícone de <b>Olho</b> na coluna <b>AÇÕES</b> para acessar a tela de visualização da compra.</p>
            <p><b>5º Passo:</b> Role até o final da tela e clique no botão <b>CANCELAR COMPRA</b>. Uma janela será aberta para que você informe o motivo do cancelamento. Caso a compra já tenha um financeiro baixado, será necessário registrar um lançamento de devolução do valor pago pelo cliente.</p>
            <p><b>6º Passo:</b> Para concluir o cancelamento, clique no botão <b>CONTINUAR</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/compras" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
      ]
    },
    {
      id:6,
      modulo: "Agenda",
      questions: [
        {
          question: "Como cadastrar um ambiente/sala na agenda?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>AGENDA</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>AGENDA</b>, clique na seção <b>AMBIENTES</b>.</p>
            <p><b>3º Passo:</b> Na tela de <b>AMBIENTES</b>, será exibida a listagem de todos os ambientes/salas. Clique no botão <b>CADASTRAR AMBIENTE</b>.</p>
            <p><b>4º Passo:</b> Uma janela será aberta para que você informe o nome da sala, a ordem em que ela aparecerá na agenda, seu status (ativa ou inativa) e uma descrição.</p>
            <p><b>5º Passo:</b> Para finalizar o cadastro, clique no botão <b>SALVAR</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/ambiente" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como alterar um ambiente/sala?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>AGENDA</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>AGENDA</b>, clique na seção <b>AMBIENTES</b>.</p>
            <p><b>3º Passo:</b> Na tela de <b>AMBIENTES</b>, será exibida a listagem de todos os ambientes/salas. Utilize a busca para localizar o ambiente/sala que deseja alterar.</p>
            <p><b>4º Passo:</b> Clique no ícone de <b>Lápis</b> na coluna <b>AÇÕES</b>. Uma janela será aberta com os dados do ambiente/sala para edição.</p>
            <p><b>5º Passo:</b> Faça as alterações desejadas e clique em <b>SALVAR</b> para finalizar.</p>
            <p><b>Acesso rápido:</b> <a href="/ambientes" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como criar um agendamento?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>AGENDA</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>AGENDA</b>, clique na seção <b>AGENDA DE AMBIENTES</b>.</p>
            <p><b>3º Passo:</b> Na tela de <b>AGENDA DE AMBIENTES</b>, será exibida uma tabela com todos os ambientes/salas e os intervalos de horários. Para agendar um novo atendimento, clique no botão <b>AGENDAR SERVIÇO</b>.</p>
            <p><b>4º Passo:</b> Uma janela será aberta, onde você deverá informar o cliente que será atendido. Digite o nome do cliente e, quando ele aparecer na lista, clique sobre o nome dele(a).</p>
            <p><b>5º Passo:</b> Em seguida, informe o serviço que será realizado, a data e hora do atendimento, o valor do serviço, a situação do atendimento e se esse atendimento requer preparo ou limpeza. Para selecionar o local de atendimento e o atendente, clique no botão <b>BUSCAR SALA/ATEND.</b> e escolha as opções desejadas.</p>
            <p><b>6º Passo:</b> Para adicionar o serviço à lista de atendimentos, clique no botão laranja <b>ADICIONAR</b>. Assim, você poderá incluir vários atendimentos para esse cliente.</p>
            <p><b>7º Passo:</b> Para adicionar um lançamento financeiro ao agendamento, clique na aba superior <b>FORMA DE PAGAMENTO</b>, marque o campo "Informar Lançamento Financeiro?" e preencha a forma de pagamento do atendimento.</p>
            <p><b>8º Passo:</b> Para finalizar o agendamento, clique no botão <b>SALVAR</b> e o agendamento será registrado.</p>
            <p><b>Acesso rápido:</b> <a href="/agenda_ambiente" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        },
        {
          question: "Como alterar um agendamento?",
          answer: `
            <p><b>1º Passo:</b> No menu principal, acesse o módulo <b>AGENDA</b>.</p>
            <p><b>2º Passo:</b> Dentro do módulo <b>AGENDA</b>, clique na seção <b>AGENDA DE AMBIENTES</b>.</p>
            <p><b>3º Passo:</b> Na tela de <b>AGENDA DE AMBIENTES</b>, será exibida uma tabela com todos os ambientes/salas e os intervalos de horários. Busque o agendamento que deseja alterar, vá até o horário e a sala onde ele está marcado e clique sobre o agendamento.</p>
            <p><b>4º Passo:</b> Uma janela será aberta, onde você poderá fazer as alterações necessárias no agendamento, como dados do serviço e informações de pagamento na aba <b>FORMA DE PAGAMENTO</b>.</p>
            <p><b>5º Passo:</b> Para finalizar a alteração do agendamento, clique no botão <b>SALVAR</b>.</p>
            <p><b>Acesso rápido:</b> <a href="/agenda_ambiente" style="color: #007bff; text-decoration: none; font-weight: bold;">Clique aqui para ir direto à tela de listagem</a>.</p>
          `
        }
      ]
    },
    // {
    //   id: 7,
    //   modulo: "Perfil de Usuário",
    //   questions: []
    // },
    // {
    //   id: 8,
    //   modulo: "Relatórios",
    //   questions: []
    // },
  ]
}

const ItemReceitaDespesa = (props) => {
  const [state, setState] = useState({ ...initialState });

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const selecionarModulo = (modulo) => {
    setState(prevState => ({...prevState,
      modulo_selecionado: modulo
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      
      setState(prevState => ({...prevState,
        loading: false
      }))
    };
    
    fetchData();
  }, []);

  const { faqData, modulo_selecionado } = state

  return (
    <React.Fragment>
      {!state.loading &&
        <div className="app-menu-closed" id="app">
          <Main history={props.history}>
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={12} xs={12} sm={12}>
                  <h1 className="titulo">Perguntas Frequentes</h1>
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid className='card_modulos' item md={3} xs={12} sm={4}>
                  {faqData.map((item, index) => (
                    <Grid key={index} container spacing={1} direction="row" onClick={() => selecionarModulo(item)}>
                      <Grid className={`item_modulo ${item.id === modulo_selecionado.id ? 'selecionado' : ''}`} item xs={12}>
                        <span>{item.modulo}</span>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid className='card_questions' item md={9} xs={12} sm={8}>
                  <Grid item md={12} xs={12} sm={12}>
                    {modulo_selecionado.questions.map((item, index) => (
                      <Accordion className="accordion-custom" key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="h6">{item.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography style={{ fontSize: '16px'}} component="div" dangerouslySetInnerHTML={{ __html: item.answer }} />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>
                </Grid>
              </Grid>           
            </div>
          </Main>
          <Nav/>
          <Alert 
            open={state.alerta.open}
            handleClose={e => handleCloseAlerta()} 
            severity={state.alerta.severity}
            message={state.alerta.message} 
          />
        </div>
      }
      <Loading 
        open={state.loading}
        message={state.mensagemLoading} 
      />
    </React.Fragment>
  )
}

export default ItemReceitaDespesa
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './../Cliente.scss'
import './../../../main/ultil.scss'
import { Grid,  Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import moment from 'moment';
import Table from '../../../components/Table'
import Alert from './../../../components/templates/Alert'
import real from './../../../services/real'
import ModalCancelarServico from './../../../components/modals/CancelarServico'
import ModalAtualizaServico from './../../../components/modals/AtualizaServico'
import ModalAlterarBoleto from './../../../components/modals/AlterarBoleto'
import ModalAlterarBoletoCancelamento from './../../../components/modals/AlterarBoleto'
import ModalCancelarContrato from './../../../components/modals/CancelarServico'
import ModalRenovarContrato from './../../../components/modals/RenovarContrato'
import ModalConfirmacao from './../../../components/modals/Confirmacao'
import ModalNovoContrato from './../../../components/modals/NovoContrato'
import ModalContrato from './../../../components/modals/Contrato'
import ModalAdicionarServico from './../../../components/modals/AdicionarServico'
import { format } from 'cnpj'
import getEndereco from './../../../services/api/getEndereco'
import { saveAs } from 'file-saver';
import decrypt from './../../../services/decrypt'
import initialState from './../initialState/contratoView';
import formatarCEP from './../../../services/formatarCEP'
import somarValores from './../../../services/somarValores'
import contarValores from './../../../services/contarValores'
import getToken from './../../../services/getToken'

const cpf = require('node-cpf-cnpj');

function formataTelefone(numero) {

  if(numero){
    let texto = numero.trim()

		texto = texto.replace(/\D/g,""); 
    
    if(texto.length === 11 || texto.length === 10){
      texto = texto.replace(/^(\d{2})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 12){
      texto = texto.replace(/^(\d{3})(\d)/g,"($1) $2")
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }else if(texto.length === 8){
      texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
    }
  
    return texto
  }

  return ''
}

const Contrato = (props) => {
  const [state, setState] = useState({ ...initialState });
  const navigate = useNavigate();

  const backPage = (timeout = 1000) => {
    setTimeout(() => {
      navigate("/cliente");
    }, timeout)
  }

  const viewContrato = async (value) => {
    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/contrato/${value._id}`, getToken())
      
      let estado_contrato = ''
      
      if (data.statusAtual.estado_contrato === 'ATIVO') {
        estado_contrato = 'Ativo'
      } else if (data.statusAtual.estado_contrato === 'INATIVO') {
        estado_contrato = 'Inativo'
      } else if (data.statusAtual.estado_contrato === 'CANCELADO') {
        estado_contrato = 'Cancelado'
      } else if (data.statusAtual.estado_contrato === 'ENCERRADO') {
        estado_contrato = 'Encerrado'
      } else if (data.statusAtual.estado_contrato === 'AGUARDANDO_ASSINATURA') {
        estado_contrato = 'Aguardando Assinatura'
      }

      let contrato = {
        _id: data.id,
        estado_contrato,
        codigo: data.codigo ? data.codigo : '',
        data_ini_vig_orig: data.data_ini_vig,
        data_ini_vig: moment(data.data_ini_vig).format('DD/MM/YYYY'),
        data_fim_vig: data.data_fim_vig ? data.data_fim_vig : '',
        tempo_contrato: data.tempo_contrato ? data.tempo_contrato : '',
        unidade_tempo: data.unidade_tempo ? data.unidade_tempo : '',
        criado_em: moment(data.criado_em).format('DD/MM/YYYY HH:mm'),
        contrato_produto: data.contrato_produto.sort(function(a,b){return a.ativo-b.ativo}).reverse(),
        unidade: data.unidade,
        cliente: data.cliente,
        midia_id: data.midia_id,
        cobrar_multa: data.cobrar_multa,
        tipo_multa: data.tipo_multa,
        valor_multa: data.valor_multa,
        agrupar_vencidas: data.agrupar_vencidas,
        porc_bonus_pont: data.porc_bonus_pont,
        bonus_pont: data.bonus_pont,
        bonus_indicacao: data.bonus_indicacao,
        cliente_indicacao: data.cliente_indicacao,
        valor_indicacao: data.valor_indicacao
      }

      await consultaProdutos(data.unidade.id)
      await consultarItens(data.unidade.id)

      setState(prevState => ({...prevState,
        contrato,
        openModalContrato: true
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        loading: false,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
      return
    }
  }

  const consultarItens = async (unidade_id) => {
    try {
      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/itemReceitaDespesa/list`, dados, getToken())

      const itens = []

      data.filter(param => param.plano_contas.tipo === 'RECEITA').sort((a, b) => (a.plano_contas.codigo > b.plano_contas.codigo) ? 1 : (b.plano_contas.codigo > a.plano_contas.codigo) ? -1 : 0).forEach(item => {
        itens.push({
          id: item.id,
          descricao: item.descricao,
          planocontas: item.plano_contas.codigo + ' - ' + item.plano_contas.descricao,
          sequencia: item.plano_contas.sequencia,
          tipo: item.plano_contas.tipo === 'DESPESA' ? 'PAGAR' : 'RECEBER',
          planocontas_id: item.plano_contas.id,
          codigo: item.plano_contas.codigo,
          unidade_id: item.plano_contas.unidade_id,
          ativo: item.ativo
        })
      })

      setState(prevState => ({...prevState,
        itens: {
          list: itens
        },
        loading: false
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const buscarPlanos = async (unidade_id) => {
    try {
  
      const dados = {
        unidadesnegocio: [parseInt(unidade_id)],
        tipo: "RECEITA"
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/planoContas/list`, dados,getToken())

      let planoContas = data
        .sort((a, b) => (parseInt(a.codigo) > parseInt(b.codigo)) ? 1 : ((parseInt(b.codigo) > parseInt(a.codigo)) ? -1 : 0))
        .filter(param => param.ativo === true)
        .filter(param => {
          return {
            id: param.id,
            codigo: param.codigo,
            descricao: param.descricao,
            ativo: param.ativo,
            slug: param.slug,
            parametros: param.parametros
          }
        })
        
      setState(prevState => ({...prevState,
        planoContas: {
          list: planoContas
        },
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const consultaProdutos = async (unidade_id) => {
    try {
      const { data: produtosAux } = await axios.get(`${window._env_.REACT_APP_API_URL}/produtos/byUnidade/${unidade_id}`, getToken())
      
      const produtos = []
      
      produtosAux.forEach(produto => {
        produtos.push({
          id: produto.id,
          mid_caminho: produto.midia ? produto.midia.mid_caminho : '',
          descricao: produto.descricao,
          unidademedida: produto.unidademedida,
          subgrupdescricao: produto.subgrupoproduto.subgrupdescricao,
          subgrupid: produto.subgrupoproduto.subgrupid,
          grupdescricao: produto.subgrupoproduto.grupoproduto.grupdescricao,
          visibilidade: produto.visibilidade,
          preco: produto.preco,
          ativo: produto.ativo
        })
      })

      setState(prevState => ({...prevState,
        produtos: {
          list: produtos
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const handleCloseAlerta = () => {
    setState(prevState => ({...prevState,
      alerta: {
        open: false,
        autoHideDuration: 5000,
        severity: state.alerta.severity,
        message: ''
      }
    }))
  }

  const updateServico = async (event) => {
    const {servico, formasPagamento, contrato, produtos, dadosContrato, conta} = state

    const name = event.target.name
    const value = event.target.value

    servico[name] = value

    setState(prevState => ({...prevState,servico}))

    if(name === 'formapagto_id'){
      try {

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Consultando Contas Financeira'
          }
        }))

        const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]

        const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${parseInt(contrato.unidade.id || dadosContrato.unidade_id)}`, getToken())
  
        setState(prevState => ({...prevState,
          contasFinanceira: {
            list: contasFinanceira.filter(param => param.tipo_conta === formaPagtoSelecionada.habilita)
          }
        }))

        setState(prevState => ({...prevState,
          alerta: {
            open: false,
            severity: 'info',
            message: ''
          }
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
      }
    }

    if(name === 'produto_id'){
      const produtoSelecionado = produtos.list.filter(param => param.id === parseInt(value))[0]

      if(produtoSelecionado){
        setState(prevState => ({...prevState,
          produtoSelecionado
        }))
      }else{
        setState(prevState => ({...prevState,
          produtoSelecionado:{
            preco: null
          }
        }))        
      }

    } 

    if(name === 'valor'){
      if(servico.parcelado){
        let valorTotal = parseFloat(value.replaceAll(".", "").replaceAll(",", "."))
  
        let valorDividido = valorTotal / conta.qtd_parcelas
        valorDividido = parseFloat(valorDividido.toFixed(2))
  
        let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)
  
        sobra = parseFloat(sobra.toFixed(2))
  
        for (let i = 0; i < conta.qtd_parcelas; i++) {
          conta.parcelas[i].valorparcela = real(valorDividido)
        }
  
        if (sobra !== 0) {
          conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
        }
  
        conta.valortotal = value

        setState(prevState => ({
          ...prevState,
          conta
        }))
      }
    }

    if(name === 'intervalo_parcela'){
      if(conta.parcelas.length >= 1){
        if(conta.parcelas[0].datavencimento){
          verificaDataParcela( {target: {value: conta.parcelas[0].datavencimento}} , 1)
        }
      }
    }

    if(name === 'cobrar_domingos' || name === 'cobrar_sabados'){
      if(conta.parcelas.length >= 1){
        if(conta.parcelas[0].datavencimento){
          verificaDataParcela( {target: {value: conta.parcelas[0].datavencimento}} , 1)
        }
      }
    }
  }

  const validateServico = () => {
    const {servico, conta} = state

    if(!servico.produto_id) return false
    if(!servico.valor) return false
    if(!servico.parcelado && !servico.recorrencia) return false

    if(servico.parcelado){
      if(!servico.intervalo_parcela) return false
    }

    if(servico.recorrencia){
      if(!servico.formapagto_id) return false
      if(!servico.contfin_id) return false
      if(!servico.venc_prim_parcela) return false
      if(!servico.qtdRecorrencia) return false
      if(!servico.recorrenciaACada) return false
      if(!servico.tipoRecorrencia) return false
    }

    return true
  }

  const addServico = async () => {

    if (!validateServico()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'Preencha os campos obrigatorios (*).'
        }
      }))
      return
    }

    const {servico, contrato, formasPagamento, contasFinanceira, conta} = state

    if(servico.recorrencia){
      const forma_selecionada = formasPagamento.list.filter(param => param.id === parseInt(servico.formapagto_id))[0]
      const conta_selecionada = contasFinanceira.list.filter(param => param.id === parseInt(servico.contfin_id))[0]

      if(moment(servico.venc_prim_parcela).isBefore('1990-01-01')){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'A data de vencimento da parcela não pode ser inferior a 01/01/1990'
          }
        }))
        return
      }

      if(
        moment(servico.venc_prim_parcela).isBefore(new Date()) 
        && forma_selecionada.slug === 'boleto' 
        && conta_selecionada.conta_corrente.parceiro){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'A data de vencimento do boleto não pode ser inferior ao dia de hoje.'
          }
        }))
        return
      }
    }

    setState(prevState => ({...prevState,
      loading: true,
      msgLoading: 'Salvando Serviço...'
    }))

    // const data_hoje = new Date()
    // const ultima_data = new Date(moment(servico.venc_prim_parcela).add(servico.num_parcelas, 'months').format('YYYY-MM-DD'))

    // const inicio = moment(data_hoje);
    // const fim = moment(ultima_data);
    // const duration = moment.duration(fim.diff(inicio));

    // if(forma_selecionada.slug === 'boleto'){
    //   if(duration.asDays() > 400){
    //     setState(prevState => ({...prevState,
    //       alerta: {
    //         open: true,
    //         severity: 'warning',
    //         message: 'A data de vencimento do boleto não pode ser superior a 400 dias.'
    //       }
    //     }))
    //     setState(prevState => ({...prevState,loading: false}))
    //     return
    //   }
    // }
    
    try {
      let dados = {
        produto_id: parseInt(servico.produto_id),
        formapagto_id: servico.recorrencia ? parseInt(servico.formapagto_id) : '',
        contfin_id: servico.recorrencia ? parseInt(servico.contfin_id) : '',
        valor: parseFloat(servico.valor.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
        num_parcelas: parseInt(servico.num_parcelas),
        venc_prim_parcela: servico.recorrencia ? servico.venc_prim_parcela : '',
        recorrencia: servico.recorrencia,
        recorrenciaACada:servico.recorrenciaACada,
        tipoRecorrencia: servico.tipoRecorrencia,
        qtdRecorrencia:servico.qtdRecorrencia,
        gerar_nfse: servico.gerar_nfse
      }

      if(servico.parcelado){
        let parcelas = []

        for (const parcela of conta.parcelas) {
          parcelas.push({
            valorparcela: parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")),
            datavencimento: parcela.datavencimento,
            contfin_id: parseInt(parcela.contfin_id),
            formapagto_id: parseInt(parcela.formapagto_id),
            num: parcela.index,
            documento: parcela.documento
          })
        }

        dados.parcelas = parcelas
      }

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Salvando Serviço...'
        }
      }))

      let {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/contrato/addServico/${contrato._id}`, dados,getToken())

      // if(conta_selecionada.conta_corrente){
      //   if(conta_selecionada.conta_corrente.parceiro){
      //     for (const conta_pr of data.contas_criadas) {
      //       if(forma_selecionada.slug === 'boleto'){
      //         setState(prevState => ({...prevState,
      //           loading: true,
      //           msgLoading: 'Gerando Boletos...'
      //         }))
      //         setState(prevState => ({...prevState,
      //           alerta: {
      //             open: true,
      //             severity: 'info',
      //             message: 'Gerando Boletos...'
      //           }
      //         }))
      //         await axios.post(`${window._env_.REACT_APP_API_URL}/boleto`, conta_pr, getToken())
              
      //       }else if(forma_selecionada.slug === 'pix'){
      //         // setState(prevState => ({...prevState,
      //         //   loading: true,
      //         //   msgLoading: 'Gerando Pix...'
      //         // })

      //         // setState(prevState => ({...prevState,
      //         //   alerta: {
      //         //     open: true,
      //         //     severity: 'info',
      //         //     message: 'Gerando Pix...'
      //         //   }
      //         // })
      //         // await axios.post(`${window._env_.REACT_APP_API_URL}/pix`, conta_pr, getToken())
      //       }
      //     }
      //   }
      // }

      await viewContrato(contrato)
      setState(prevState => ({...prevState,
        openModalAdicionarServico: false
      }))

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'success',
          message: 'Serviço adicionado com sucesso'
        }
      }))
      
    } catch (error) {
      console.log(error)

      setState(prevState => ({...prevState,loading: false}))

      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const atualizaServico = async (row) => {
    const {contrato, formasPagamento} = state

    try {
      const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === row.formapagto_id)[0]
      const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${parseInt(contrato.unidade.id)}`, getToken())
  
      setState(prevState => ({...prevState,
        contasFinanceira: {
          list: contasFinanceira.filter(param => param.tipo_conta === formaPagtoSelecionada.habilita)
        }
      }))

      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/parcelaConta/pendente/contrato_produto/${row._id}`, getToken())
      
      let servico = {...row}
      
      servico.valor = real(data[0] ? data[0].valorparcela: 0)
      servico.num_parcelas = data.length === 0 ? 1 : data.length

      setState(prevState => ({...prevState,
        servico,
        modalAtualizaServico: true,
        financeiros_pendentes: {
          list: data
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }   
  }

  const buscaContas = async (unidade_id) => {
    try {
      const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidade/${parseInt(unidade_id)}`, getToken())

      setState(prevState => ({...prevState,
        contasFinanceira: {
          list: contasFinanceira
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }

  }

  const avisarRemoverServico = (index) => {
    setState(prevState => ({...prevState,
      openModalConfirmacao: true,
      indexServico: index,
      modal: {
        mensagem: 'Ao cancelar esse serviço o contrato do cliente permanecerá vigente, deseja continuar?'
      }
    }))
  }

  const handleCloseModalConfirmacao = () => {
    setState(prevState => ({...prevState,
      openModalConfirmacao: false,
      indexServico: 0
    }))
  }

  const removerServico = async (value) => {
    if(value) {
      handleCloseModalConfirmacao()
      const {contrato, conta, indexServico} = state
      
      const contrato_produto = contrato.contrato_produto
      
      if(contrato_produto[indexServico].ativo){
        try {
  
          const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/parcelaConta/pendente/contrato_produto/${contrato_produto[indexServico].id}`, getToken())
        
          conta.descricao = `Negociação Cancelamento de Serviço`
    
          await buscarPlanos(contrato.unidade.id)
          await buscaContas(contrato.unidade.id)
  
          setState(prevState => ({...prevState,
            modalCancelarServico: true,
            servicoSelecionado: contrato.contrato_produto[indexServico],
            financeiros_pendentes: {
              list: data
            },
            conta
          }))
        } catch (error) {
          console.log(error)
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'error',
              message: error.response ? error.response.data.message : 'Erro Interno'
            }
          }))
        }
      }else{
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Esse serviço se encontra inativo.'
          }
        }))
      }
    }else{
      handleCloseModalConfirmacao()
    }    
  }

  const handleCloseModalCancelarServico = () => {
    setState(prevState => ({...prevState,
      modalCancelarServico: false,
      modalCancelarContrato: false,
      conta: {
        descricao: "",
        tipo: "A RECEBER",
        valortotal: "",
        numparcela: 1,
        item_id: '',
        tipo_item: 'ITEMRECDESP',
        qtd_parcelas: 1,
        gerar_nfse: false,
        parcelas: [
          {
            baixado: "",
            datavencimento: "",
            valorparcela: "",
            formapagto_id: "",
            documento: "",
            num: "",
            contfin_id: "",
            parcela_editada: false,
            index: 1
          }
        ]
      },
    }))
  }

  const updateFieldLancFinanceiroCancelamento = () => {
    const {cancelarServico} = state
    
    cancelarServico.lanc_fin = !cancelarServico.lanc_fin
    
    setState(prevState => ({...prevState,
      cancelarServico
    }))
  }

  const updateFieldConta = (event) => {
    const conta = { ...state.conta }

    if (event.target.name === 'valortotal') {

      const valorTotal = parseFloat(event.target.value.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
      let valorDividido = valorTotal / conta.qtd_parcelas
      valorDividido = parseFloat(valorDividido.toFixed(2))

      let sobra = valorTotal - (valorDividido * conta.qtd_parcelas)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        conta.parcelas[i].valorparcela = real(valorDividido)
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }
    }

    conta[event.target.name] = event.target.value


    setState(prevState => ({...prevState, conta }))
  }

  const recalcularParcela = (event, index) => {
    const conta = { ...state.conta }

    const parcelaEditada = conta.parcelas[index - 1]

    if (parcelaEditada.valorparcela !== event.target.value) {

      const valorTotal = parseFloat(conta.valortotal.replace("R$ ", "").replaceAll(".", "").replace(",", "."))

      let qtdParcelasEditado = state.qtdParcelasEditado

      if (!parcelaEditada.parcela_editada) {
        conta.parcelas[index - 1].parcela_editada = true
        qtdParcelasEditado = qtdParcelasEditado + 1

        setState(prevState => ({...prevState,
          qtdParcelasEditado
        }))
      }

      conta.parcelas[index - 1][event.target.name] = event.target.value

      const parcelasEditada = conta.parcelas.filter(param => param.parcela_editada)
      const somaParcelasEditada = somarValores(parcelasEditada.map(parcela => { return parseFloat(parcela.valorparcela.replace("R$ ", "").replaceAll(".", "").replace(",", ".")) }))
      const diferenca = valorTotal - somaParcelasEditada
      
      const qtdParcelasNaoEditada = conta.qtd_parcelas - qtdParcelasEditado
      
      let valorDividido = parseFloat(diferenca / qtdParcelasNaoEditada).toFixed(2)
      valorDividido = parseFloat(valorDividido)

      let sobra = diferenca - (valorDividido * qtdParcelasNaoEditada)

      sobra = parseFloat(sobra.toFixed(2))

      for (let i = 0; i < conta.qtd_parcelas; i++) {
        if (!conta.parcelas[i].parcela_editada) {
          conta.parcelas[i].valorparcela = real(valorDividido)
        }
      }

      if (sobra !== 0) {
        conta.parcelas = ajustarArray(conta.qtd_parcelas, conta.parcelas, valorDividido, sobra)
      }

      setState(prevState => ({...prevState, conta }))
    }
  }

  const mudarParcelas = (event) => {
    const {conta, servico} = state

    let qtdParcelasInfo = 1

    if(parseInt(event.target.value) > 0){
      qtdParcelasInfo = parseInt(event.target.value)
    }

    let array = []

    const valorTotal = parseFloat(conta.valortotal.replace("R$ ", "").replaceAll(".", "").replace(",", "."))
    
    let valorDividido = valorTotal / qtdParcelasInfo
    
    valorDividido = parseFloat(valorDividido.toFixed(2))  

    let sobra = valorTotal - (valorDividido * qtdParcelasInfo)

    sobra = parseFloat(sobra.toFixed(2))
    let data_acrecentada = moment()

    for (let i = 0; i < qtdParcelasInfo; i++) {
      data_acrecentada = moment(data_acrecentada).add(1, verificaUnidadeTempo(servico.intervalo_parcela))

      if(servico.cobrar_sabados === 'NAO' && moment(data_acrecentada).format('dddd') === 'sábado'){
        if(servico.cobrar_domingos === 'NAO'){
          data_acrecentada = moment(data_acrecentada).add(2, 'day')
        }else{
          data_acrecentada = moment(data_acrecentada).add(1, 'day')
        }
      }

      if(servico.cobrar_domingos === 'NAO' && moment(data_acrecentada).format('dddd') === 'domingo'){
        data_acrecentada = moment(data_acrecentada).add(1, 'day')
      }

      array.push({
        baixado: "",
        datavencimento: moment(data_acrecentada).format('YYYY-MM-DD'),
        valorparcela: real(valorDividido),
        formapagto_id: "",
        documento: "",
        num: "",
        parcela_editada: false,
        index: i + 1
      })
    }

    if (sobra !== 0) {
      array = ajustarArray(qtdParcelasInfo, array, valorDividido, sobra)
    }

    conta.numparcela = qtdParcelasInfo
    conta.qtd_parcelas = qtdParcelasInfo
    conta.parcelas = array

    setState(prevState => ({...prevState, conta }))
  }

  const ajustarArray = (qtdParcela, array, valorDividido, sobra) => {
    const qtdSobra = parseInt(sobra.toString().replace('-', '').replace('0.0', ''))

    const arrayFalse = array.filter(param => param.parcela_editada === false)
    const arrayTrue = array.filter(param => param.parcela_editada === true)

    for (let i = 0; i < qtdSobra; i++) {
      arrayFalse[arrayFalse.length - (i + 1)].valorparcela = real((valorDividido + ((sobra) / qtdSobra)).toFixed(2))
    }

    for (let i = 0; i < arrayTrue.length; i++) {
      arrayFalse.push(arrayTrue[i])
    }

    return arrayFalse
  }

  const updateFieldParcela = (event, index) => {
    const {conta, formasPagamento} = state
    
    let value = event.target.value
    let name = event.target.name
    
    if(name === 'formapagto_id'){
      const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]
      
      conta.parcelas[index - 1].habilita = formaPagtoSelecionada.habilita
      conta.parcelas[index - 1].contfin_id = ""
    }

    conta.parcelas[index - 1][name] = value
    
    setState(prevState => ({...prevState, conta }))
  }

  const verificaDataParcela = (event, index) => {
    const {conta, servico} = state

    conta.parcelas[index - 1].datavencimento_orig = event.target.value
    
    let novaData = moment(event.target.value)

    for (let i = index; i < conta.parcelas.length; i++) {
      novaData = moment(novaData).add(1, verificaUnidadeTempo(servico.intervalo_parcela))

      if(servico.cobrar_sabados === 'NAO' && moment(novaData).format('dddd') === 'sábado'){
        if(servico.cobrar_domingos === 'NAO'){
          novaData = moment(novaData).add(2, 'day')
        }else{
          novaData = moment(novaData).add(1, 'day')
        }
      }

      if(servico.cobrar_domingos === 'NAO' && moment(novaData).format('dddd') === 'domingo'){
        novaData = moment(novaData).add(1, 'day')
      }

      conta.parcelas[i].datavencimento = moment(novaData).format('YYYY-MM-DD')
      conta.parcelas[i].datavencimento_orig = novaData    
    }

    setState(prevState => ({...prevState, conta }))
  }

  const updateFieldCancelarServico = (event) => {
    const { cancelarServico } = state

    cancelarServico[event.target.name] = event.target.value

    setState(prevState => ({...prevState, cancelarServico }))
  }

  const confirmarCancelamentoServico = async (value) => {
    if (value){

      let {financeiros_pendentes} = state

      let continua = true
      let boletosVencidos = []

      for (const financeiro of financeiros_pendentes.list) {
        if(financeiro.outras_parcelas.length > 0){
          continua = false

          for (const outra_parcela of financeiro.outras_parcelas) {
            boletosVencidos.push({
              ...outra_parcela,
              nova_multajuros: real(outra_parcela.multajuros),
              nova_data_vencimento: ''
            })
          }

        }
      }

      if(!continua){
        setState(prevState => ({...prevState,
          boletosVencidos,
          modalAlterarBoleto: true
        }))

      }else{
        await finalizarCancelamentoServico(true)
      }

    }else{
      setState(prevState => ({...prevState,
        modalCancelarServico: false
      }))
    }
  }

  const finalizarCancelamentoServico = async (value) => {
    if (value) {
      let {conta, cancelarServico, contrato, financeiros_pendentes, servicoSelecionado, boletosVencidos} = state

      for (const boleto of boletosVencidos) {
        if(boleto.nova_data_vencimento === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: `Por favor informar as novas datas de vencimento do boleto.`
            },
            loading: false,
          }))
        
          return
        }
      }

      if(cancelarServico.lanc_fin){
  
        if(conta.item_id === '' || !conta.item_id){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: `Por favor informar o item de receita.`
            }
          }))
          return
        }
  
        if(conta.valortotal === "" || parseFloat(conta.valortotal.replaceAll(".", "").replace(",", ".")) === 0 ){
          
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: `Por favor informar o valor da conta.`
            }
          }))
        
          return
        }
  
        if(parseFloat(conta.valortotal.replaceAll(".", "").replace(",", ".")) < 0 ){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: `O valor da conta não pode ser negativo.`
            }
          }))
          return
        }
  
        for (let i = 0; i < conta.parcelas.length; i++) {
          if(conta.parcelas[i].formapagto_id === ""){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Por favor informar a forma de pagamento da parcela ${conta.parcelas[i].index}.`
              }
            }))
            return
          }
  
          if(conta.parcelas[i].contfin_id === ""){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Por favor informar a conta da parcela ${conta.parcelas[i].index}.`
              }
            }))
            return
          }
  
          if(conta.parcelas[i].datavencimento === ""){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Por favor informar a data de vencimento da parcela ${conta.parcelas[i].index}.`
              }
            }))
            return
          }
        }
      }
  
      if(cancelarServico.motivoCancelamento === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o motivo do cancelamento.'
          }
        }))
        return
      }
  
      const dados = {
        motivoCancelamento: cancelarServico.motivoCancelamento,
        contrato_produto_id: servicoSelecionado.id,
        conta,
        financeiros_pendentes: financeiros_pendentes.list.map(value => {
          return {
            id: value.id
          }
        }),
        boletos_vencidos: boletosVencidos.map(value => {
          return {
            parcela_id: value.id,
            datavencimento: value.nova_data_vencimento,
            multajuros: parseFloat(value.nova_multajuros.replaceAll(".", "").replace(",", "."))
          }
        })
      }

      if(dados.boletos_vencidos.length === 0){
        delete dados.boletos_vencidos
      }
  
      if(dados.financeiros_pendentes.length === 0){
        delete dados.financeiros_pendentes
      }
  
      if(!cancelarServico.lanc_fin){
        delete dados.conta
      }else{
        dados.conta.tipo = 'RECEBER'
        dados.conta.valortotal = parseFloat(dados.conta.valortotal.replaceAll(".", "").replace(",", "."))
  
        for (let i = 0; i < dados.conta.parcelas.length; i++) {
          dados.conta.parcelas[i].valorparcela = parseFloat(dados.conta.parcelas[i].valorparcela.replaceAll(".", "").replace(",", "."))
          dados.conta.parcelas[i].baixado = false
          dados.conta.parcelas[i].num = dados.conta.parcelas[i].index
          dados.conta.parcelas[i].formas_pgto = [
            {
              formapagto_id: parseInt(dados.conta.parcelas[i].formapagto_id)
            }
          ]
    
          if(
            moment(dados.conta.parcelas[i].datavencimento).isBefore(new Date()) 
            && (dados.conta.parcelas[i].formas_pgto.formapagto_id === 4 || dados.conta.parcelas[i].formas_pgto.formapagto_id === 5)){
            
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'error',
                  message: `A data do ${dados.conta.parcelas[i].formas_pgto.formapagto_id === 4 ? 'Pix' : 'Boleto'} não pode ser inferior ao dia de amanhã`
                }
              }))
  
              return false
          }
  
          delete dados.conta.parcelas[i].formapagto_id
          delete dados.conta.parcelas[i].contfin_id
        }
      }
  
      if(financeiros_pendentes.length === 0){
        delete dados.financeiros_pendentes
      }
  
      try {

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: `Cancelando Serviço...`
          }
        }))
          
        await axios.post(`${window._env_.REACT_APP_API_URL}/contrato/removerServico/${contrato._id}`, dados, getToken())   
  
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: `Serviço Cancelado.`
          }
        }))
  
        await inicia_tela()
        await viewContrato(contrato)
      
        setState(prevState => ({...prevState,
          modalCancelarServico: false,
          modalAlterarBoleto: false
        }))
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          loading: false,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
        return
      }
    }else{
      setState(prevState => ({...prevState,
        boletosVencidos:[],
        modalAlterarBoleto: false
      }))
    }
    
  }

  const confirmarAlteracaoServico = async (value) => {
    if (value){

      let {servico, financeiros_pendentes, boletosVencidos} = state

      for (const boleto of boletosVencidos) {
        if(boleto.nova_data_vencimento === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: `Por favor informar as novas datas de vencimento.`
            },
            loading: false,
          }))
        
          return
        }
      }

      setState(prevState => ({...prevState,
        loading: true,
        modalAtualizaServico: false,
        msgLoading: 'Gerando Boletos...'
      }))

      if(financeiros_pendentes.list.findIndex(param => param.estado_contrato === "VENCIDO") !== -1 && servico.formapagto_id === 4){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: `Existem parcelas em atrasos, não é possivel gerar o boleto.`
          },
          loading: false,
        }))
      
        return
      }

      const dados = {
        servico:{
          id: servico._id,
          valor: parseFloat(servico.valor.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
          formapagto_id: servico.formapagto_id,
          contfin_id: servico.contfin_id,
          gerar_nfse: servico.gerar_nfse
        },
        financeiros_pendentes: financeiros_pendentes.list.map(value => {
          return {
            id: value.id
          }
        }),
        boletos_vencidos: boletosVencidos.map(value => {
          return {
            parcela_id: value.id,
            datavencimento: value.nova_data_vencimento,
            multajuros:parseFloat(value.nova_multajuros.replaceAll(".", "").replace(",", "."))
          }
        })
      }

      if(dados.boletos_vencidos.length === 0){
        delete dados.boletos_vencidos
      }

      try {

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: `Alterando Serviço...`
          }
        }))
        
        let {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/contrato/alteraServico`, dados, getToken())   

        if(data){
          for (const parcela_id of data) {
            let dadosGera = {
              parcelas : [{id: parcela_id}]
            }

            if(parseInt(servico.formapagto_id) === 4){
              await axios.post(`${window._env_.REACT_APP_API_URL}/boleto`, dadosGera, getToken())
            }else{
              // await axios.post(`${window._env_.REACT_APP_API_URL}/pix`, dadosGera, getToken())
            }
          }
        }

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: `Serviço Alterado.`
          }
        }))

        window.location.reload()

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          loading: false,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
        return
      }

    }else{
      setState(prevState => ({...prevState,
        modalAtualizaServico: false,
        servico:{
          produto_id: '',
          descricao: '',
          formapagto_id: '',
          forma_pagto: '',
          valor: '',
          num_parcelas: '',
          contfin_id: '',
          conta_fin: '',
          venc_prim_parcela: '',
          venc_prim_parcela_sem_form: '',
          gerar_nfse: false,
          index: ''
        }
      }))
    }
  }

  const handleCloseModalAtualizaServico = () => {
    setState(prevState => ({...prevState,
      modalAtualizaServico: false,
      modalCancelarContrato: false,
      modalAlterarBoletoCancelamento: false
    }))
  }

  const updateFieldBoleto = (event, id) => {
    const{boletosVencidos} = state 
  
    const index = boletosVencidos.findIndex(param => param.id === id)

    boletosVencidos[index][event.target.name] = event.target.value 
    setState(prevState => ({...prevState,
      boletosVencidos
    }))
  }

  const cancelarContrato = async (contrato, estado_contrato) => {
    let {conta} = state

    setState(prevState => ({...prevState,
      estado_contrato
    }))

    try {
      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/parcelaConta/pendente/contrato/${contrato._id}`, getToken())
    
      conta.descricao = `Negociação Cancelamento de Contrato`
  
        await buscarPlanos(contrato.unidade.id)
        await buscaContas(contrato.unidade.id)

        setState(prevState => ({...prevState,
          modalCancelarContrato: true,
          financeiros_pendentes: {
            list: data
          },
          conta
        }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const confirmarCancelamentoContrato = async (value) => {
    if(value){
      let {financeiros_pendentes} = state

      let continua = true
      let boletosVencidos = []

      for (const financeiro of financeiros_pendentes.list) {
        if(financeiro.outras_parcelas.length > 0){
          continua = false

          for (const outra_parcela of financeiro.outras_parcelas) {
            boletosVencidos.push({
              ...outra_parcela,
              nova_multajuros: real(outra_parcela.multajuros),
              nova_data_vencimento: ''
            })
          }

        }
      }

      if(!continua){
        setState(prevState => ({...prevState,
          boletosVencidos,
          modalAlterarBoletoCancelamento: true
        }))

      }else{
        await finalizarCancelamentoContrato(true)
      }
    }else{
      setState(prevState => ({...prevState,
        modalCancelarContrato: false
      }))
    }
  }

  const finalizarCancelamentoContrato = async (value) => {
    if(value) {
      let {conta, cancelarServico, contrato, financeiros_pendentes, estado_contrato, boletosVencidos} = state

      for (const boleto of boletosVencidos) {
        if(boleto.nova_data_vencimento === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: `Por favor informar as novas datas de vencimento do boleto.`
            },
            loading: false,
          }))
        
          return
        }
      }

      if(cancelarServico.lanc_fin){
  
        if(conta.item_id === '' || !conta.item_id){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: `Por favor informar o item de receita.`
            }
          }))
          return
        }
  
        if(conta.valortotal === "" || parseFloat(conta.valortotal.replaceAll(".", "").replace(",", ".")) === 0 ){
          
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: `Por favor informar o valor da conta.`
            }
          }))
        
          return
        }
  
        if(parseFloat(conta.valortotal.replaceAll(".", "").replace(",", ".")) < 0 ){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: `O valor da conta não pode ser negativo.`
            }
          }))
          return
        }
  
        for (let i = 0; i < conta.parcelas.length; i++) {
          if(conta.parcelas[i].formapagto_id === ""){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Por favor informar a forma de pagamento da parcela ${conta.parcelas[i].index}.`
              }
            }))
            return
          }
  
          if(conta.parcelas[i].contfin_id === ""){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Por favor informar a conta da parcela ${conta.parcelas[i].index}.`
              }
            }))
            return
          }
  
          if(conta.parcelas[i].datavencimento === ""){
            setState(prevState => ({...prevState,
              alerta: {
                open: true,
                severity: 'warning',
                message: `Por favor informar a data de vencimento da parcela ${conta.parcelas[i].index}.`
              }
            }))
            return
          }
        }
      }
  
      if(cancelarServico.motivoCancelamento === ""){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar o motivo do cancelamento.'
          }
        }))
        return
      }
  
      const dados = {
        motivoCancelamento: cancelarServico.motivoCancelamento,
        contrato: contrato._id,
        estado_contrato,
        conta,
        financeiros_pendentes: financeiros_pendentes.list.map(value => {
          return {
            id: value.id
          }
        }),
        boletos_vencidos: boletosVencidos.map(value => {
          return {
            parcela_id: value.id,
            datavencimento: value.nova_data_vencimento,
            multajuros: parseFloat(value.nova_multajuros.replaceAll(".", "").replace(",", "."))
          }
        })
      }

      if(dados.boletos_vencidos.length === 0){
        delete dados.boletos_vencidos
      }
  
      if(dados.financeiros_pendentes.length === 0){
        delete dados.financeiros_pendentes
      }
  
      if(!cancelarServico.lanc_fin){
        delete dados.conta
      }else{
        dados.conta.tipo = 'RECEBER'
        dados.conta.valortotal = parseFloat(dados.conta.valortotal.replaceAll(".", "").replace(",", "."))
  
        for (let i = 0; i < dados.conta.parcelas.length; i++) {
          dados.conta.parcelas[i].valorparcela = parseFloat(dados.conta.parcelas[i].valorparcela.replaceAll(".", "").replace(",", "."))
          dados.conta.parcelas[i].baixado = false
          dados.conta.parcelas[i].num = dados.conta.parcelas[i].index
          dados.conta.parcelas[i].formas_pgto = [
            {
              formapagto_id: parseInt(dados.conta.parcelas[i].formapagto_id)
            }
          ]
    
          if(
            moment(dados.conta.parcelas[i].datavencimento).isBefore(new Date()) 
            && (dados.conta.parcelas[i].formas_pgto.formapagto_id === 4 || dados.conta.parcelas[i].formas_pgto.formapagto_id === 5)){
            
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'error',
                  message: `A data do ${dados.conta.parcelas[i].formas_pgto.formapagto_id === 4 ? 'Pix' : 'Boleto'} não pode ser inferior ao dia de amanhã`
                }
              }))
  
              return false
          }
  
          delete dados.conta.parcelas[i].formapagto_id
          // delete dados.conta.parcelas[i].contfin_id
        }
      }
  
      if(financeiros_pendentes.length === 0){
        delete dados.financeiros_pendentes
      }
  
      try {

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: `Cancelando Contrato...`
          }
        }))
          
        let {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/contrato/cancelar/${contrato._id}`, dados, getToken())   
  
        if(data.parcelas){
          for (const parcela of data.parcelas) {
  
            let dadosGera = {
              parcelas : [parcela]
            }
            if(parcela.forma_slug === 'boleto'){
              setState(prevState => ({...prevState,
                alerta: {
                  open: true,
                  severity: 'info',
                  message: 'Gerando Boletos...'
                }
              }))
              await axios.post(`${window._env_.REACT_APP_API_URL}/boleto`, dadosGera, getToken())
              
            }else if(parcela.forma_slug === 'pix'){
              // setState(prevState => ({...prevState,
              //   alerta: {
              //     open: true,
              //     severity: 'info',
              //     message: 'Gerando Pix...'
              //   }
              // })
              // await axios.post(`${window._env_.REACT_APP_API_URL}/pix`, dadosGera, getToken())
            }
          }
        }

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: `Contrato Cancelado.`
          }
        }))
        
        setState(prevState => ({...prevState,
          modalCancelarContrato: false,
          modalAlterarBoletoCancelamento: false,
          openModalContrato: false
        }))
        
        await inicia_tela()
  
      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          loading: false,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
        return
      }
    }else{
      setState(prevState => ({...prevState,
        boletosVencidos:[],
        modalAlterarBoletoCancelamento: false
      }))
    }
  }

  const renovarContrato = async (contrato) => {
    let {dadosRenovarContrato} = state

    dadosRenovarContrato.data_ini_vig = contrato.data_fim_vig
    dadosRenovarContrato.tempo_contrato = contrato.tempo_contrato
    dadosRenovarContrato.unidade_tempo = contrato.unidade_tempo
    dadosRenovarContrato.data_fim_vig = moment(dadosRenovarContrato.data_ini_vig).add(dadosRenovarContrato.tempo_contrato, verificaUnidadeTempo(dadosRenovarContrato.unidade_tempo)).format('YYYY-MM-DD')

    let ids_produto = []
    let key = 0

    dadosRenovarContrato.produtos = []

    for (const value of contrato.contrato_produto) {
      if(ids_produto.findIndex(param => param === value.produto_id) === -1){
        ids_produto.push(value.produto_id)
        
        let parcelas = []
  
        if(value.parcelado){
          for (let i = 0; i < value.num_parcelas; i++) {
            parcelas.push({
              baixado: false,
              datavencimento: moment(value.venc_prim_parcela).add(i+1, 'month').add(dadosRenovarContrato.tempo_contrato, verificaUnidadeTempo(dadosRenovarContrato.unidade_tempo)).format('YYYY-MM-DD'),
              valorparcela: real(value.valor),
              formapagto_id: value.formapagto_id,
              documento: '',
              num: i+1,
              contfin_id: value.contfin_id,
              parcela_editada: false,
              index: i+1
            })
          }
        }

        dadosRenovarContrato.produtos.push({
          produto_id: value.produto_id,
          descricao:value.produto.descricao,
          formapagto_id: value.formapagto_id,
          forma_pagto: value.forma_pagamento.descricao,
          contfin_id: value.contfin_id,
          conta_fin: value.conta_financeira.descricao,
          valor: value.parcelado ? real(value.valor * value.num_parcelas) : real(value.valor),
          descricao_parcelamento: `${value.num_parcelas}x de R$ ${value.valor}`,
          venc_prim_parcela: moment(value.venc_prim_parcela).add(dadosRenovarContrato.tempo_contrato, verificaUnidadeTempo(dadosRenovarContrato.unidade_tempo)).format('YYYY-MM-DD'),
          parcelado: value.parcelado,
          recorrencia: value.recorrencia,
          num_parcelas: value.num_parcelas,
          qtdRecorrencia: value.num_parcelas,
          recorrenciaACada: 1,
          tipoRecorrencia: 'M',
          parcelas,
          index: key
        })

        key++
      }
    }

    await consultarContasBancarias(props.cliente.unidade_id)

    setState(prevState => ({...prevState,
      modalRenovarContrato: true,
      dadosRenovarContrato
    }))
  }

  const editarServicoRenovarContrato = (index) => {
    let {dadosRenovarContrato, conta} = state

    if(dadosRenovarContrato.produtos[index].parcelado){
      conta.valortotal = dadosRenovarContrato.produtos[index].valor
      conta.numparcela = dadosRenovarContrato.produtos[index].parcelas.length
      conta.parcelas = dadosRenovarContrato.produtos[index].parcelas
    }

    setState(prevState => ({...prevState,
      servico: dadosRenovarContrato.produtos[index],
      conta,
      alteraServico: true,
      habilita_servico: true
    }))
  }

  const addServicoRenovarContrato = () => {
    if (!validateServico()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios do serviço (*)."
        },
        loadingSalvar: false
      }))
      return
    }

    const {servico, produtos, formasPagamento, contasFinanceira, dadosRenovarContrato, conta} = state

    let parcelas = []

    if(servico.parcelado){
      for (const parcela of conta.parcelas) {

        if(parcela.valorparcela === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar o valor da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }

        if(parcela.datavencimento === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar a data de vencimento da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }

        if(parcela.formapagto_id === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar a forma de pagamento da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }

        if(parcela.contfin_id === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar a conta financeira da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }
      }

      parcelas = [...conta.parcelas]
    }

    let descricao_parcelamento = ''

    if(servico.parcelado){
      let contagem = contarValores(parcelas.map(value => {return value.valorparcela.replace("R$ ", "").replaceAll(".", "").replace(",", ".")})).sort((a, b) => (a.quantidade > b.quantidade) ? 1 : ((b.quantidade > a.quantidade) ? -1 : 0))
    
      for (let i = 0; i < contagem.length; i++) {
        const element = contagem[i];

        if((i + 1) === contagem.length){
          descricao_parcelamento += `${element.quantidade}x de R$ ${real(element.valor)}`
        }else{
          descricao_parcelamento += `${element.quantidade}x de R$ ${real(element.valor)}, `
        }
      }
    }

    const produto_selecionado = produtos.list.filter(param => param.id === parseInt(servico.produto_id))[0]
    const forma_selecionada = formasPagamento.list.filter(param => param.id === parseInt(servico.recorrencia ? servico.formapagto_id : parcelas[0].formapagto_id))[0]
    const conta_fin_selecionada = contasFinanceira.list.filter(param => param.id === parseInt(servico.recorrencia ? servico.contfin_id : parcelas[0].contfin_id))[0]
    
    let obj = {
      produto_id: parseInt(servico.produto_id),
      descricao: produto_selecionado.descricao,
      formapagto_id: servico.recorrencia ? parseInt(servico.formapagto_id) : '',
      forma_pagto: forma_selecionada.descricao,
      valor: servico.valor,
      num_parcelas: servico.recorrencia ? parseInt(servico.num_parcelas) : parcelas.length,
      contfin_id: servico.recorrencia ? parseInt(servico.contfin_id) : '',
      conta_fin: conta_fin_selecionada.descricao,
      venc_prim_parcela: servico.recorrencia ? servico.venc_prim_parcela : parcelas[0].datavencimento,
      recorrencia: servico.recorrencia,
      parcelado: servico.parcelado,
      recorrenciaACada:servico.recorrenciaACada,
      tipoRecorrencia: servico.tipoRecorrencia,
      qtdRecorrencia:servico.qtdRecorrencia,
      parcelas,
      descricao_parcelamento: servico.recorrencia ? `${servico.qtdRecorrencia}x de R$ ${servico.valor}`  : descricao_parcelamento,
    }

    if(servico.index === ''){
      dadosRenovarContrato.produtos.push({
        ...obj,
        index: dadosRenovarContrato.produtos.length,
      })
    }else{
      dadosRenovarContrato.produtos[servico.index] = {
        ...obj,
        index: servico.index,
      }
    }

    setState(prevState => ({...prevState,
      dadosRenovarContrato,
      servico:{
        produto_id: '',
        descricao: '',
        formapagto_id: '',
        forma_pagto: '',
        valor: '',
        num_parcelas: '',
        contfin_id: '',
        conta_fin: '',
        venc_prim_parcela: '',
        recorrencia: false,
        parcelado: false,
        recorrenciaACada: 1,
        tipoRecorrencia: 'M',
        qtdRecorrencia: 1,
        index: ''
      },
      alteraServico: false,
      conta: {
        descricao: "",
        tipo: "A RECEBER",
        valortotal: "",
        numparcela: 1,
        item_id: '',
        tipo_item: 'ITEMRECDESP',
        qtd_parcelas: 1,
        gerar_nfse: false,
        parcelas: [
          {
            baixado: "",
            datavencimento: "",
            valorparcela: "",
            formapagto_id: "",
            documento: "",
            num: "",
            contfin_id: "",
            parcela_editada: false,
            index: 1
          }
        ]
      },
      habilita_servico: false
    }))
  }

  const removerServicoRenovarContrato = (index) => {
    let {dadosRenovarContrato} = state

    dadosRenovarContrato.produtos.splice(index, 1)

    setState(prevState => ({...prevState,
      dadosRenovarContrato
    }))
  }

  const handleCloseModalRenovarContrato = async () => {
    setState(prevState => ({...prevState,
      modalRenovarContrato: false,
      servico:{
        produto_id: '',
        descricao: '',
        formapagto_id: '',
        forma_pagto: '',
        valor: '',
        num_parcelas: '',
        contfin_id: '',
        conta_fin: '',
        venc_prim_parcela: '',
        venc_prim_parcela_sem_form: '',
        gerar_nfse: false,
        index: ''
      }
    }))
  }

  const updateDadosRenovar = (event) => {
    let {dadosRenovarContrato} = state
    dadosRenovarContrato[event.target.name] = event.target.value
    setState(prevState => ({...prevState,dadosRenovarContrato}))
  }

  const confirmarRenovacao = async (value) => {
    if (value) {
      // setState(prevState => ({...prevState,disabledButton: true}))

      const {servico, contrato, formasPagamento, contasFinanceira, dadosRenovarContrato, conta} = state

      if (!dadosRenovarContrato.data_fim_vig || dadosRenovarContrato.data_fim_vig === '') {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a nova data final do contrato(*).'
          },
          disabledButton: false
        }))
        return
      }

      if(moment(dadosRenovarContrato.data_fim_vig).isBefore(contrato.data_ini_vig_orig)){
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'A data final do contrato não pode ser inferior a data incial do contrato'
          },
          disabledButton: false
        }))
        return
      }
      
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Salvando Renovação...'
        }
      }))

      try {

        let dados = {
          data_ini_vig: dadosRenovarContrato.data_ini_vig,
          data_fim_vig: dadosRenovarContrato.data_fim_vig,
          unidade_tempo: dadosRenovarContrato.unidade_tempo,
          tempo_contrato: parseInt(dadosRenovarContrato.tempo_contrato),
          produtos: dadosRenovarContrato.produtos.map(value => {
            console.log(value)
            let dados = {
              produto_id:parseInt(value.produto_id),
              valor: parseFloat(value.valor.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
              num_parcelas: value.recorrencia ? parseInt(value.num_parcelas) : value.parcelas.length,
              formapagto_id: value.recorrencia ? parseInt(value.formapagto_id) : '',
              contfin_id: value.recorrencia ? parseInt(value.contfin_id) : '',
              venc_prim_parcela: value.venc_prim_parcela,
              recorrencia: value.recorrencia,
              recorrenciaACada: value.recorrenciaACada,
              tipoRecorrencia: value.tipoRecorrencia,
              qtdRecorrencia: value.qtdRecorrencia
            }
  
            if(value.parcelado){
              let parcelas = []
  
              for (const parcela of value.parcelas) {
                parcelas.push({
                  valorparcela: parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")),
                  datavencimento: parcela.datavencimento,
                  contfin_id: parseInt(parcela.contfin_id),
                  formapagto_id: parseInt(parcela.formapagto_id),
                  num: parcela.index,
                  documento: parcela.documento
                })
              }
  
              dados.parcelas = parcelas
            }
  
            return {
              ...dados           
            }
          })
        }

        console.log(contrato)

        let {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/contrato/renovarContrato/${contrato._id}`, dados,getToken())

        // if(conta_selecionada.conta_corrente){
        //   if(conta_selecionada.conta_corrente.parceiro){
        //     for (const conta_pr of data.contas_criadas) {
        //       if(forma_selecionada.slug === 'boleto'){
        //         setState(prevState => ({...prevState,
        //           alerta: {
        //             open: true,
        //             severity: 'info',
        //             message: 'Gerando Boletos...'
        //           }
        //         }))
        //         await axios.post(`${window._env_.REACT_APP_API_URL}/boleto`, conta_pr, getToken())
                
        //       }else if(forma_selecionada.slug === 'pix'){
        //         // setState(prevState => ({...prevState,
        //         //   alerta: {
        //         //     open: true,
        //         //     severity: 'info',
        //         //     message: 'Gerando Pix...'
        //         //   }
        //         // })
        //         // await axios.post(`${window._env_.REACT_APP_API_URL}/pix`, conta_pr, getToken())
        //       }
        //     }
        //   }
        // }

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'success',
            message: 'Renovação realizada com sucesso...'
          }
        }))

        handleCloseModalRenovarContrato()
  
        window.location.reload()

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          },
          disabledButton: false
        }))
      }
    }else{
      handleCloseModalRenovarContrato()
    }
  }

  const updateFieldGerarNfse = () => {
    let {servico} = state

    servico.gerar_nfse = !servico.gerar_nfse

    setState(prevState => ({...prevState,servico}))
  }

  const updateFieldGerarNfseConta = (index) => {
    const {conta} = state

    conta.gerar_nfse = !conta.gerar_nfse

    setState(prevState => ({...prevState,conta}))
  }

  const novoContrato = async () => {
    setState(prevState => ({...prevState,
      responsavel: {
        pessoaFisica: {
          nome: "",
          cpf: "",
          rg: "",
          dataNascimento: ""
        },
        pessoaJuridica: {
          id:"",
          pessoa_id: "",
          razaoSocial: "",
          nomeFantasia: "",
          cnpj: "",
          inscricaoEstadual: "",
          dataFundacao: "",
        },
        endereco: {
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "",
          index: 1
        },
        email: {
          email: "",
          principal: true,
          index: 1
        },
        contato: {
          numero: "",
          tipoContato: "",
          principal: true,
          index: 1
        },
      },
      tabContrato: 0,
      searchResp: {
        cpf: ''
      },
      enableBuscaResp: true,
    }))

    const cliente = props.cliente.cliente
    
    let {dadosContrato} = state
    
    dadosContrato.unidade_id = props.cliente.unidade_id
    dadosContrato.cliente_id = cliente.id
    
    await consultaProdutos(props.cliente.unidade_id)
    await consultarContasBancarias(props.cliente.unidade_id)
    await consultarTemplatesDocumento(props.cliente.unidade_id)
   
    setState(prevState => ({...prevState,
      openModalNovoContrato: true,
      dadosContrato
    }))
  }

  const consultarContasBancarias = async (unidade_id) => {
    try {
      const { data: contasFinanceira } = await axios.get(`${window._env_.REACT_APP_API_URL}/contaFinanceira/listByUnidadeAll/${parseInt(unidade_id)}`, getToken())
  
      setState(prevState => ({...prevState,
        contasFinanceira: {
          list: contasFinanceira
        }
      }))
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }

  }

  const handleCloseModalNovoContrato = () => {
    setState(prevState => ({...prevState,
      openModalNovoContrato: false,
      dadosContrato: {
        codigo: '',
        data_ini_vig: '',
        data_fim_vig: '',
        unidade_tempo: '',
        tempo_contrato: '',
        unidade_id: '',
        cliente_id: '',
        produtos: [],
        template_versao_id: "",
        assinar_contrato: false,
        cobrar_multa: false,
        tipo_multa: '',
        valor_multa: 0,
        agrupar_vencidas: false,
        bonus_pont: false,
        porc_bonus_pont: '0',
        bonus_indicacao: false,
        cliente_indicacao: '',
        valor_indicacao: '0'
      },
      servico:{
        produto_id: '',
        descricao: '',
        formapagto_id: '',
        forma_pagto: '',
        valor: '',
        num_parcelas: '',
        contfin_id: '',
        conta_fin: '',
        venc_prim_parcela: '',
        venc_prim_parcela_sem_form: '',
        index: ''
      },
      alteraServico: false
    }))
  }

  const verificaUnidadeTempo = (value) => {
    if(value === 'DIA'){
      return 'day'
    }else if (value === 'MÊS'){
      return 'month'
    }else if (value === 'ANO'){
      return 'year'
    }
  }

  const updateFieldContrato = (event) => {
    const { dadosContrato, template_documentos, servico, conta, formasPagamento } = state

    const name = event.target.name
    const value = event.target.value

    dadosContrato[name] = value

    if((name === 'data_ini_vig' || name === 'unidade_tempo' || name === 'tempo_contrato') && dadosContrato.data_ini_vig !== '' && dadosContrato.unidade_tempo !== '' && dadosContrato.tempo_contrato !== ''){
      dadosContrato.data_fim_vig = moment(dadosContrato.data_ini_vig).add(dadosContrato.tempo_contrato, verificaUnidadeTempo(dadosContrato.unidade_tempo)).format('YYYY-MM-DD')   
    }

    if(name === 'unidade_tempo'){
      servico.intervalo_parcela = value
      if(name === 'intervalo_parcela'){
        if(conta.parcelas.length >= 1){
          if(conta.parcelas[0].datavencimento){
            verificaDataParcela( {target: {value: conta.parcelas[0].datavencimento}} , 1)
          }
        }
      }
    }

    if(name === "template_versao_id" && value !== ""){
      let versao_selecionada = template_documentos.list.filter(param => param.versao_id = parseInt(value))[0]
      dadosContrato.assinar_contrato = versao_selecionada.assinar
    }else{
      dadosContrato.assinar_contrato = false
    }

    if(name === "formapagto_indicacao_id"){
      const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(value))[0]
      
      dadosContrato.habilita = formaPagtoSelecionada.habilita
    }

    setState(prevState => ({...prevState, dadosContrato, servico }))
  }

  const addServicoNovoContrato = () => {

    if (!validateServico()) {
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: "Preencha os campos obrigatorios do serviço (*)."
        },
        loadingSalvar: false
      }))
      return
    }

    const {servico, produtos, formasPagamento, contasFinanceira, dadosContrato, conta} = state

    let parcelas = []

    if(servico.parcelado){
      for (const parcela of conta.parcelas) {

        if(parcela.valorparcela === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar o valor da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }

        if(parcela.datavencimento === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar a data de vencimento da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }

        if(parcela.formapagto_id === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar a forma de pagamento da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }

        if(parcela.contfin_id === ''){
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: ` Por favor informar a conta financeira da parcela ${parcela.index}`
            },
            loadingSalvar: false
          }))
          return
        }
      }

      parcelas = [...conta.parcelas]
    }

    let descricao_parcelamento = ''

    if(servico.parcelado){
      let contagem = contarValores(parcelas.map(value => {return value.valorparcela.replace("R$ ", "").replaceAll(".", "").replace(",", ".")})).sort((a, b) => (a.quantidade > b.quantidade) ? 1 : ((b.quantidade > a.quantidade) ? -1 : 0))
    
      for (let i = 0; i < contagem.length; i++) {
        const element = contagem[i];

        if((i + 1) === contagem.length){
          descricao_parcelamento += `${element.quantidade}x de R$ ${real(element.valor)}`
        }else{
          descricao_parcelamento += `${element.quantidade}x de R$ ${real(element.valor)}, `
        }
      }
    }

    const produto_selecionado = produtos.list.filter(param => param.id === parseInt(servico.produto_id))[0]
    const forma_selecionada = formasPagamento.list.filter(param => param.id === parseInt(servico.recorrencia ? servico.formapagto_id : parcelas[0].formapagto_id))[0]
    const conta_fin_selecionada = contasFinanceira.list.filter(param => param.id === parseInt(servico.recorrencia ? servico.contfin_id : parcelas[0].contfin_id))[0]
    
    let obj = {
      produto_id: parseInt(servico.produto_id),
      descricao: produto_selecionado.descricao,
      formapagto_id: servico.recorrencia ? parseInt(servico.formapagto_id) : '',
      forma_pagto: forma_selecionada.descricao,
      valor: servico.valor,
      num_parcelas: servico.recorrencia ? parseInt(servico.num_parcelas) : parcelas.length,
      contfin_id: servico.recorrencia ? parseInt(servico.contfin_id) : '',
      conta_fin: conta_fin_selecionada.descricao,
      venc_prim_parcela: servico.recorrencia ? servico.venc_prim_parcela : parcelas[0].datavencimento,
      recorrencia: servico.recorrencia,
      parcelado: servico.parcelado,
      recorrenciaACada:servico.recorrenciaACada,
      tipoRecorrencia: servico.tipoRecorrencia,
      qtdRecorrencia:servico.qtdRecorrencia,
      gerar_nfse: servico.gerar_nfse,
      parcelas,
      descricao_parcelamento: servico.recorrencia ? `${servico.qtdRecorrencia}x de R$ ${servico.valor}`  : descricao_parcelamento,
      intervalo_parcela: servico.intervalo_parcela,
      cobrar_sabados: servico.cobrar_sabados,
      cobrar_domingos: servico.cobrar_domingos
    }

    if(servico.index === ''){
      dadosContrato.produtos.push({
        ...obj,
        index: dadosContrato.produtos.length,
      })
    }else{
      dadosContrato.produtos[servico.index] = {
        ...obj,
        index: servico.index,
      }
    }

    setState(prevState => ({...prevState,
      dadosContrato,
      servico:{
        produto_id: '',
        descricao: '',
        formapagto_id: '',
        forma_pagto: '',
        valor: '',
        num_parcelas: '',
        contfin_id: '',
        conta_fin: '',
        venc_prim_parcela: '',
        recorrencia: false,
        parcelado: false,
        recorrenciaACada: 1,
        tipoRecorrencia: 'M',
        qtdRecorrencia: 1,
        gerar_nfse: false,
        intervalo_parcela: dadosContrato.unidade_tempo,
        cobrar_sabados: 'SIM',
        cobrar_domingos: 'SIM',
        index: ''
      },
      alteraServico: false,
      conta: {
        descricao: "",
        tipo: "A RECEBER",
        valortotal: "",
        numparcela: 1,
        item_id: '',
        tipo_item: 'ITEMRECDESP',
        qtd_parcelas: 1,
        gerar_nfse: false,
        parcelas: [
          {
            baixado: "",
            datavencimento: "",
            valorparcela: "",
            formapagto_id: "",
            documento: "",
            num: "",
            contfin_id: "",
            parcela_editada: false,
            index: 1
          }
        ]
      },
    }))
  }

  const editarServicoNovoContrato = (index) => {
    const {dadosContrato} = state
    
    let conta = {
      descricao: "",
      tipo: "A RECEBER",
      valortotal: dadosContrato.produtos[index].valor,
      numparcela: dadosContrato.produtos[index].parcelas.length,
      item_id: '',
      tipo_item: 'ITEMRECDESP',
      qtd_parcelas: dadosContrato.produtos[index].parcelas.length,
      gerar_nfse: false,
      parcelas: dadosContrato.produtos[index].parcelas
    }

    setState(prevState => ({...prevState,
      servico: dadosContrato.produtos[index],
      alteraServico: true,
      conta
    }))
    
  }

  const removerServicoNovoContrato = (row) => {
    let {dadosContrato} = state

    dadosContrato.produtos.splice(row.index, 1)

    setState(prevState => ({...prevState,
      dadosContrato
    }))
  }

  const updateFieldAssinar = () => {
    const { dadosContrato } = state
    dadosContrato.assinar_contrato = !dadosContrato.assinar_contrato
    setState(prevState => ({...prevState, dadosContrato }))
  }

  const confirmarNovoContrato = async (value) => {
    if (value){
      const {dadosContrato, responsavel} = state

      setState(prevState => ({...prevState,
        disabledButton: true       
      }))

      if (dadosContrato.data_ini_vig === "") {
        setState(prevState => ({...prevState,
          disabledButton: false,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar a data de inicio da vigência.'
          }
        }))
        return
      }

      const anoContrato = moment(dadosContrato.data_ini_vig).format('YYYY')
  
      if (parseInt(anoContrato) > 9999) {
        setState(prevState => ({...prevState,
          disabledButton: false,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Data de Inicio da Vigência Invalido!'
          }
        }))
        return
      }

      if(dadosContrato.produtos.length === 0){
        setState(prevState => ({...prevState,
          disabledButton: false,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Por favor informar ao menos um serviço no contratos!'
          }
        }))
        return
      }

      if(dadosContrato.cobrar_multa){
        if(dadosContrato.tipo_multa === ''){
          setState(prevState => ({...prevState,
            disabledButton: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o tipo da multa.'
            }
          }))
          return
        }

        if(dadosContrato.valor_multa === ''){
          setState(prevState => ({...prevState,
            disabledButton: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o valor da multa.'
            }
          }))
          return
        }
      }

      if(dadosContrato.bonus_pont){
        if(dadosContrato.porc_bonus_pont === '' || !dadosContrato.porc_bonus_pont){
          setState(prevState => ({...prevState,
            disabledButton: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a porcentagem do bônus de pontualidade.'
            }
          }))
          return
        }
      }

      if(dadosContrato.bonus_indicacao){
        if(dadosContrato.cliente_indicacao === '' || !dadosContrato.cliente_indicacao){
          setState(prevState => ({...prevState,
            disabledButton: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o cliente do bônus de indicação.'
            }
          }))
          return
        }

        if(dadosContrato.valor_indicacao === ''){
          setState(prevState => ({...prevState,
            disabledButton: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar o valor do bônus de indicação.'
            }
          }))
          return
        }

        if(dadosContrato.formapagto_indicacao_id === ''){
          setState(prevState => ({...prevState,
            disabledButton: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a forma de pagamento do bônus de indicação.'
            }
          }))
          return
        }

        if(dadosContrato.contfin_indicacao_id === ''){
          setState(prevState => ({...prevState,
            disabledButton: false,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'Por favor informar a conta fin. do bônus de indicação.'
            }
          }))
          return
        }
      }

      let dados = {
        codigo: dadosContrato.codigo,
        data_ini_vig: dadosContrato.data_ini_vig,
        data_fim_vig: dadosContrato.data_fim_vig,
        unidade_tempo: dadosContrato.unidade_tempo,
        tempo_contrato: dadosContrato.tempo_contrato,
        unidade_id: parseInt(dadosContrato.unidade_id),
        cliente_id: parseInt(dadosContrato.cliente_id),
        cobrar_multa: dadosContrato.cobrar_multa,
        agrupar_vencidas: dadosContrato.agrupar_vencidas,
        tipo_multa: dadosContrato.tipo_multa,
        valor_multa: parseFloat(dadosContrato.valor_multa.replace(".", "").replace(",", ".")),
        bonus_pont: dadosContrato.bonus_pont,
        porc_bonus_pont: parseFloat(dadosContrato.porc_bonus_pont.replace(".", "").replace(",", ".")),
        produtos: dadosContrato.produtos.map(value => {
          let dados = {
            produto_id:parseInt(value.produto_id),
            valor: parseFloat(value.valor.replace("R$ ", "").replaceAll(".", "").replace(",", ".")),
            num_parcelas: value.recorrencia ? parseInt(value.num_parcelas) : value.parcelas.length,
            formapagto_id: value.recorrencia ? parseInt(value.formapagto_id) : '',
            contfin_id: value.recorrencia ? parseInt(value.contfin_id) : '',
            venc_prim_parcela: value.recorrencia ? value.venc_prim_parcela : value.parcelas[0].datavencimento,
            recorrencia: value.recorrencia,
            recorrenciaACada: value.recorrenciaACada,
            tipoRecorrencia: value.tipoRecorrencia,
            qtdRecorrencia: value.qtdRecorrencia,
            gerar_nfse: value.gerar_nfse
          }

          if(value.parcelado){
            let parcelas = []

            for (const parcela of value.parcelas) {
              parcelas.push({
                valorparcela: parseFloat(parcela.valorparcela.replaceAll(".", "").replace(",", ".")),
                datavencimento: parcela.datavencimento,
                contfin_id: parseInt(parcela.contfin_id),
                formapagto_id: parseInt(parcela.formapagto_id),
                num: parcela.index,
                documento: parcela.documento
              })
            }

            dados.parcelas = parcelas
          }

          return {
            ...dados           
          }
        }),
        template_versao_id: dadosContrato.template_versao_id || '',
        assinar_contrato: dadosContrato.assinar_contrato
      }

      if(dadosContrato.bonus_indicacao){
        dados.bonus_indicacao = {
          cliente_indicacao: parseInt(dadosContrato.cliente_indicacao),
          valor: parseFloat(dadosContrato.valor_indicacao.replaceAll(".", "").replace(",", ".")),
          formapagto_id: dadosContrato.formapagto_indicacao_id,
          contfin_id: dadosContrato.contfin_indicacao_id
        }
      }

      let habilita_responsavel = false

      if(responsavel.tipoPessoa === "FISICA"){
        if(responsavel.pessoaFisica.cpf !== '') {
          habilita_responsavel = true
        }
      }else{
        if(responsavel.pessoaJuridica.cnpj !== '') {
          habilita_responsavel = true
        }
      }

      if(props.cliente.tipoPessoa === "FISICA" && !props.cliente.pessoaFisica.maiorIdade && !habilita_responsavel){
        setState(prevState => ({...prevState,
          disabledButton: false,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Esse cliente é menor de idade, por favor informar o responsável.'
          },
          tabContrato: 1
        }))
        return
      }

      if(habilita_responsavel){
        dados.responsavel = {
          tipo_pessoa: responsavel.tipoPessoa,
          contato: responsavel.contato,
          email: responsavel.email,
          endereco: responsavel.endereco
        }
      
        if(responsavel.tipoPessoa === "FISICA"){
          dados.responsavel.pessoaFisica = responsavel.pessoaFisica
        }else{
          dados.responsavel.pessoaJuridica = responsavel.pessoaJuridica
        }
      
        if(dados.responsavel.endereco.cep === ''){
          delete dados.responsavel.endereco
        }else{
          if(dados.responsavel.endereco.numero === '' || !dados.responsavel.endereco.numero){
            setState(prevState => ({...prevState,
              disabledButton: false,
              alerta: {
                open: true,
                severity: 'warning',
                message: 'Por favor informar a o número do endereço do responsavel.'
              }
            }))
            return
          }
        }

        if(dados.responsavel.email.email === ''){
          delete dados.responsavel.email
        }

        if(dados.responsavel.contato.numero === ''){
          delete dados.responsavel.contato
        }

      }

      if(dados.data_fim_vig === ''){
        delete dados.data_fim_vig
      }

      if(dados.unidade_tempo === ''){
        delete dados.unidade_tempo
      }

      if(dados.tempo_contrato === ''){
        delete dados.tempo_contrato
      }

      if(dados.codigo === ''){
        delete dados.codigo
      }
      
      try {

        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'info',
            message: 'Adicionando novo contrato...'
          }
        }))

        console.log(dados)

        let {data} = await axios.post(`${window._env_.REACT_APP_API_URL}/contrato/novoContrato`, dados, getToken())   
        
        if(data.contas_criadas.length > 0){
          for (const conta_pr of data.contas_criadas) {
            if(conta_pr.forma_pgto === 'boleto'){
              await axios.post(`${window._env_.REACT_APP_API_URL}/boleto`, conta_pr, getToken())
            }else if(conta_pr.forma_pgto === 'pix'){
              // await axios.post(`${window._env_.REACT_APP_API_URL}/pix`, conta_pr, getToken())
            }
          }
        }

        setState(prevState => ({...prevState,
          alerta: {
            open: false,
            severity: 'info',
            message: ''
          }
        }))
        window.location.reload()

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          disabledButton: false,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
        return
      }
    }else{
      handleCloseModalNovoContrato()
    }
  }

  const handleCloseModalAdicionarServico = () => {
    setState(prevState => ({...prevState,
      openModalAdicionarServico: false
    }))
  }

  const adicionarServico = async () => {
    await consultarContasBancarias(props.cliente.unidade_id)
    setState(prevState => ({...prevState,
      openModalAdicionarServico: true
    }))
  }

  const handleChange = (event, newValue) => {
    setState(prevState => ({...prevState,
      tabContrato: newValue
    }))
  };

  const updateFieldSearchResp = (event) => {
    const { searchResp } = state

    searchResp[event.target.name] = event.target.value

    setState(prevState => ({...prevState, searchResp }))
  }

  const buscarResp = async () => {
    const { searchResp, cliente } = state

    if(searchResp.cpf.length !== 18 && searchResp.cpf.length !== 14){
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'warning',
          message: 'CPF/CNPJ Invalido! Por favor corrija o campo.'
        }
      }))
      return
    }

    let buscaPor
    const search = searchResp.cpf

    let unidade_id = props.cliente.unidade_id

    if(searchResp.cpf.length === 14){
      buscaPor = 'cpf'

      if (!cpf.isValid(cpf.format(search))) {
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'CPF Invalido! Por favor corrija o campo CPF.'
          }
        }))
        return
      }

      if(props.cliente.tipoPessoa === 'FISICA'){
        if (cpf.format(search) === cpf.format(props.cliente.pessoaFisica.cpf)) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'O CPF do Responsável não pode ser igual ao do cliente.'
            }
          }))
          return
        }
      }

      try {
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/fisica/search/${cpf.strip(search)}`, { buscaPor, busca: 'responsavel', unidade_id }, getToken())
        
        let endereco_principal = {
          id: "",
          cep_id: "",
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: 1
        }

        if(data.pessoa.enderecos.filter(param => param.principal)[0]){
          let endereco_aux = data.pessoa.enderecos.filter(param => param.principal)[0]

          endereco_principal = {
            id: endereco_aux.id,
            rua: endereco_aux.logradouro,
            cep_id: endereco_aux.cep_id,
            complemento: "",
            numero: "",
            bairro: endereco_aux.bairro,
            cidade: endereco_aux.cidade,
            estado: endereco_aux.estado,
            cep: endereco_aux.num_cep,
            principal: true,
            tipoEndereco: endereco_aux.tipo,
            index: 1
          }
        }

        let email_principal = {
          id: "",
          email: "",
          principal: true,
          index: 1
        }

        if(data.pessoa.emails.filter(param => param.principal)[0]){
          email_principal = data.pessoa.emails.filter(param => param.principal)[0]
        }

        let contato_principal = {
          id: "",
          numero: "",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }

        if(data.pessoa.telefones.filter(param => param.principal)[0]){
          let contato_aux = data.pessoa.telefones.filter(param => param.principal)[0]
          contato_principal = {
            numero: contato_aux.numero,
            tipoContato: contato_aux.tipo,
            principal: contato_aux.principal,
            index: 1
          }
        }

        let responsavel = {
          tipoPessoa: 'FISICA',
          pessoaFisica: {
            id: data.id,
            pessoa_id: data.pessoa_id,
            nome: data.nome,
            sobrenome: data.sobrenome,
            cpf: cpf.format(data.cpf),
            rg: data.rg ? data.rg : '',
            rg_orgao_emissor: data.rg_orgao_emissor ? data.rg_orgao_emissor : '',
            rg_uf: data.rg_uf ? data.rg_uf : '',
            rg_data_emissao: data.rg_data_emissao ? data.rg_data_emissao : '',
            dataNascimento: data.datanascimento,
            dadosComplementar: {
              sexo: '',
              num_calcado: '',
              receber_novidades: '',
              quantidade_filhos: '',
              profissao: '',
              data_casamento: '',
              rg: '',
              rg_orgao_emissor: '',
              rg_uf: '',
              rg_data_emissao: '',
              estado_civil: '',
              como_conheceu_id: '',
            }
          },
          pessoaJuridica: {
            id: "",
            pessoa_id: '',
            razaoSocial: '',
            nomeFantasia: '',
            cnpj: '',
            inscricaoEstadual: '',
            dataFundacao: '',
          },
          endereco: endereco_principal,
          email: email_principal,
          contato: contato_principal
        }

        setState(prevState => ({...prevState, responsavel, enableBuscaResp: false }))
      } catch (error) {
        let responsavel = {
          tipoPessoa: 'FISICA',
          pessoaFisica: {
            id: "",
            pessoa_id: '',
            nome: "",
            cpf: cpf.format(searchResp.cpf),
            rg: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: "",
            dataNascimento: "",
            dadosComplementar: {
              sexo: '',
              num_calcado: '',
              receber_novidades: '',
              quantidade_filhos: '',
              profissao: '',
              data_casamento: '',
              rg: '',
              rg_orgao_emissor: '',
              rg_uf: '',
              rg_data_emissao: '',
              estado_civil: '',
              como_conheceu_id: '',
            }
          },
          pessoaJuridica: {
            id: "",
            pessoa_id: '',
            razaoSocial: '',
            nomeFantasia: '',
            cnpj: '',
            inscricaoEstadual: '',
            dataFundacao: '',
          },
          endereco: {
            id: "",
            cep_id: "",
            rua: "",
            complemento: "",
            numero: "",
            bairro: "",
            cidade: "",
            estado: "",
            cep: "",
            principal: true,
            tipoEndereco: "RESIDENCIAL",
            index: 1
          },
          email: {
            email: "",
            principal: true,
            index: 1
          },
          contato: {
            numero: "",
            tipoContato: "RESIDENCIAL",
            principal: true,
            index: 1
          },
        }
        setState(prevState => ({...prevState, responsavel, enableBuscaResp: false }))
      }
    }else{
      if(props.cliente.tipoPessoa === 'JURIDICA'){
        if (search === format(cliente.pessoaJuridica.cnpj)) {
          setState(prevState => ({...prevState,
            alerta: {
              open: true,
              severity: 'warning',
              message: 'O CNPJ do Responsável não pode ser igual ao do cliente.'
            }
          }))
          return
        }
      }

      try {
        buscaPor = 'cnpj'
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/fisica/search/${search.replaceAll('.','').replaceAll('/','').replaceAll('-','')}`, { buscaPor, busca: 'responsavel', unidade_id }, getToken())

        let endereco_principal = {
          id: "",
          cep_id: "",
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: 1
        }

        if(data.pessoa.enderecos.filter(param => param.principal)[0]){
          let endereco_aux = data.pessoa.enderecos.filter(param => param.principal)[0]

          endereco_principal = {
            rua: endereco_aux.logradouro,
            complemento: "",
            numero: "",
            bairro: endereco_aux.bairro,
            cidade: endereco_aux.cidade,
            estado: endereco_aux.estado,
            cep: endereco_aux.num_cep,
            principal: true,
            tipoEndereco: "RESIDENCIAL",
            index: 1
          }
        }

        let email_principal = {
          email: "",
          principal: true,
          index: 1
        }

        if(data.pessoa.emails.filter(param => param.principal)[0]){
          email_principal = data.pessoa.emails.filter(param => param.principal)[0]
        }

        let contato_principal = {
          numero: "",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }

        if(data.pessoa.telefones.filter(param => param.principal)[0]){
          let contato_aux = data.pessoa.telefones.filter(param => param.principal)[0]
          contato_principal = {
            numero: contato_aux.numero,
            tipoContato: contato_aux.tipo,
            principal: contato_aux.principal,
            index: 1
          }
        }

        let responsavel = {
          tipoPessoa: 'JURIDICA',
          pessoaFisica: {
            id: "",
            pessoa_id: '',
            nome: "",
            cpf:  "",
            rg: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: "",
            dataNascimento: "",
            dadosComplementar: {
              sexo: '',
              num_calcado: '',
              receber_novidades: '',
              quantidade_filhos: '',
              profissao: '',
              data_casamento: '',
              rg: '',
              rg_orgao_emissor: '',
              rg_uf: '',
              rg_data_emissao: '',
              estado_civil: '',
              como_conheceu_id: '',
            }
          },
          pessoaJuridica: {
            id: data.id,
            pessoa_id: data.pessoa_id,
            razaoSocial: data.razaosocial,
            nomeFantasia: data.nomefantasia,
            cnpj: search,
            inscricaoEstadual: data.inscricaoestadual,
            dataFundacao: data.datafundacao,
          },
          endereco: endereco_principal,
          email: email_principal,
          contato: contato_principal,
        }
        setState(prevState => ({...prevState, responsavel, enableBuscaResp: false }))
      } catch ({ response }) {
        let responsavel = {
          tipoPessoa: 'JURIDICA',
          pessoaFisica: {
            id: "",
            pessoa_id: '',
            nome: "",
            cpf:  "",
            rg: "",
            rg_orgao_emissor: "",
            rg_uf: "",
            rg_data_emissao: "",
            dataNascimento: "",
            dadosComplementar: {
              sexo: '',
              num_calcado: '',
              receber_novidades: '',
              quantidade_filhos: '',
              profissao: '',
              data_casamento: '',
              rg: '',
              rg_orgao_emissor: '',
              rg_uf: '',
              rg_data_emissao: '',
              estado_civil: '',
              como_conheceu_id: '',
            }
          },
          pessoaJuridica: {
            id: "",
            pessoa_id: '',
            razaoSocial: '',
            nomeFantasia: '',
            cnpj: search,
            inscricaoEstadual: '',
            dataFundacao: '',
          },
          endereco: {
            id: "",
            cep_id: "",
            rua: "",
            complemento: "",
            numero: "",
            bairro: "",
            cidade: "",
            estado: "",
            cep: "",
            principal: true,
            tipoEndereco: "RESIDENCIAL",
            index: 1
          },
          email: {
            email: "",
            principal: true,
            index: 1
          },
          contato: {
            numero: "",
            tipoContato: "RESIDENCIAL",
            principal: true,
            index: 1
          },
        }

        setState(prevState => ({...prevState, responsavel, enableBuscaResp: false }))
      }
    }    
  }

  const updateFieldPessoaFisicaResponsavel = (event) => {
    let {responsavel} = state

    responsavel.pessoaFisica[event.target.name] = event.target.value

    setState(prevState => ({...prevState,responsavel}))
  }

  const validarCpfCnpjResponsavel = async (event) => {

    let cpf_cnpj = event.target.value
    const responsavel = state.responsavel
    cpf_cnpj = cpf.format(cpf_cnpj)


    const dados = {
      id: responsavel.id
    }

    if (cpf_cnpj !== '000.000.000-00') {
      try {
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, dados, getToken())

        if (data) {
          responsavel.pessoaFisica.cpf = ""
          setState(prevState => ({...prevState, responsavel }))

          const erro = {
            titulo: "CPF Invalido!",
            descricao: "Já existe um cadastro com este CPF"
          }
          setState(prevState => ({...prevState,
            modalErro: true,
            erro
          }))
        } else {
          responsavel.pessoaFisica.cpf = cpf_cnpj
          setState(prevState => ({...prevState, responsavel }))
        }

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
  
        backPage()
      }
    } else if (!cpf.isValid(cpf_cnpj)) {
      responsavel.pessoaFisica.cpf = ""
      setState(prevState => ({...prevState, responsavel }))

      const erro = {
        titulo: "CPF Invalido!",
        descricao: "Por favor corrija o campo CPF"
      }
      setState(prevState => ({...prevState,
        modalErro: true,
        erro
      }))
    } else {
      try {
        const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/fisica/verificaCPF/${cpf_cnpj}`, dados, getToken())

        if (data) {
          responsavel.pessoaFisica.cpf = ""
          setState(prevState => ({...prevState, responsavel }))

          const erro = {
            titulo: "CPF Invalido!",
            descricao: "Já existe um cadastro com este CPF"
          }
          setState(prevState => ({...prevState,
            modalErro: true,
            erro
          }))
        } else {
          responsavel.pessoaFisica.cpf = cpf_cnpj
          setState(prevState => ({...prevState, responsavel }))
        }

      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
  
        backPage()
      }
    }
  }

  const pegaEnderecoResponsavel = async (event, index) => {
    let aux = event.target.value.trim()

    if (aux === "") return false

    if (event.target.name === "cep") {
      const responsavel = { ...state.responsavel }
      const endereco_aux = responsavel.endereco
      let endereco = {
        rua: "Carregando...",
        bairro: "Carregando...",
        cidade: "Carregando...",
        estado: "Carregando...",
        numero: endereco_aux.numero,
        complemento: endereco_aux.complemento,
        cep: formatarCEP(endereco_aux.cep),
        principal: endereco_aux.principal,
        tipoEndereco: endereco_aux.tipoEndereco,
        index: endereco_aux.index
      }

      responsavel.endereco = endereco
      setState(prevState => ({...prevState, responsavel }))

      try {
        const resp = await getEndereco(event.target.value)

        let endereco = {
          cep_id: resp.id,
          complemento: endereco_aux.complemento,
          rua: resp.logradouro,
          numero: endereco_aux.numero,
          bairro: resp.bairro.nome,
          cidade: resp.bairro.cidade.nome,
          estado: resp.bairro.cidade.uf.descricao,
          cep: formatarCEP(endereco_aux.cep),
          principal: endereco_aux.principal,
          tipoEndereco: endereco_aux.tipoEndereco,
          index: endereco_aux.index
        }

        responsavel.endereco = endereco
        setState(prevState => ({...prevState, responsavel }))

        setState(prevState => ({...prevState,
          disableEndereco: true
        }))
      } catch (error) {
        console.log(error)

        let endereco = {
          rua: "",
          bairro: "",
          cidade: "",
          estado: "",
          numero: endereco_aux.numero,
          cep: "",
          complemento: endereco_aux.complemento,
          principal: endereco_aux.principal,
          tipoEndereco: endereco_aux.tipoEndereco,
          index: endereco_aux.index
        }

        responsavel.endereco = endereco
        setState(prevState => ({...prevState, responsavel }))

        setState(prevState => ({...prevState,
          modalErro: true,
          erro: {
            titulo: 'Erro!',
            descricao: 'CEP Não encontrado!'
          }
        }))

      }
    }
  }

  const formataTelefoneResponsavel = (event) => {
    if(event.target.value !== ''){
      let texto = event.target.value.trim()
  
      texto = texto.replace(/\D/g,""); 
      
      if(texto.length === 11 || texto.length === 10){
        texto = texto.replace(/^(\d{2})(\d)/g,"($1) $2")
        texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
      }else if(texto.length === 12){
        texto = texto.replace(/^(\d{3})(\d)/g,"($1) $2")
        texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
      }else if(texto.length === 8){
        texto = texto.replace(/(\d)(\d{4})$/,"$1-$2")
      }else{
        setState(prevState => ({...prevState,
          alerta: {
            open: true,
            severity: 'warning',
            message: 'Telefone Invalido'
          }
        }))

        const responsavel = { ...state.responsavel }
        responsavel.contato.numero = ''
        setState(prevState => ({...prevState, responsavel }))

        return false

      }
    
      const responsavel = { ...state.responsavel }
      responsavel.contato.numero = texto
      setState(prevState => ({...prevState, responsavel }))
    }
  }

  const updateFieldContatoResponsavel = (event) => {
    let {responsavel} = state

    responsavel.contato[event.target.name] = event.target.value

    setState(prevState => ({...prevState,responsavel}))
  }

  const updateFieldEnderecoResponsavel = (event) => {
    let {responsavel} = state

    responsavel.endereco[event.target.name] = event.target.value

    setState(prevState => ({...prevState,responsavel}))
  }

  const updateFieldEmailResponsavel = (event) => {
    let {responsavel} = state

    responsavel.email[event.target.name] = event.target.value

    setState(prevState => ({...prevState,responsavel}))
  }

  const handleCloseModalContrato = () => {
    setState(prevState => ({...prevState,
      openModalContrato: false
    }))
  }

  const imprimirContratoMatricula = async () => {
    let {contrato} = state

    try {

      setState(prevState => ({...prevState,
        loadingImprimir: true,
        alerta: {
          open: true,
          severity: 'info',
          message: 'Carregando Contrato'
        }
      }))

      const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/contrato/viewContrato/${contrato._id}`, getToken())
      
      const dadosImprimir = {
        path: data
      }

      const resp = await axios.post(`${window._env_.REACT_APP_API_URL}/imprimirPDF`, dadosImprimir, { responseType: 'blob' })
  
      const pdfBlob = new Blob([resp.data], { type: 'application/pdf' });

      setState(prevState => ({...prevState,
        loadingImprimir: false,
        alerta: {
          open: false,
          severity: 'info',
          message: ''
        }
      }))

      saveAs(pdfBlob, `Contrato_${contrato._id}.pdf`)

    }catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateRecorrencia = () => {
    let {servico} = state

    servico.recorrencia = !servico.recorrencia
    servico.parcelado = false
    servico.num_parcelas = 1
    servico.qtd_parcelas = 1
    servico.recorrenciaACada = 1
    servico.tipoRecorrencia = 'M'
    servico.qtdRecorrencia = 1
    
    setState(prevState => ({...prevState,servico}))
  }

  const updateParcelado = () => {
    let {servico} = state
    servico.parcelado = !servico.parcelado
    servico.recorrencia = false
    setState(prevState => ({...prevState,servico}))
  }

  const marcaParcelas = (event, index) => {
    
    const {conta, formasPagamento} = state
        
    const formaPagtoSelecionada = formasPagamento.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < conta.parcelas.length; i++) {
      if(formaPagtoSelecionada){
        conta.parcelas[i].formapagto_id = event.target.value
        conta.parcelas[i].habilita = formaPagtoSelecionada.habilita
        conta.parcelas[i].contfin_id = ""
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const marcaParcelasContaFin = (event, index) => {
    
    const {conta, contasFinanceira} = state
        
    const contaFinanceiraSelecionada = contasFinanceira.list.filter(param => param.id === parseInt(event.target.value))[0]
    
    for (let i = index; i < conta.parcelas.length; i++) {
      if(contaFinanceiraSelecionada){
        if(conta.parcelas[i].habilita === contaFinanceiraSelecionada.tipo_conta){
          conta.parcelas[i].contfin_id = event.target.value
        }
      }
    }

    setState(prevState => ({...prevState, conta }))
  }

  const consultarTemplatesDocumento = async (unidade_id) => {
    try {
      const dados = {
        unidadesnegocio: [unidade_id]
      }

      const { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/templatesdocumentos/list`, dados, getToken())

      let array = []

      for (const template of data) {
        for (const versao of template.versoes) {
          if(versao.ativo && versao.publicado && moment(versao.datainicio).isBefore(moment())){
            array.push({
              template_id: template.id,
              versao_id: versao.id,
              titulo: template.titulo,
              assinar: template.assinar,
              versao: versao.versao,
              ativo: versao.ativo
            })
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        template_documentos: {
          list: array
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        },
        loading: false
      }))
    }
  }

  const habilitaServico = () => {
    setState((prevState) => ({
      ...prevState,
      habilita_servico: !state.habilita_servico
    }))
  }

  const updateFieldCobrarMulta = () => {
    let { dadosContrato } = state

    dadosContrato.cobrar_multa = !dadosContrato.cobrar_multa

    setState((prevState) => ({
      ...prevState,
      dadosContrato
    }))
  }

  const updateFieldExibirInativos = () => {
    setState((prevState) => ({
      ...prevState,
      exibir_inativados: !state.exibir_inativados
    }))
  }

  const updateFieldAgruparVencidas = () => {
    let { dadosContrato } = state

    dadosContrato.agrupar_vencidas = !dadosContrato.agrupar_vencidas

    setState((prevState) => ({
      ...prevState,
      dadosContrato
    }))
  }

  const updateFieldBonusPontualidade = () => {
    let { dadosContrato } = state

    dadosContrato.bonus_pont = !dadosContrato.bonus_pont

    setState((prevState) => ({
      ...prevState,
      dadosContrato
    }))
  }

  const updateFieldBonusIndicacao = () => {
    let { dadosContrato } = state

    dadosContrato.bonus_indicacao = !dadosContrato.bonus_indicacao

    setState((prevState) => ({
      ...prevState,
      dadosContrato
    }))
  }

  const updateFiltroPessoa = async (event) => {
    if(event.target.value.length >= 3){
      await consultarPessoa(event.target.value)
    }else if(event.target.value.length < 3){
      setState(prevState => ({...prevState,
        clientes: {
          list: []
        }
      }))
    }
  }

  const consultarPessoa = async (nome) => {
    try {

      const acesso_atual = JSON.parse(localStorage.getItem('acesso_atual'))
  
      let filtroPessoa = {
        unidadesnegocio: acesso_atual.map(acesso => {
          return acesso.id
        }),
        ativo: 'Sim',
        nome: nome
      }

      let { data } = await axios.post(`${window._env_.REACT_APP_API_URL}/pessoa/listSearch`, filtroPessoa, getToken())

      data = JSON.parse(decrypt(data))

      const pessoas = data.map(pessoa => {
        return {
          pessoa_id: pessoa.id,
          nome: pessoa.nome + (pessoa.sobrenome ? ` ${pessoa.sobrenome}` : ''),
        }
      })

      setState(prevState => ({...prevState,
        clientes: {
          list: pessoas.sort((a, b) => (a.nome > b.nome) ? 1 : (b.nome > a.nome) ? -1 : 0)
        }
      }))

    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
  }

  const updateFieldCliente = (event, value) =>{
    const {dadosContrato} = state
    if(!value){
      setState(prevState => ({...prevState,
        clienteIndicacao: {
          id: '',
          nome: '',
          cpf_cnpj: '',
          pessoa_id: ''
        }
      }))

      return false
    }

    dadosContrato.cliente_indicacao = value.pessoa_id

    setState(prevState => ({...prevState,
      dadosContrato,
      clienteIndicacao: value
    }))
  }

  const inicia_tela = async () => {
    setState(prevState => ({...prevState,
      cliente: {
        _id: "",
        pessoaFisica: {
          nome: "",
          cpf: "",
          rg: "",
          dataNascimento: "",
          idade: 0,
          maiorIdade: false
        },
        enderecos: [{
          rua: "",
          complemento: "",
          numero: "",
          bairro: "",
          cidade: "",
          estado: "",
          cep: "",
          principal: true,
          tipoEndereco: "RESIDENCIAL",
          index: 1
        }],
        emails: [{
          email: "",
          principal: true,
          index: 1
        }],
        contatos: [{
          numero: "",
          tipoContato: "RESIDENCIAL",
          principal: true,
          index: 1
        }],
        contrato: {
          contm_data_inicio_vigencia: "",
          contm_duracao: "",
          contm_observacao: "",
          contm_curso_id: "",
          contm_ativo: true,
          contm_unin_id: ""
        }
      },
      contrato: {
        _id: '',
        estado_contrato: '',
        codigo: '',
        data_ini_vig: '',
        data_fim_vig: '',
        tempo_contrato: '',
        unidade_tempo: '',
        unidade: {
          numero: '',
          descricao: ''
        }
      },
      contratos: {
        list: []
      },
      conta: {
        descricao: "",
        tipo: "A RECEBER",
        valortotal: "",
        numparcela: 1,
        item_id: '',
        tipo_item: 'ITEMRECDESP',
        qtd_parcelas: 1,
        gerar_nfse: false,
        parcelas: [
          {
            baixado: "",
            datavencimento: "",
            valorparcela: "",
            formapagto_id: "",
            documento: "",
            num: "",
            contfin_id: "",
            parcela_editada: false,
            index: 1
          }
        ]
      },
    }))

    setState(prevState => ({...prevState,
      loading: true
    }))

    setState(prevState => ({...prevState,msgLoading: 'Carregando Dados do Contrato...'}))
    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.slug === 'cliente')[0]

    let permissoesContrato = perfil.permissoes.filter(param => param.tela.modulo.slug === 'contrato')[0]

    setState(prevState => ({...prevState,
      permissoes
    }))

    if(permissoesContrato){

      if(permissoesContrato.inserir || permissoesContrato.alterar){
        setState(prevState => ({...prevState,
          acoesTabelaServico: ['removeServico', 'updateServico']
        }))
      }

      setState(prevState => ({...prevState,
        permissoesContrato
      }))
    }

    try {
      const { data: formasPagamento } = await axios.get(`${window._env_.REACT_APP_API_URL}/formapagto`, getToken())

      setState(prevState => ({...prevState,
        formasPagamento: {
          list: formasPagamento.filter(param => param.ativo === true && param.slug !== 'voucher_produto')
        }
      }))

      const { data: configuracao } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${props.cliente.unidade_id}`, getToken())
        
      if(configuracao){
        setState(prevState => ({...prevState,
          possui_emissao_nfse: configuracao.habilitar_emissao,
          exibir_opcao_multa_contrato: configuracao.exibir_opcao_multa_contrato,
          exibir_opcao_pontualidade_contrato: configuracao.exibir_opcao_pontualidade_contrato,
          exibir_opcao_bonus_indicacao_contrato: configuracao.exibir_opcao_bonus_indicacao_contrato
        }))
      }else{
        setState(prevState => ({...prevState,
          possui_emissao_nfse: false,
          exibir_opcao_multa_contrato: false,
          exibir_opcao_pontualidade_contrato: false,
          exibir_opcao_bonus_indicacao_contrato: false
        }))
      }
      
    } catch (error) {
      console.log(error)
      setState(prevState => ({...prevState,
        alerta: {
          open: true,
          severity: 'error',
          message: error.response ? error.response.data.message : 'Erro Interno'
        }
      }))
    }
   
    if(props.cliente.cliente){
      
      const cliente = props.cliente.cliente
      
      try {
        const { data } = await axios.get(`${window._env_.REACT_APP_API_URL}/cliente/contratos/${cliente.id}`, getToken())
  
        setState(prevState => ({...prevState,
          contratos: {
            list: data.map(contrato => {
              let estado_contrato = ''
              if (contrato.statusAtual.estado_contrato === 'ATIVO') {
                estado_contrato = 'Ativo'
              } else if (contrato.statusAtual.estado_contrato === 'INATIVO') {
                estado_contrato = 'Inativo'
              } else if (contrato.statusAtual.estado_contrato === 'CANCELADO') {
                estado_contrato = 'Cancelado'
              } else if (contrato.statusAtual.estado_contrato === 'ENCERRADO') {
                estado_contrato = 'Encerrado'
              } else if (contrato.statusAtual.estado_contrato === 'AGUARDANDO_ASSINATURA') {
                estado_contrato = 'Aguardando Assinatura'
              }
  
              return {
                _id: contrato.id,
                estado_contrato,
                codigo: contrato.codigo,
                data_ini_vig: moment(contrato.data_ini_vig).format('DD/MM/YYYY'),
                data_fim_vig: contrato.data_fim_vig ? moment(contrato.data_fim_vig).format('DD/MM/YYYY') : '',
                tempo_contrato: contrato.tempo_contrato,
                codigo_assinatura: contrato.codigo_assinatura ? decrypt(contrato.codigo_assinatura) : '',
                unidade_tempo: contrato.unidade_tempo,
              }
            })
          }
        }))
  
        if(props.cliente.contrato){
          const { data: configuracao } = await axios.get(`${window._env_.REACT_APP_API_URL}/configuracaoUnidade/byUnidade/${props.cliente.contrato.unidade_id}`, getToken())
          
          setState(prevState => ({...prevState,
            possui_emissao_nfse: configuracao ? configuracao.habilitar_emissao : false
          }))
        }


      } catch (error) {
        console.log(error)
        setState(prevState => ({...prevState,
          loading: false,
          alerta: {
            open: true,
            severity: 'error',
            message: error.response ? error.response.data.message : 'Erro Interno'
          }
        }))
        return
      }
    }

    setState(prevState => ({...prevState,
      loading: false
    }))
  }

  useEffect(() => {
    const fetchData = async () => {
      await inicia_tela()
    };
    
    fetchData();
  }, []);

  const {contrato, servico, produtos, formasPagamento, contasFinanceira, template_documentos,
    produtoSelecionado, produtoSelecionadoRenovar, permissoesContrato, possui_emissao_nfse} = state

  return (
    <React.Fragment>
      {state.loading &&
        <React.Fragment>
          <div className="progressCircular">
            <CircularProgress />
          </div>
          <div className="progressText">
            <p>{state.msgLoading}</p>
          </div>
        </React.Fragment>
      }
      {!state.loading &&
        <div>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} sm={12} xs={12}>
              <h4 className='titulo'>Contratos:</h4>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction="row" className="mg_top_10">
            <Grid item md={12} xs={12}>
              <Table 
                headCell={state.cabecalhoTabela} 
                rows={state.contratos.list} 
                acoes={state.acoesTabela} 
                viewContrato={e => viewContrato(e)} 
                qtdLinhas={state.contratos.list.length}
                desabilitaRodape={true}
              />
            </Grid>
          </Grid>
          {permissoesContrato.inserir &&
            <Grid container spacing={1} direction="row">
              <Grid item md={9} sm={12} xs={9}></Grid>
              <Grid item md={3} sm={12} xs={3}>
                <Button
                  size='small'
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => novoContrato()}
                >
                  Adicionar Contrato
                </Button>
              </Grid>
            </Grid>
          }
        </div>
      }
      <Alert 
        open={state.alerta.open}
        handleClose={e => handleCloseAlerta()} 
        severity={state.alerta.severity}
        message={state.alerta.message} 
      />

      <ModalCancelarServico 
        open={state.modalCancelarServico}
        handleClose={e => handleCloseModalCancelarServico()} 
        financeiros_pendentes={state.financeiros_pendentes.list}
        dados={state.cancelarServico}
        updateFieldLancFinanceiroCancelamento={e => updateFieldLancFinanceiroCancelamento(e)}
        conta={state.conta}
        updateFieldConta={e => updateFieldConta(e)}
        mudarParcelas={e => mudarParcelas(e)}
        recalcularParcela={(e, value) => recalcularParcela(e, value)}
        updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
        formasPagamento={state.formasPagamento.list}
        confirmar={e => confirmarCancelamentoServico(e)}
        updateField={e => updateFieldCancelarServico(e)}
        planoContas={state.planoContas.list}
        contasFinanceira={state.contasFinanceira.list}
        itens={state.itens.list}
        possui_emissao_nfse={possui_emissao_nfse}
        updateFieldGerarNfseConta={(e) => updateFieldGerarNfseConta(e)}
      />

      <ModalCancelarContrato
        open={state.modalCancelarContrato}
        handleClose={e => handleCloseModalCancelarServico()} 
        financeiros_pendentes={state.financeiros_pendentes.list}
        dados={state.cancelarServico}
        updateFieldLancFinanceiroCancelamento={e => updateFieldLancFinanceiroCancelamento(e)}
        conta={state.conta}
        updateFieldConta={e => updateFieldConta(e)}
        mudarParcelas={e => mudarParcelas(e)}
        recalcularParcela={(e, value) => recalcularParcela(e, value)}
        updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
        formasPagamento={state.formasPagamento.list}
        confirmar={e => confirmarCancelamentoContrato(e)}
        updateField={e => updateFieldCancelarServico(e)}
        planoContas={state.planoContas.list}
        contasFinanceira={state.contasFinanceira.list}
        itens={state.itens.list}
        possui_emissao_nfse={possui_emissao_nfse}
        updateFieldGerarNfseConta={(e) => updateFieldGerarNfseConta(e)}
      />

      <ModalAtualizaServico 
        open={state.modalAtualizaServico}
        dados={state.servico}
        handleClose={e => handleCloseModalAtualizaServico()} 
        updateServico={e => updateServico(e)}
        produtos={produtos}
        formasPagamento={formasPagamento}
        contasFinanceira={contasFinanceira}
        financeiros_pendentes={state.financeiros_pendentes.list}
        confirmar={e => confirmarAlteracaoServico(e)}
        possui_emissao_nfse={possui_emissao_nfse}
        updateFieldGerarNfse={(e) => updateFieldGerarNfse(e)}
      />

      <ModalAlterarBoleto 
        open={state.modalAlterarBoleto }
        handleClose={e => handleCloseModalAtualizaServico()} 
        boletosVencidos={state.boletosVencidos}
        updateFieldBoleto={(e, id) => updateFieldBoleto(e, id)} 
        confirmar={e => finalizarCancelamentoServico(e)}
      />

      <ModalAlterarBoletoCancelamento 
        open={state.modalAlterarBoletoCancelamento }
        handleClose={e => handleCloseModalAtualizaServico()} 
        boletosVencidos={state.boletosVencidos}
        updateFieldBoleto={(e, id) => updateFieldBoleto(e, id)} 
        confirmar={e => finalizarCancelamentoContrato(e)}
      />

      <ModalRenovarContrato
        open={state.modalRenovarContrato}
        handleClose={e => handleCloseModalRenovarContrato()}
        dadosRenovarContrato={state.dadosRenovarContrato}
        produtos={produtos}
        formasPagamento={formasPagamento}
        contasFinanceira={contasFinanceira}
        servico={servico}
        updateServico={(e) => updateServico(e)}
        confirmar={(e) => confirmarRenovacao(e)}
        produtoSelecionado={produtoSelecionadoRenovar}
        disabledButton={state.disabledButton}
        contrato={contrato}
        updateDadosRenovar={(e) => updateDadosRenovar(e)}
        updateRecorrencia={(e) => updateRecorrencia(e) }
        updateParcelado={(e) => updateParcelado(e) }
        conta={state.conta}
        recalcularParcela={(e, value) => recalcularParcela(e, value)}
        mudarParcelas={e => mudarParcelas(e)}
        updateFieldConta={e => updateFieldConta(e)}
        updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
        marcaParcelas={(e, index) => marcaParcelas(e, index)}
        marcaParcelasContaFin={(e, index) => marcaParcelasContaFin(e, index)}
        possui_emissao_nfse={possui_emissao_nfse}
        editarServico={e => editarServicoRenovarContrato(e)}
        alteraServico={state.alteraServico}
        addServico={(e) => addServicoRenovarContrato(e)}
        removerServico={e => removerServicoRenovarContrato(e)}
        habilita_servico={state.habilita_servico}
        habilitaServico={() => habilitaServico()}
      />

      <ModalConfirmacao 
        open={state.openModalConfirmacao}
        handleClose={e => handleCloseModalConfirmacao()}
        dados={state.modal} 
        confirmar={(e) => removerServico(e)}
      />

      <ModalNovoContrato 
        open={state.openModalNovoContrato}
        handleClose={e => handleCloseModalNovoContrato()}
        dados={state.dadosContrato}
        servico={state.servico}
        updateField={e => updateFieldContrato(e)}
        updateServico={e => updateServico(e)}
        produtos={produtos}
        formasPagamento={formasPagamento}
        contasFinanceira={contasFinanceira}
        produtoSelecionado={produtoSelecionadoRenovar}
        addServico={e => addServicoNovoContrato(e)}
        editarServico={e => editarServicoNovoContrato(e)}
        alteraServico={state.alteraServico}
        removerServico={e => removerServicoNovoContrato(e)}
        confirmar={e => confirmarNovoContrato(e)}
        disabledButton={state.disabledButton}
        tab={state.tabContrato}
        handleChange={handleChange}
        searchResp={state.searchResp}
        updateFieldSearchResp={(e) => updateFieldSearchResp(e)}
        buscarResp={() => buscarResp()}
        responsavel={state.responsavel}
        enableBuscaResp={state.enableBuscaResp}
        updateFieldPessoaFisicaResponsavel={(e) => updateFieldPessoaFisicaResponsavel(e)}
        validarCpfCnpjResponsavel={(e) => validarCpfCnpjResponsavel(e)}
        formataTelefone={(e) => formataTelefoneResponsavel(e)}
        updateFieldContatoResponsavel={(e) => updateFieldContatoResponsavel(e)}
        updateFieldEnderecoResponsavel={(e) => updateFieldEnderecoResponsavel(e)}
        pegaEndereco={(e) => pegaEnderecoResponsavel(e)}
        updateFieldEmailResponsavel={(e) => updateFieldEmailResponsavel(e)}
        updateRecorrencia={(e) => updateRecorrencia(e) }
        updateParcelado={(e) => updateParcelado(e) }
        conta={state.conta}
        recalcularParcela={(e, value) => recalcularParcela(e, value)}
        mudarParcelas={e => mudarParcelas(e)}
        updateFieldConta={e => updateFieldConta(e)}
        updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
        marcaParcelas={(e, index) => marcaParcelas(e, index)}
        marcaParcelasContaFin={(e, index) => marcaParcelasContaFin(e, index)}
        template_documentos={template_documentos.list}
        updateFieldAssinar={() => updateFieldAssinar()}
        verificaDataParcela={(e, index) => verificaDataParcela(e, index)}
        possui_emissao_nfse={possui_emissao_nfse}
        updateFieldGerarNfse={(e) => updateFieldGerarNfse(e)}
        updateFieldCobrarMulta={() => updateFieldCobrarMulta()}
        updateFieldAgruparVencidas={() => updateFieldAgruparVencidas()}
        updateFieldBonusPontualidade={() => updateFieldBonusPontualidade()}
        updateFieldBonusIndicacao={() => updateFieldBonusIndicacao()}
        exibir_opcao_multa_contrato={state.exibir_opcao_multa_contrato}
        exibir_opcao_pontualidade_contrato={state.exibir_opcao_pontualidade_contrato}
        exibir_opcao_bonus_indicacao_contrato={state.exibir_opcao_bonus_indicacao_contrato}
        clienteIndicacao={state.clienteIndicacao}
        clientes={state.clientes.list}
        updateFiltroPessoa={e => updateFiltroPessoa(e)}
        updateFieldCliente={(e, value) => updateFieldCliente(e, value)}
      />
  
      <ModalContrato
        open={state.openModalContrato}
        handleClose={e => handleCloseModalContrato()}
        contrato={contrato}
        permissoesContrato={permissoesContrato}
        cabecalhoTabelaServico={state.cabecalhoTabelaServico}
        acoesTabelaServico={state.acoesTabelaServico}
        avisarRemoverServico={(e) => avisarRemoverServico(e)}
        atualizaServico={(e) => atualizaServico(e)}
        cancelarContrato={(e, estado_contrato) => cancelarContrato(e, estado_contrato)}
        renovarContrato={(e) => renovarContrato(e)}
        adicionarServico={(e) => adicionarServico(e)}
        imprimirContratoMatricula={(e) => imprimirContratoMatricula(e)}
        exibir_inativados={state.exibir_inativados}
        updateFieldExibirInativos={() => updateFieldExibirInativos()}
      />

      <ModalAdicionarServico 
        open={state.openModalAdicionarServico}
        handleClose={e => handleCloseModalAdicionarServico()}
        servico={servico}
        produtos={produtos}
        formasPagamento={formasPagamento}
        contasFinanceira={contasFinanceira}
        produtoSelecionado={produtoSelecionado}
        possui_emissao_nfse={possui_emissao_nfse}
        addServico={(e) => addServico(e)}
        updateServico={(e) => updateServico(e)}
        updateFieldGerarNfse={(e) => updateFieldGerarNfse(e)}
        updateRecorrencia={(e) => updateRecorrencia(e)}
        updateParcelado={(e) => updateParcelado(e)}
        conta={state.conta}
        recalcularParcela={(e, value) => recalcularParcela(e, value)}
        mudarParcelas={e => mudarParcelas(e)}
        updateFieldConta={e => updateFieldConta(e)}
        updateFieldParcela={(e, index) => updateFieldParcela(e, index)}
        marcaParcelas={(e, index) => marcaParcelas(e, index)}
        marcaParcelasContaFin={(e, index) => marcaParcelasContaFin(e, index)}
        alteraServico={state.alteraServico}
        verificaDataParcela={(e, index) => verificaDataParcela(e, index)}
      />
    </React.Fragment>
  )
}

export default Contrato
import styled, {css} from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';

const dragActive = css`
    border-color: #78e5d5;
`;

const dragReject = css`
    border-color: #e57878;
`;

export const DropContainer = styled.div.attrs({
    classnName: "dropzone"
})`
    border: 1px dashed rgb(111, 109, 109);
    border-radius: 4px;
    cursor: pointer;

    transition: height 0.2s ease;

    ${props => props.isDragActive && dragActive};
    ${props => props.isDragReject && dragReject};
`;

const messageColors = {
    default: '#999',
    error: '#e57878',
    success: '#78e5d5'
};

export const UploadMessage = styled.p`
    display: flex;
    color: ${props => messageColors[props.type || 'defaut']};
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px 0;
    margin-bottom: 0
`;

export const Container = styled.ul`
    margin: 0;
    border: 1px dashed #000;
    padding-left: 0;
    li {
        display : flex;
        justify-content: space-between;
        align-items: center;
        color: #444;

        & + li {
            margin-top: 15px;
        }
    }
`;

export const FileInfo = styled.div`
    display: flex;
    aling-items: center;

    div {
        display: flex;
        flex-direction: column;

        span { 
            font-size: 12px;
            color: #999;
            margin-top: 5px;

            button {
                border: 0 ;
                background: transparent;
                color: #e57878;
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }
`;

export const Preview = styled.div`
    width: 82px;
    height: 82px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 5px;
    margin-left: calc(50% - 45px);
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin-right: 10px;
`;